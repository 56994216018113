import React, { useRef } from "react";

import "./TermsAndConditions.scss";

export const TermsAndConditions: React.FC = () => {
  const frameworkRef = useRef(null);
  const backgroundRef = useRef(null);
  const meaningsRef = useRef(null);
  const agreementRef = useRef(null);
  const durationRef = useRef(null);
  const servicesRef = useRef(null);
  const requiredRef = useRef(null);
  const promiseRef = useRef(null);
  const feesRef = useRef(null);
  const unavailabilityRef = useRef(null);
  const nonDealingRef = useRef(null);
  const confidentialityRef = useRef(null);
  const intellectualRef = useRef(null);
  const terminationRef = useRef(null);
  const liabilityRef = useRef(null);
  const indemnityRef = useRef(null);
  const generalRef = useRef(null);

  const scrollTo = (ref: any) => {
    const position = ref?.current.getBoundingClientRect().top - 100;

    window.scrollTo({ top: position, behavior: "smooth" });
  }

  return (
    <div className="help-container">
      <h1 className="help-title">VOICE OF INFLUENCE LTD: CONTENT CREATOR TERMS AND CONDITIONS</h1>
      <div className="help-effective-date">Last updated: 21 August 2023</div>

      <div className="help-content">
        <div className="help-table-content">
          <div>TABLE OF CONTENTS</div>
          <div onClick={() => scrollTo(frameworkRef)}>FRAMEWORK TERMS</div>
          <div onClick={() => scrollTo(backgroundRef)} >BACKGROUND</div>
          <ol>
            <li onClick={() => scrollTo(meaningsRef)}>MEANINGS</li>
            <li onClick={() => scrollTo(agreementRef)}>OUR AGREEMENT</li>
            <li onClick={() => scrollTo(durationRef)}>DURATION OF OUR AGREEMENT</li>
            <li onClick={() => scrollTo(servicesRef)}>SERVICES</li>
            <li onClick={() => scrollTo(requiredRef)}>WHAT YOU ARE REQUIRED TO DO</li>
            <li onClick={() => scrollTo(promiseRef)}>WHAT EACH PARTY PROMISES IN RELATION TO THE AGREEMENT</li>
            <li onClick={() => scrollTo(feesRef)}>FEES; CHANGE CONTROL</li>
            <li onClick={() => scrollTo(unavailabilityRef)}>UNAVAILABILITY OF CONTENT CREATORS</li>
            <li onClick={() => scrollTo(nonDealingRef)}>NON-DEALING</li>
            <li onClick={() => scrollTo(confidentialityRef)}>CONFIDENTIALITY</li>
            <li onClick={() => scrollTo(intellectualRef)}>INTELLECTUAL PROPERTY</li>
            <li onClick={() => scrollTo(terminationRef)}>TERMINATION</li>
            <li onClick={() => scrollTo(liabilityRef)}>LIABILITY</li>
            <li onClick={() => scrollTo(indemnityRef)}>INDEMNITY</li>
            <li onClick={() => scrollTo(generalRef)}>GENERAL</li>
          </ol>
        </div>
        <div className="help-terms-and-conditions">
          <div ref={frameworkRef} className="help-terms-and-conditions-block">
            <h2>FRAMEWORK TERMS</h2>
            <ol>
              <li><strong>VOICE OF INFLUENCE LIMITED</strong> (company number <strong>14146057</strong>) with registered office is at 25 Evesham Place, Stratford Upon Avon, UK CV37 6HT (“VOi” “us” “our” such
                expressions including any successors in title and assigns) is an influencer marketing company that engages brands and content creators in connection with influencer marketing
                campaigns and related services.
              </li>
              <li>
                You (“you”) wish to register to VOi’s proprietary influencer Platform to participate in campaigns or, in the case of a manager and/or an agency (where applicable), so that your
                Represented Creator can participate in such campaigns.
              </li>
              <li>
                By ticking “Accept” where indicated, registering an account on the Platform or by using the Platform, you and/or Represented Creator (as applicable) understand and agree that you
                shall be bound by these Framework Terms. Please read these Framework Terms carefully as they contain important information about your and/or your Represented Creator (as
                applicable) rights and responsibilities when using the Platform.
              </li>
              <li>
                Please read these Framework Terms carefully as they contain important information about your and/or your Represented Creator’s (as applicable) rights and responsibilities when using
                the Platform.
              </li>
            </ol>
          </div>
          <div ref={backgroundRef} className="help-terms-and-conditions-block">
            <h2>BACKGROUND</h2>
            <ol>
              <li>VOi is an influencer marketing company that connects brands and content creators in connection with influencer marketing campaigns and related services.</li>
              <li>You wish to register to VOi’s proprietary influencer Platform to participate in campaigns or, in the case of a manager and/or an agency (where applicable), so that your Represented
                Creator can participate in such campaigns.</li>
              <li>These terms and conditions form the agreement between you and VOi, or as the case applies, between, on the one hand, VOi, and, on the other, Agent and its Represented Creator, in
                connection with the participation of any Campaign(s) through our Platform.</li>
            </ol>
          </div>
          <div ref={meaningsRef} className="help-terms-and-conditions-block">
            <h2>1. MEANINGS</h2>
            <div className="content">
              <span><strong>1.1</strong> The defined terms in these terms and conditions shall have the following meanings:</span>
              <div className="child-content">
                <span><strong>Agent:</strong> means the manager, agent, or appointed representative of Represented Creator.</span>
                <span><strong>Agreement:</strong> means the binding agreement between VOi and you, or as the case applies, between VOi, Agent and its Represented Creator, which is made up of these Framework Terms
                  together with each applicable Work Order as agreed between the parties from time to time. For clarity, each agreed Work Order shall form a separate Agreement incorporating these
                  Framework Terms.</span>
                <span><strong>Brand:</strong> means the brand that you are promoting as part of the Services, or as the case applies, that Agent and its Represented Creator are promoting as part of the Services, in relation to
                  an influencer marketing Campaign as detailed in a Work Order.</span>
                <span><strong>Change(s):</strong> means any changes required by VOi and/or Brand in connection with a Deliverable (or Deliverables) provided by you, Agent and/or Represented Creator (as applicable)
                  further to a Work Order in accordance with clause 7.4.</span>
                <span><strong>Campaign(s):</strong> means the influencer marketing campaign (or campaigns) involving a Brand and you, or as the case applies, involving a Brand and Agent and/or its Represented Creator,
                  as detailed in each Work Order.</span>
                <span><strong>Campaign Term:</strong> means the duration of the applicable Campaign as detailed in each Work Order.</span>
                <span><strong>Confidential Information:</strong> means confidential and proprietary information and trade secrets, including without limitation, software and hardware designs and specifications, equipment,
                  software (including not by way of limitation source and binary code), plans, drawings, data, prototypes, discoveries, research, developments, processes, procedures, intellectual property
                  and information relating to customers, marketing plans and future products, business data, internal organisational structure, methods of operations, business processes, forecasts, and
                  financial information and such other information disclosed pursuant to conditions of confidentiality, whether disclosed prior to, upon or after execution of this Agreement.
                </span>
                <span><strong>Deliverables:</strong> means the proceeds of your, or as applicable, Agent and its Represented Creator's, Services as required in connection with each Campaign and as detailed in each Work
                  Order.</span>
                <span><strong>Fees:</strong> means the applicable compensation in connection with a Campaign as set out in a Work Order and payable in accordance with clause 7.</span>
                <span><strong>Framework Terms:</strong> means these terms and conditions</span>
                <span><strong>VOi Affiliate:</strong> means any affiliate or group entities of VOi, each of its respective successors, and/or assigns.</span>
                <span><strong>VOi Materials:</strong> means any materials or information owned by or licensed to VOi and/or Brand that are provided to you, or as applicable, are provided to Agent and its Represented
                  Creator, and each of your, or as applicable each of Agent’s and/or its Represented Creator's, respective associates in connection with this Agreement.</span>
                <span><strong>Intellectual Property Rights:</strong> means any and all present and future, patents, inventions, know-how, trade secrets and other confidential information, trademarks, service marks, logos,
                  emblems, badges, mascots, insignia, identifying music and sounds, get-up, domain names, business names, trade names, moral rights, performance rights, registered designs, copyrights,
                  database rights, the sui generis rights of extraction relating to databases, design rights and other intellectual property rights of whatever nature, in each case whether registered or
                  unregistered and including applications for registration, and all rights or forms of protection having equivalent or similar effect anywhere in the world.</span>
                <span><strong>Media:</strong> any website, social media platform or place where Deliverables are posted and/or displayed including but not limited to those specific sites detailed in each Work Order.</span>
                <span><strong>Personal Data:</strong> has the meaning given to such term in the UK GDPR and includes any other information which is subject to other data protection, privacy or similar laws or regulations as
                  may be applicable.</span>
                <span><strong>Platform:</strong> means Vois’ proprietary technology which enables registered content creators to engage in commercial opportunities with Brands, namely execute Campaigns and facilitate
                  Deliverable submissions, approval, and performance.</span>
                <span><strong>Process:</strong> has the meaning given to such term in the UK GDPR.</span>
                <span><strong>Represented Creator:</strong> means those content creators that Agent represents and that Agent is authorised to act for and on behalf of in connection with procuring commercial opportunities,
                  namely in connection with the Campaign(s), and which Agent wishes to register to the Platform and/or to perform the Services as required in each Work Order.</span>
                <span><strong>Services:</strong> means the services that you, or as applicable, that Agent and its Represented Creator are required to provide in connection with a Campaign (including but not limited to the
                  provision of the Deliverables) as detailed in each Work Order.</span>
                <span><strong>Term:</strong> means the duration of Agreement between VOi and you or, as applicable, between VOi, on the one hand, and Agent and its Represented Creator, on the other, as detailed in
                  clause 3.1.</span>
                <span><strong>Territory:</strong> means the territory applicable to each Agreement as detailed in a Work Order.</span>
                <span><strong>UK GDPR:</strong> means the version of the European General Data Protection Regulation (2016/679) retained in the United Kingdom by virtue of section 3 of the European Union (Withdrawal)
                  Act of 2018..</span>
                <span><strong>Work Order:</strong> means the statement of work or creator brief accessed and agreed between the parties from time to time through the Platform which shall include (without limitation): (i) the
                  scope and details of each Campaign(s); (ii) the required Services in relation to each Campaign(s); (iii) the commercial terms of the Agreement as relevant to each Campaign(s), including
                  but not limited to details such as Fees, Deliverables, usage rights and related usage periods, exclusivity, relevant media channels and Campaign Term(s).</span>
              </div>
            </div>
          </div>
          <div ref={agreementRef} className="help-terms-and-conditions-block">
            <h2>2. OUR AGREEMENT</h2>
            <div className="content">
              <span><strong>2.1</strong> The Parties have agreed to work together on these Framework Terms.</span>
              <span><strong>2.2</strong> From time to time, the Parties may agree a Work Order through the Platform.</span>
              <span><strong>2.3</strong> Each Work Order shall be accessible and agreed through our Platform and shall integrate these Framework Terms. These Framework Terms shall also apply to any provision of
                services by VOi to you.
              </span>
            </div>
          </div>
          <div ref={durationRef} className="help-terms-and-conditions-block">
            <h2>3. DURATION OF OUR AGREEMENT</h2>
            <div className="content">
              <span><strong>3.1</strong> The Term of our Agreement shall begin on the date set out in a Work Order and shall continue, subject to clause 3.2, unless terminated in accordance with clause 12.</span>
              <span><strong>3.2</strong>  Unless this Agreement is terminated in accordance with clause 12, this Agreement shall expire at the end of a Campaign Term as set out in a Work Order. If this Agreement is due to
                expire or terminate during a Campaign Term, the Term of this Agreement shall automatically be extended until the end of such Campaign Term. For further clarity, you may not end this
                Agreement whilst a Campaign Term is still live unless clause 12 applies.</span>
            </div>
          </div>
          <div ref={servicesRef} className="help-terms-and-conditions-block">
            <h2>4. SERVICES</h2>
            <div className="content">
              <span><strong>4.1</strong> In consideration of your Services, or as the case applies, in consideration of Agent’s and/or Represented Creator's Services, VOi shall pay the Fees as set out in a Work Order in
                accordance with clause 7 to an account chosen by you or, as applicable, to the account chosen by Agent or by Represent Creator.</span>
              <span><strong>4.2</strong> During the Campaign Term, you agree to provide the Services described in a Work Order or, where applicable, Agent shall procure that Represented Creator provides the Services as
                described in a Work Order, time being of the essence, with all reasonable and due care and skill and subject to the terms of this Agreement.</span>
            </div>
          </div>
          <div ref={requiredRef} className="help-terms-and-conditions-block">
            <h2>5. WHAT YOU ARE REQUIRED TO DO</h2>
            <div className="content">
              <span><strong>5.1</strong> You, or as applicable, Agent and its Represented Creator, agree to co-operate with and act in good faith towards VOi and, where necessary, provide such materials including those
                Deliverables listed in the Work Order as VOi require to incorporate into the Services or VOi requires to carry out its obligations.</span>
              <span><strong>5.2</strong>  You, or as the case applies, Agent and its Represented Creator, agree to keep the applicable page and/or profile on which the Deliverables are posted on the relevant Media
                accessible to the public (i.e., not private) for the duration of the Campaign Term as set out in the Work Order.</span>
              <span><strong>5.3</strong> You, or as the case applies, Agent and its Represented Creator, will at any time during or after the Term, upon our request, promptly edit, remove, or delete any Deliverables as
                requested.</span>
              <span><strong>5.4</strong> You, or as the case applies, Agent and its Represented Creator, understand and agree that the Deliverables are subject to the Brand’s final approval in connection with each relevant
                Campaign, such approval not to be unreasonably withheld. You understand and agree, or as applicable, Agent procures that Represented Creator understands and agrees, to make any
                Change(s) or amendments to the Deliverables to rectify any inaccuracies before posting the same on the applicable Media set out in the Work Order and in accordance with clause 7.4.
                You shall not, or as the case applies, Agent shall not and procure that its Represented Creator shall not, make untruthful, unsubstantiated, or unapproved statements or claims regarding
                Brand or its products and/or services. </span>
              <span><strong>5.5</strong> The Deliverables shall disclose the sponsored nature of the relationship between you, or as applicable, Represented Creator, and the Brand in respect of the Services by disclosing the
                Deliverables '#ad' or ‘AD’ as appropriate and, as may be directed by VOi and/or Brand, together with any available ‘in-platform’ tools that disclose the paid nature of the applicable
                marketing communication.</span>
              <span><strong>5.6</strong> You, or as the case applies, Agent and/or its Represented Creator, will need to register to the Platform in order to submit all Deliverables in accordance with a Work Order so that VOi,
                any VOi Affiliate, and/or Brand can record their approval(s) of the Deliverables.</span>
              <span><strong>5.7</strong> Where required, you shall, or as the case applies, Agent procures that its Represented Creator shall, agree to link the relevant user account with a valid Facebook, Instagram,
                YouTube, Twitter, TikTok or such other social media account as applicable (such service, a “Third-Party Service” and each such account, a “Third-Party Account”) by allowing VOi to
                access your or Represented Creator's (as applicable) Third-Party Account, as is permitted under the applicable terms and conditions that govern the use of each Third-Party Account, so
                that VOi can track performance of Deliverables in relation to any given Campaign.
              </span>
            </div>
          </div>
          <div ref={promiseRef} className="help-terms-and-conditions-block">
            <h2>6. WHAT EACH PARTY PROMISES IN RELATION TO THE AGREEMENT</h2>
            <div className="content">
              <span><strong>6.1</strong> You, or as the case applies, Agent and its Represented Creator, warrant, and represent that:</span>
              <div className="child-content">
                <span><strong>6.1.1</strong> you, or Represented Creator (as applicable), are able to provide the Services and have the legal capacity to enter into this Agreement and that by doing so you, or Represented
                  Creator (as applicable), will not be in breach of any obligation to or right of a third party;</span>
                <span><strong>6.1.2</strong> you, or Represented Creator (as applicable), have the necessary skills and knowledge to carry out the Services under this Agreement;</span>
                <span><strong>6.1.3</strong> you, or Represented Creator (as applicable), are solely responsible for all VAT(if applicable), income tax and national insurance contributions or social security due in respect of
                  provision of the Services under this Agreement and shall timely remit payment
                  of all such VAT (if applicable), income tax and national insurance contributions (NIC) or social security in each jurisdiction in which such taxes, contributions or obligations are due and
                  payable;</span>
                <span><strong>6.1.4</strong> you or Represented Creator shall reimburse VOi in respect of any tax, social security contributions or amount in respect of tax (including UK income tax and NIC) which VOi is
                  required to account to any tax authority in connection with this Agreement or any other past, present or future agreement between VOi and you, or Represented Creator (as applicable);</span>
                <span><strong>6.1.5</strong> you, or Represented Creator (as applicable), do not and will not artificially increase follower or subscriber counts by buying followers or by using other artificial means such as the use
                  of “bots” or similar;</span>
                <span><strong>6.1.6</strong> the Services shall be provided in accordance with the Work Order (and as otherwise set out in this Agreement) and such Services shall be provided with all due care, skill and ability;</span>
                <span><strong>6.1.7</strong> the manner in which the Deliverables are used on the Media will not have any adverse effect on VOi, any VOi Affiliate and/or the Brand. You, or Represented Creator (as applicable),
                  shall not use the Deliverables or any VOi Materials in any derogatory, discriminatory, violent, racist, offensive or otherwise illegal manner;</span>
                <span><strong>6.1.8</strong>  as of the effective date of this Agreement, there are no reasons, acts or omissions that you, or Represented Creator (as applicable), have undertaken which brings, or is likely to bring,
                  VOi, any VOi Affiliate and/or Brand (at VOi and/or Brands sole reasonable discretion) into disrepute, scandal or embarrassment, including without limitation any connection with any
                  criminal activity;</span>
                <span><strong>6.1.9</strong> the Deliverables will not, when used in accordance with this Agreement, infringe the intellectual property rights (including without limitation copyright, trade mark, or rights of publicity)
                  or other rights of any third party (including rights relating to Personal Data) and will comply with all applicable laws and regulations, including without limitation any guidelines in place from
                  time to time issued by the Advertising Standards Authority (ASA) and/or the Competition and Markets Authority (CMA) in connection with marketing communications, and any other
                  relevant law, rule, guidelines, or regulation applicable to the display or publication of advertisements or marketing communications as issued or in place from time to time pursuant to any
                  other government or regulatory body that regulates advertising within your, or as applicable, Represented Creator’s, local country and/or industry; or applicable data protection, privacy or
                  similar laws or regulations;</span>
                <span><strong>6.1.10</strong> where required and on VOi’s request, you, or as the case applies, Agent and/or its Represented Creator, shall provide VOi with full details of the performance of the Deliverables
                  including but not limited to viewer engagements, likes, impressions and views;</span>
                <span><strong>6.1.11</strong> you, or Represented Creator (as applicable), confirm that you are not, or, in the case of Represented Creator, they are not, a member of any guild, union or similar organisation such
                  that the use of your, or Represented Creator's (as applicable), image rights as may be comprised of the Services, including without limitation, biographical materials, statements or
                  testimonials as contemplated under this Agreement would trigger any guild, union or third party payments or other obligations. In the event this status changes, you, or Represented
                  Creator (as applicable) agree to notify us immediately.</span>
                <span><strong>6.1.12</strong> unless prevented by ill health or accident, you, or as the case applies, Represented Creator, shall devote such time as is necessary to the carrying out of the Services as may be
                  necessary for their proper performance;</span>
                <span><strong>6.1.13</strong> should any third parties be hired by you, or as the case applies, hired by Agent and/or Represented Creator (as applicable), in connection with the Services, such third parties will be
                  subject to the prior written approval of VOi and/or Brand, such approval not to be unreasonably withheld or delayed;</span>
                <span><strong>6.1.14</strong> you, or as the case applies, Agent and/or Represented Creator (as applicable), shall obtain such licences, waivers or consents in respect of existing materials (excluding VOi
                  Materials) incorporated into the Deliverables as shall be necessary in order that VOi and/or Brand can use the Deliverables for the purposes set out in this Agreement and in accordance
                  with applicable laws and regulations (including laws relating to Personal Data);</span>
                <span><strong>6.1.15</strong> you will advise VOi, as soon as reasonably practicable, in the event that you, or as the case applies, Represented Creator (as applicable), are/is unable to provide the Services due
                  to illness, injury or other emergency;</span>
                <span><strong>6.1.16</strong> you shall not transfer Personal Data to VOi or VOi Affiliates except in accordance with applicable data protection, privacy or similar laws or regulations;</span>
                <span><strong>6.1.17</strong> you, or Represented Creator (as applicable), shall be responsible for providing accurate details and maintaining such up to date details with respect to your account on the Platform,
                  including without limitation, applicable payment details and tax residency status. Should your, or Represented Creator’s (as applicable), tax residency status change, you understand that it
                  is your responsibility to inform VOi of such changes.</span>
              </div>
              <span><strong>6.2</strong> VOi warrants and undertakes that it shall be responsible for the management of and communication with the Brand and that it shall provide you, or as the case applies, provide Agent
                and/or its Represented Creator (as applicable), with the Brand’s creative briefs in relation to the applicable Campaign and in connection with the Services.
              </span>
            </div>
          </div>
          <div ref={feesRef} className="help-terms-and-conditions-block">
            <h2>7. FEES; CHANGE CONTROL</h2>
            <div className="content">
              <span><strong>7.1</strong> In consideration of the Services that you provide to VOi in connection with any Campaign, VOi shall pay you the Fees set out in each Work Order (plus VAT if applicable) within sixty
                (60) days completion of the Services and subject to:(i) payment from the Brand for services rendered in connection with the relevant Campaign; and (ii) the receipt of a valid invoice, or
                otherwise in accordance with the payment terms set out in the relevant Work Order.</span>
              <span><strong>7.2</strong> You, or Represented Creator (as applicable), shall be responsible for making all deductions from payments and Fees received in relation to this Agreement and performing all acts and
                making all payments necessary under any relevant tax legislation. For the avoidance of doubt, VOi shall not make such deductions from the Fees.</span>
              <span><strong>7.3</strong> VOi may deduct amounts from the Fees in the event that:</span>
              <div className="child-content">
                <span><strong>7.3.1</strong> you, Represented Creator (as applicable), fail to properly and/or on time provide VOi or any VOi Affiliate the Deliverables and/or comply with any reasonable instructions set out in a
                  Work Order as reasonably required for the timely supply of the Services (provided always that such failure to provide the Deliverables is not the direct result of VOi and/or Brand’s material
                  failure to comply with its obligations toward you and/or Represented Creator (as applicable));</span>
                <span><strong>7.3.2</strong> there are unforeseen changes to the cost of Services and/or other circumstances outside of VOi’s reasonable control, for example where a Brand has decided to cancel or otherwise
                  not proceed with a Campaign or part thereof except that, in this case, VOi shall use all reasonable efforts to ensure that the Brand pays you, or as the case applies, Agent and/or
                  Represented Creator (as applicable), a pro rata portion of the Fees for any Services provided up until the date of termination/ cancellation provided always that such
                  termination/cancellation is not due to your and/or Represented Creator's (as applicable) material breach of obligations under this Agreement;</span>
                <span><strong>7.3.3</strong> the parties agree to any variations to the Services in writing.</span>
              </div>
              <span><strong>7.4</strong> VOi will have the right to request that you, or Represented Creator (as applicable), make Change(s) to the Deliverables and/or products of the Services that are reasonably required to
                bring the Deliverables / Services into scope of the Brand’s creative brief as applicable to the relevant Campaign. VOi will exercise such right to request Change(s) reasonably and
                principally where you, or Represented Creator (as applicable), have deviated from any creative brief provided by VOi. You, or as the case applies, Agent and/or Represented Creator (as
                applicable), agree to implement such Change(s) as reasonably required by VOi and acknowledge that the Brand shall have final approval rights before the posting of any Deliverable(s) on
                the applicable Media pursuant to any Campaign(s). You, or as the case applies, Agent and/or Represented Creator (as applicable), understand and agree that the Deliverables shall not be
                posted until the Deliverables and any associated content have been approved by VOi and/or the Brand. Unless otherwise agreed in writing between the parties, any and all Change(s)
                shall be inclusive of the Fees payable hereunder. VOi and/or Brand shall not be required to pay any additional fees for any Change(s) as may be reasonably required from time to time.
              </span>
            </div>
          </div>
          <div ref={unavailabilityRef} className="help-terms-and-conditions-block">
            <h2>8. UNAVAILABILITY OF CONTENT CREATORS</h2>
            <div className="plain-content">
              In the event that you, or as the case applies, Represented <strong>Creator</strong>, are unavailable or unwilling to provide the Services, you agree to inform VOi immediately and will work with VOi in good
              faith to find a suitable solution in order to provide the Services. In the case of a manager and/or an agency, you agree to replace Represented <strong>Creator</strong> with a suitable alternative with VOi’s
              prior written approval. In the event that the parties cannot agree a suitable alternative, VOi may terminate this Agreement subject to paying you, or Represented <strong>Creator</strong>(as applicable), a
              reasonable pro rata portion of the Fees for Services provided but not yet paid for and/or any prior agreed expenses incurred up to that date.
            </div>
          </div>
          <div ref={nonDealingRef} className="help-terms-and-conditions-block">
            <h2>9. NON-DEALING</h2>
            <div className="plain-content">
              Unless otherwise specified in a Work Order, in order to protect the legitimate business interests of VOi, you represent or, as applicable, you procure that Represented <strong>Creator</strong> represents,
              with VOi that you, and/or Represented <strong>Creator</strong>, shall not for the Term and a period of <strong>12</strong> months after the Term directly deal with a Brand for which you, or Represented <strong>Creator</strong>(as
              applicable), have provided Services to in connection with this Agreement without VOi’s prior written approval.
            </div>
          </div>
          <div ref={confidentialityRef} className="help-terms-and-conditions-block">
            <h2>10. CONFIDENTIALITY</h2>
            <div className="content">
              <span><strong>10.1</strong> The Parties may disclose to each other and their designated agents and/or advisors Confidential Information as may be required for the performance of the Services and execution of
                a Campaign.</span>
              <span><strong>10.2</strong> Each party undertakes that it will keep secret and confidential the terms of this Agreement and any Confidential Information supplied by either party in connection with this Agreement
                or in connection with the business of the other and in connection with the Services and Campaign and shall only disclose such information or part thereof (except to its own employees and
                advisers and then only on a need to know basis) with the other party’s prior written consent <strong>PROVIDED THAT</strong> this clause shall not extend to information which was and can be shown to
                be rightfully in the possession of the receiving party prior to the commencement of the negotiations leading to this Agreement or which is in the public domain (other than as a result of a
                breach of this clause).</span>
            </div>
          </div>
          <div ref={intellectualRef} className="help-terms-and-conditions-block">
            <h2>11. INTELLECTUAL PROPERTY</h2>
            <div className="content">
              <span><strong>11.1</strong> Subject to the remainder of this clause 11 and unless otherwise agreed in a Work Order, you, or as the case applies, Represented <strong>Creator</strong>, will retain all Intellectual Property Rights in
                the content created in the provision of the Services, excluding any Intellectual Property Rights in VOi Materials provided by VOi and/or the Brand which shall remain the property of VOi
                and/or the Brand (as applicable).</span>
              <span><strong>11.2</strong> You, or as the case applies, Agent and Represented <strong>Creator</strong>, agree that the Deliverables must be kept on the relevant Media in the Territory for the duration of the Campaign Term
                and any usage periods specifically set out in the relevant Work Order which shall, unless otherwise stated in a Work Order, be a minimum of 12 months from the date of posting the
                relevant Deliverable on the Media (subject always to the terms and conditions of the relevant Media platform).</span>
              <span><strong>11.3</strong>  If a Work Order indicates that VOi and/or Brand may use the Deliverables on its own channels, it is the intention of the parties that VOi and/or Brand hold a licence to use and exploit
                such Deliverables in accordance with the terms set out in the Work Order. You, or as the case applies, Agent and/or Represented <strong>Creator</strong>, shall grant to VOi and/or Brand an irrevocable
                royalty-free licence to use and exploit the Deliverables for the purpose described in the Work Order in the Territory and on the applicable Media set out in the Work Order.</span>
              <span><strong>11.4</strong> Notwithstanding the expiry of any licence granted further to clause 11.3 above, you, or Represented <strong>Creator</strong>(as applicable), agree that VOi and/or Brand may continue to use the
                Deliverables and/or the proceeds of your Services in perpetuity and on a royalty free basis strictly for non-commercial purposes, including but not limited to intra-company, research, award,
                press, publicity, file and/or reference purposes. For the avoidance of doubt, VOi and/or Brand shall not be obliged to take down or otherwise remove any posts comprising the Deliverables
                from its owned and/or operated channels which have been used in accordance with any usage provisions set out in a Work Order during the Campaign Term.</span>
              <span><strong>11.5</strong> Where applicable, you, or as the case applies, Agent and its Represented <strong>Creator</strong>, acknowledge and agree that in order to promote you and/or Represented <strong>Creator</strong>(as applicable)
                to our commercial network of Brands and to maximise commercial opportunities available to you and/or Represented <strong>Creator</strong> as part of VOi's content creator community, we may use, in
                any and all media now known or hereafter devised, your and/or Represented <strong>Creator</strong>'s approved name, picture, image, likeness, social media handle(s), avatar (s), signature, voice, and
                biographical information, as incorporated into the Deliverables and/or other social media content, in their original or modified form, provided always that such use is for non-commercial
                purposes and credits you and/or Represented <strong>Creator</strong>(as applicable), in accordance with the terms of this Agreement.</span>
              <span><strong>11.6</strong> You, or as the case applies, Agent and its Represented <strong>Creator</strong>, expressly acknowledge that it is forbidden to use the Deliverables in connection with any racist, defamatory or
                obscene material (including, without limitation, pornographic material) or other legally restricted material.</span>
              <span><strong>11.7</strong> VOi grants you and/or Represented <strong>Creator</strong>(as applicable) a non-exclusive royalty free licence to use the VOi Materials for all purposes relating to this Agreement and warrants that
                it is fully entitled to grant you and/or Represented <strong>Creator</strong>(as applicable) these rights and that the VOi Materials are free of racist, defamatory, obscene and other legally restricted material.</span>
            </div>
          </div>
          <div ref={terminationRef} className="help-terms-and-conditions-block">
            <h2>12. TERMINATION</h2>
            <div className="content">
              <span><strong>12.1</strong> Either party shall be entitled to terminate this Agreement upon the other party’s material breach (including without limitation non-payment of any sum due) unless the breaching party
                remedies such breach within fourteen (14) days written notice of its occurrence.</span>
              <span><strong>12.2</strong> Neither party will be liable in any amount for failure to perform any obligation hereunder if such failure is caused by the occurrence of any unforeseen contingency beyond the
                reasonable control of such party including without limitation, internet outages, pandemic, communications outages, fire, flood, war or act of God. In such circumstances the affected party
                shall be entitled to a reasonable extension of time for performing such obligations. If the period of delay or non-performance continues for a period of more than one (1) month, the party not
                affected may terminate the Term of this agreement by giving fourteen (14) days’ written notice to the affected party.</span>
              <span><strong>12.3</strong> You, or as the case applies, Agent and/or its Represented <strong>Creator</strong>, may not cancel its order of the Services or otherwise terminate this Agreement (save for a proven material breach
                by VOi of a fundamental term of this Agreement) at any time.</span>
              <span><strong>12.4</strong>  You, or as the case applies, Agent and/or its Represented <strong>Creator</strong>, acknowledge that in, under some circumstances and due to the nature of individual Campaigns, a Brand may
                have the right to suspend or cease the Campaign. In which case, VOi shall have the right to terminate this Agreement on written notice to you, or Represented <strong>Creator</strong>(as applicable),
                subject to the provisions of clause 7.3.2.</span>
              <span><strong>12.5</strong> Upon expiry and/or termination of this Agreement:</span>
              <div className="child-content">
                <span><strong>12.5.1</strong> you and/or Represented <strong>Creator</strong>(as applicable) agree to return to VOi any Confidential Information and/or VOi Materials in your possession;</span>
                <span><strong>12.5.2</strong> any term of this Agreement which is intended to survive the Term shall continue to apply on or after such expiry or ending of the Agreement, including without limitation clauses 1,
                  6,7,9,10,11,12,13,14 and 15.</span>
              </div>
            </div>
          </div>
          <div ref={liabilityRef} className="help-terms-and-conditions-block">
            <h2>13. LIABILITY</h2>
            <div className="content">
              <span><strong>13.1</strong> Subject to Clause 13.2 below, VOi’s liability to you, or as the case applies, to Agent and/or Represented <strong>Creator</strong>(as applicable) for any loss or damage direct or otherwise and
                howsoever caused whether in tort (including negligence), contract or otherwise shall not exceed the Fees invoiced by you and/or Represented <strong>Creator</strong>(as applicable) to VOi for the
                Services under the Work Order to which such liability relates.</span>
              <span><strong>13.2</strong> Neither party shall be liable to the other party for any indirect or consequential loss or damage <strong>SAVE THAT</strong> nothing in this Agreement shall exclude or in any way limit either party’s
                liability for fraud, death or personal injury caused by its negligence or any other liability to the extent the same may not be excluded or limited as a matter of law.</span>
              <span><strong>13.3</strong> When instructions or advice are given or received orally by VOi, it shall have no liability to you and/or Represented <strong>Creator</strong>(as applicable) for any misunderstanding or representation
                which may arise in relation thereto except in relation to fraudulent misrepresentations.</span>
            </div>
          </div>
          <div ref={indemnityRef} className="help-terms-and-conditions-block">
            <h2>14. INDEMNITY</h2>
            <div className="plain-content">
              You, or as the case applies, Represented <strong>Creator</strong>, undertake to VOi to indemnify and hold harmless VOi and/or Brand in full and defend at its own expense VOi and/or Brand against all
              costs, damages and losses incurred by it arising out of its use of the Deliverables or breach of clauses 6.1.1 to 6.1.17 (inclusive).
            </div>
          </div>
          <div ref={generalRef} className="help-terms-and-conditions-block">
            <h2>15. GENERAL</h2>
            <div className="content">
              <span><strong>15.1</strong> The Parties understand that VOi is an independent contractor with respect to you and/or Represented Creator(as applicable), and this Agreement creates no agency, partnership or
                joint venture relationship between the Parties. Neither party shall have express or implied authority to act on behalf of or make any representations whatsoever on behalf of the other.
                You, or Represented Creator(as applicable), understand that VOi has other clients and that VOi may offer similar services to other clients.</span>
              <span><strong>15.2</strong> Except as specifically set forth herein, this Agreement may be amended or terminated only by a written instrument executed by an authorized officer of VOi and you.</span>
              <span><strong>15.3</strong> If at any time any part of this Agreement is or becomes unenforceable, such part will be construed as far as possible to reflect the Parties’ intentions and the remainder of the
                provisions will remain in full force and effect.</span>
              <span><strong>15.4</strong> No person who is not a party to this Agreement shall be entitled to enforce any of the terms pursuant to the Contracts (Rights of Third Parties) Act 1999.</span>
              <span><strong>15.5</strong> You, or as the case applies, Agent and its Represented Creator, acknowledge that VOi and/or VOi Affiliates will Process your, or as the case applies, the Agent’s and its
                Represented Creator's, Personal Data and will do so in accordance with the privacy notice available here: <a href="https://www.voice-of-influence.co.uk/privacy">https://www.voice-of-influence.co.uk/privacy</a>.</span>
              <span><strong>15.6</strong> VOi may, at any time, assign the benefit and/or burden or otherwise delegate any of its rights and obligations under this Agreement to any VOi Affiliate provided that VOi will procure
                that such VOi Affiliate will be bound by the same terms of this Agreement. In such cases, the VOi Affiliate will assume all the rights, obligations, liabilities, and benefits of VOi under the
                Agreement as if it were the original party to it in place of VOi. <br />
                You, or as the case applies, Agent and/or its Represented Creator, shall not assign the benefit or burden of this Agreement without the prior written consent of VOi.
              </span>
              <span><strong>15.7</strong> During the Term of this Agreement and for the twelve (12) month period following its termination, neither party shall directly or indirectly solicit, offer employment, employ or retain as a
                consultant any employee or former employee of the other party who has participated directly in the Services without the prior written consent of the other party.</span>
              <span><strong>15.8</strong> VOi may update these Framework Terms from time to time and place a new version on the Platform. When VOi does so, we may send you a notification advising that these
                Framework Terms have been updated on our page located at <a href="https://www.voice-of-influence.co.uk/terms-conditions/">https://www.voice-of-influence.co.uk/terms-conditions/</a>. Your, or as the case applies, Agent’s and/or Represented Creator's,
                use of the Platform from the date that the amended terms are placed on the Platform onwards will be governed by those new terms.</span>
              <span><strong>15.9</strong> This Agreement shall be governed and construed in all respects in accordance with the laws of England and Wales and the Parties agree to submit to the exclusive jurisdiction of the
                courts of England and Wales.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};