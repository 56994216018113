import React, { useState } from "react"
import { Link } from "react-router-dom";
import { Button } from "../../../elements"
import { RegisterOptions, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { forgotPassword } from "../../../../redux";

import './ForgotPassword.scss';

export const ForgotPasswordPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: "onBlur"
  });
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  const { loading } = useAppSelector(state => state.auth);

  const registerOptions = {
    email: (): RegisterOptions => {
      return {
        required: "Email is required",
        pattern: {
          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
          message: "Invalid email address",
        }
      }
    },
  }

  const onFormSubmit: SubmitHandler<any> = async data => {
    try {
      const response = await dispatch(forgotPassword(data.email))

      if (response?.payload?.isSuccess) {
        setError('');
        setSuccess('Reset email sent successfully. Please check your email!');
      }
    } catch (err) {
      setError(err.message);
      setSuccess('');
    }
  }

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-wrapper">
        <div className="forgot-password-logo">
          <img src="/images/logo.png" alt="Voice of influence" />
        </div>

        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="forgot-password-title">
            Forgot Password
          </div>
          <div className="forgot-password-fields">
            <input
              type="email"
              placeholder="Your Email"
              name="email"
              {...register("email", registerOptions.email())}
            />
            {errors.email && <p className="error">{errors.email.message}</p>}
          </div>

          <div className="forgot-password-button">
            <Button variant="secondary" type="submit" disabled={!isValid || loading}>Send Email</Button>
          </div>

          {error && <p className="error system-error">{error}</p>}
          {success && <p className="success system-success">{success}</p>}
        </form>

        <div className="no-account">
          <p>Don't have an account?</p>
          <Link to="/auth/signup" className="signup-link">
            Sign Up for free
          </Link>
        </div>
      </div>
    </div>
  )
}