import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import {
  getCampaignDetailByCrn,
  getOfferById,
  resetCurrentCampaign
} from '../../../../redux'
import { useLoading } from '../../../../hooks'

import { AppLayout } from '../../../layout'
import { CampaignDetailPanel, CampaignNav, MapPanel, ViewMode } from '../../../common'
import { formatDate } from '../../../../utils/timeHelpers'
import { OfferStatus } from '../../../../interfaces'
import { useBodyClassName } from '../../../../hooks/useBodyClassName'

import './CampaignDetailPage.scss'

export const CampaignDetailPage: React.FC = () => {
  useBodyClassName('bg-white')

  const { setLoading } = useLoading()
  const [viewMode, setViewMode] = useState<ViewMode>('detail')
  const [isMyCampaign, setIsMyCampaign] = useState<boolean>(false)
  const [isMyOffer, setIsMyOffer] = useState<boolean>(false)
  const [isCampaignRegisteredOrApplied, setIsCampaignRegisteredOrApplied] =
    useState<boolean>(false)
  const { campaignId, offerId, '*': paths } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { currentCampaign: campaign, loading } = useAppSelector((state) => state.campaign)
  const { offer: myCampaign, myCampaigns } = useAppSelector((state) => state.offer)

  console.log({ campaign, myCampaign })

  useEffect(() => {
    setLoading(true)
    if (paths.includes('my-campaigns')) {
      setIsMyCampaign(true)
    }

    if (paths.includes('my-offers')) {
      setIsMyOffer(true)
    }

    if (offerId) {
      dispatch(getOfferById(offerId))
    }

    dispatch(getCampaignDetailByCrn(campaignId))
  }, [])

  useEffect(() => {
    if (campaign && !loading) {
      setLoading(false)

      if (myCampaigns && myCampaigns.length) {
        const isRegisteredOrApplied = myCampaigns.some(
          (item) =>
            item.relatedCampaignId === campaign.id &&
            [OfferStatus.ACCEPTED, OfferStatus.PENDING].includes(item.status)
        )
        setIsCampaignRegisteredOrApplied(isRegisteredOrApplied)
      }
    }
  }, [campaign, loading, myCampaigns])

  const onBackButtonClick = () => {
    dispatch(resetCurrentCampaign())

    navigate(-1)
  }

  return campaign ? (
    <AppLayout
      backButtonVisible
      backButtonClick={onBackButtonClick}
      headerChildren={
        <CampaignNav currentMode={viewMode} onSelectViewMode={setViewMode} />
      }>
      <div className="campaign-detail-container">
        <div className="campaign-detail-header">
          <div className="campaign-detail-header-left">
            <h2>{campaign.hotel.organisationName}</h2>
            <h5>{campaign.name}</h5>
          </div>
          <div className="campaign-detail-header-right">
            {viewMode === 'map' ? (
              <span>{campaign.hotel.organisationAddress}</span>
            ) : (
              <span>
                {formatDate(campaign.startDate)} - {formatDate(campaign.endDate)}
              </span>
            )}
          </div>
        </div>
      </div>
      {viewMode === 'detail' && (
        <CampaignDetailPanel
          campaign={campaign}
          hotel={campaign.hotel}
          offer={myCampaign}
          isMyCampaign={isMyCampaign}
          isMyOffer={isMyOffer}
          isCampaignRegisteredOrApplied={isCampaignRegisteredOrApplied}
        />
      )}
      {viewMode === 'map' && <MapPanel hotel={campaign.hotel} />}
    </AppLayout>
  ) : null
}
