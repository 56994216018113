import React, { useEffect, useState } from 'react'

import { AppLayout } from '../../../layout'
import { AlertModal, Tab } from '../../../elements'
import { MessageCard, MessageCreateModal } from '../../../common'
import { MessageInterfaceProps } from '../../../../interfaces'
import { deleteMessage, getInboxMessages, getSentMessages, getUnreadMessages, getMessageByUser, markAsRead, useInboxMessages, useSentMessages, useUnreadInboxMessages, useUnreadMessages } from '../../../../redux'
import { useAppDispatch } from '../../../../redux/hooks'
import { useLoading } from '../../../../hooks'

import './MessagesPage.scss'

export const MessagesPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const inboxMessages = useInboxMessages();
  const sentMessages = useSentMessages();
  const unreadMessages = useUnreadMessages();
  const unreadInboxMessages = useUnreadInboxMessages();

  const { loading, setLoading } = useLoading();

  const [isMessageModalVisible, setIsMessageModalVisible] = useState(false);
  const [currentMessage, setCurrentMessage] = useState<MessageInterfaceProps>(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const onReply = (message: MessageInterfaceProps) => {
    setCurrentMessage(message);
    setIsMessageModalVisible(true);
  }

  const onDelete = (message: MessageInterfaceProps) => {
    setCurrentMessage(message);
    setDeleteModalVisible(true);
  }

  const onConfirmDelete = async () => {
    try {
      setLoading(true);

      await dispatch(deleteMessage(currentMessage));

      setLoading(false);
      setDeleteModalVisible(false);
      setCurrentMessage(null);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    dispatch(getInboxMessages());
    dispatch(getSentMessages());
    dispatch(getUnreadMessages());
    dispatch(getMessageByUser());

    for (const message of unreadInboxMessages) {
      dispatch(markAsRead({ ...message, isRead: true }));
    }
  }, []);

  return (
    <AppLayout backButtonVisible>
      <div className="messages-container">
        <Tab
          panes={[
            {
              id: 'inbox',
              title: `Inbox ${inboxMessages.length > 0 ? `(${inboxMessages.length})` : ''}`,
              element: (
                <div className="messages-list">
                  {inboxMessages.map((message) => (
                    <MessageCard key={message.id} message={message} onReply={(mes) => onReply(mes)} onDelete={(mes) => onDelete(mes)} />
                  ))}
                </div>
              ),
            },
            {
              id: 'sent',
              title: `Sent ${sentMessages.length > 0 ? `(${sentMessages.length})` : ''}`,
              element: (
                <div className="messages-list">
                  {sentMessages.map((message) => (
                    <MessageCard key={message.id} message={message} isOwnMessage onDelete={(mes) => onDelete(mes)} />
                  ))}
                </div>
              ),
            },
          ]}
          infoElement={
            unreadMessages ? <div className="messages-tab-info">
              New Messages <span className="chip">{unreadMessages}</span>
            </div> : null
          }
        />
      </div>

      {isMessageModalVisible && (
        <MessageCreateModal
          open={isMessageModalVisible}
          onClose={() => setIsMessageModalVisible(false)}
          recipientId={currentMessage.senderId}
          campaign={currentMessage.campaign}
          message={currentMessage}
          isReply
        />
      )}

      <AlertModal
        open={deleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        title={'Delete message'}
        description={`Are you sure you want to delete this message?`}
        onConfirm={() => onConfirmDelete()}
        loading={loading}
      />
    </AppLayout>
  )
}
