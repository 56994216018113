import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { RegisterOptions, useForm } from "react-hook-form";
import { TagsInput } from "react-tag-input-component";
import { Button, Calendar, ImageUpload, Modal, ModalProps } from "../../../elements";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useLoading } from "../../../../hooks";
import { createCampaign, updateCampaign } from "../../../../redux";
import { uploadImageToS3 } from "../../../../utils/fileHelpers";
import { CampaignInterfaceProps, CampaignStatus, HotelInterfaceProps } from "../../../../interfaces";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { END_OF_TODAY, campaignChannelOptions } from "../../../../utils/types";
import { RichTextEditor } from "../../../elements/RichTextEditor/RichTextEditor";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import { formatTimeToBeginingOfDay } from "../../../../utils/timeHelpers";

import './CampaignCreateModal.scss';

interface CampaignCreateModalProps extends ModalProps {
  currentCampaign?: CampaignInterfaceProps;
  isEdit?: boolean;
  isCopy?: boolean;
  hotels?: HotelInterfaceProps[];
  refreshCampaigns?: () => Promise<void>;
}

export const CampaignCreateModal: React.FC<CampaignCreateModalProps> = ({
  open,
  onClose,
  currentCampaign,
  isEdit = false,
  isCopy = false,
  hotels = [],
  refreshCampaigns,
}) => {
  const dispatch = useAppDispatch();
  const { isMobile } = useWindowSize();
  const [currentImages, setCurrentImages] = useState<string[]>(currentCampaign?.images || []);
  const [channels, setChannels] = useState<string[]>(currentCampaign?.channels || []);
  const [selectedDates, setSelectedDates] = useState<Date[]>(currentCampaign?.availableDates || []);
  const [tags, setTags] = useState(currentCampaign?.keywords || []);
  const [descriptionEditorState, setDescriptionEditorState] = useState<EditorState>(currentCampaign?.description ? EditorState.createWithContent(stateFromHTML(currentCampaign?.description)) : EditorState.createEmpty());
  const [termsAndConditionsEditorState, setTermsAndConditionsEditorState] = useState<EditorState>(currentCampaign?.terms ? EditorState.createWithContent(stateFromHTML(currentCampaign?.terms)) : EditorState.createEmpty());
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    getValues,
    setError,
    clearErrors
  } = useForm<CampaignInterfaceProps>({
    mode: 'onChange',
    delayError: 200,
    defaultValues:
      isEdit || isCopy
        ? {
            ...currentCampaign,
            name: isCopy ? `${currentCampaign?.name} (Copy)` : currentCampaign?.name,
            startDate: isCopy
              ? null
              : new Date(currentCampaign?.startDate).toISOString().split('T')[0],
            endDate: isCopy
              ? null
              : new Date(currentCampaign?.endDate).toISOString().split('T')[0],
            status: isCopy ? CampaignStatus.SCHEDULED : currentCampaign.status,
            availableDates: isCopy ? null : currentCampaign.availableDates
          }
        : {}
  })
  const form = useRef(null);
  const descriptionEditor = useRef(null);
  const termsAndConditionsEditor = useRef(null);
  let startDate = watch("startDate");

  const { setLoading } = useLoading();
  const { loading, error } = useAppSelector(state => state.campaign);

  const tileClassName = ({ date, view }) => {
    return selectedDates.some((item) => new Date(formatTimeToBeginingOfDay(item)).getTime() === new Date(formatTimeToBeginingOfDay(date)).getTime()) ?
      'campaign-calendar-tile campaign-calendar-tile-available' :
      'campaign-calendar-tile'
  }

  const onFormSubmit = async (data: any) => {
    try {
      setLoading(true);
      const images = [];
      const latestImages = currentImages.filter((image) => image);
      for (const imageUrl of latestImages) {
        const imageResponse = imageUrl.includes('blob:') ? await uploadImageToS3(imageUrl) : null;

        images.push(imageResponse ? imageResponse?.data?.location : imageUrl);
      }

      const { id: currentCampaignId, createdAt, updatedAt, ...rest } = data || {};

      const updatedData: CampaignInterfaceProps = {
        ...rest,
        keywords: tags,
        images,
        channels,
        relatedHotelId: Number(data.relatedHotelId),
        minAudienceRequirements: data.minAudienceRequirements ? Number(data.minAudienceRequirements) : null,
        followerDiscountRate: data.followerDiscountRate ? Number(data.followerDiscountRate) : null,
        commissionOfferedRate: data.commissionOfferedRate ? Number(data.commissionOfferedRate) : null,
        availableDates: selectedDates,
        instagramRequirements: channels.includes('Instagram'),
        tiktokRequirements: channels.includes('TikTok'),
        description: stateToHTML(descriptionEditorState.getCurrentContent()),
        terms: stateToHTML(termsAndConditionsEditorState.getCurrentContent()),
      }

      const response = await dispatch(isEdit ? updateCampaign({ ...updatedData, id: currentCampaignId }) : createCampaign(updatedData));

      if (response?.payload?.isSuccess) {
        await refreshCampaigns();

        setLoading(false);
        setSelectedDates([]);
        onClose();
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const registerOptions = {
    crn: (): RegisterOptions => {
      return {
        required: 'CRN is required',
      }
    },
    name: (): RegisterOptions => {
      return {
        required: 'Name is required',
      }
    },
    relatedHotelId: (): RegisterOptions => {
      return {
        required: 'Related hotel is required',
      }
    },
    startDate: (): RegisterOptions => {
      return {
        required: 'Start date is required',
        validate: (value) => {
          if (new Date(value).getTime() < new Date(END_OF_TODAY).getTime())
            return 'Start date must be greater than today';
          if (new Date(value).getTime() > new Date(getValues('endDate')).getTime())
            return 'Start date must be less than end date';

          return true;
        },
        onChange: () => {
          setSelectedDates([]);
        }
      }
    },
    endDate: (): RegisterOptions => {
      return {
        required: 'End date is required',
        validate: (value) => {
          return new Date(value).getTime() > new Date(startDate).getTime() || 'End date must be greater than start date';
        },
        onChange: () => {
          setSelectedDates([]);
        }
      }
    },
    minAudienceRequirements: (): RegisterOptions => {
      return {
        validate: (value) => {
          return !isNaN(value) || 'Audience requirements must be a number';
        }
      }
    },
    followerDiscountRate: (): RegisterOptions => {
      return {
        validate: (value) => {
          return !isNaN(value) || 'Follower discount rate must be a number';
        }
      }
    },
    commissionOfferedRate: (): RegisterOptions => {
      return {
        validate: (value) => {
          return !isNaN(value) || 'Commission offered rate must be a number';
        }
      }
    },
  }

  const onSelectNewImage = (index: number) => (blobUrl: any) => {
    setCurrentImages((currentImages) => {
      const newImages = [...currentImages];
      if (index > -1) {
        newImages[index] = blobUrl;
      } else {
        newImages.push(blobUrl);
      }
      return newImages;
    });
  }

  const onSelectChannel = (channel: string) => {
    setChannels((prev) => {
      if (prev.includes(channel)) {
        return prev.filter((item) => item !== channel)
      } else {
        return [...prev, channel]
      }
    })
  }

  const handleDateSelection = (date: Date) => {
    setSelectedDates((prev) => {
      if (prev.some((pdate) => new Date(pdate).getTime() === date.getTime())) {
        return prev.filter((pdate) => new Date(pdate).getTime() !== date.getTime())
      } else {
        return [...prev, date]
      }
    })
  }

  useEffect(() => {
    const onEnterFormSubmit = (event: any) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        event.stopPropagation();
        form?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    }

    document.addEventListener('keypress', onEnterFormSubmit);

    return () => {
      document.removeEventListener('keypress', onEnterFormSubmit);
    }
  }, []);

  const validateDescription = (name, message, state: EditorState) => {
    if (!state.getCurrentContent().hasText()) {
      setTimeout(() => {
        setError(name, { message });
      }, 500);
    } else {
      clearErrors(name);
    }
  };

  return (
    <Modal open={open} onClose={onClose} className={classNames({ 'campaign-modal-container': !isMobile })}>
      <div className="campaign-create-modal-container">
        <h3 className="campaign-create-modal-heading">
          {isEdit ? 'Edit Campaign' : 'Create Campaign'}
        </h3>

        <form ref={form} onSubmit={handleSubmit(onFormSubmit)}>
          <div className="campaign-create-modal-fields">
            <div className="campaign-create-modal-form-container">
              <div className="campaign-create-modal-form-fields">
                <label htmlFor="crn">Campaign reference number <span className="required">*</span></label>
                <input id="crn" type="text" placeholder="CRN00001" {...register('crn', registerOptions.crn())} disabled={isEdit} />
                {errors.crn && <span className="error">{errors.crn.message}</span>}
              </div>
              <div className="campaign-create-modal-form-fields">
                <label htmlFor="name">Campaign name <span className="required">*</span></label>
                <input id="name" type="text" placeholder="Campaign name" {...register('name', registerOptions.name())} />
                {errors.name && <span className="error">{errors.name.message}</span>}
              </div>
            </div>
            <div className="campaign-create-modal-form-fields">
              <label htmlFor="description">Description <span className="required">*</span></label>
              <RichTextEditor
                editorState={descriptionEditorState}
                setEditorState={setDescriptionEditorState}
                editor={descriptionEditor}
                placeholder="Campaign description here..."
                onBlur={() => validateDescription('description', 'Description is required', descriptionEditorState)}
              />
              {errors.description && <span className="error">{errors.description.message}</span>}
            </div>
            <div className="campaign-create-modal-form-fields">
              <label htmlFor="terms">Terms and conditions <span className="required">*</span></label>
              <RichTextEditor
                editorState={termsAndConditionsEditorState}
                setEditorState={setTermsAndConditionsEditorState}
                editor={termsAndConditionsEditor}
                placeholder="Campaign terms and conditions here..."
                onBlur={() => validateDescription('terms', 'Terms and conditions is required', termsAndConditionsEditorState)}
              />
              {errors.terms && <span className="error">{errors.terms.message}</span>}
            </div>
            <div className="campaign-create-modal-form-container">
              <div className="campaign-create-modal-form-fields">
                <label htmlFor="relatedHotelId">Related hotel <span className="required">*</span></label>
                <select id="relatedHotelId" {...register('relatedHotelId', registerOptions.relatedHotelId())}>
                  <option value="">Select Hotel</option>
                  {hotels.map((hotel) => (
                    <option key={hotel.id} value={hotel.id}>{hotel.organisationName}</option>
                  ))}
                </select>
                {errors.relatedHotelId && <span className="error">{errors.relatedHotelId.message}</span>}
              </div>
              <div className="campaign-create-modal-form-fields">
                <label htmlFor="promotionCode">Promotion code</label>
                <input id="promotionCode" type="text" placeholder="Promotion code" {...register('promotionCode')} />
              </div>
            </div>
            <div className="campaign-create-modal-form-container">
              <div className="campaign-create-modal-form-fields">
                <label htmlFor="startDate">Start date <span className="required">*</span></label>
                <input id="startDate" type="date" {...register('startDate', registerOptions.startDate())} />
                {errors.startDate && <span className="error">{errors.startDate.message}</span>}
              </div>
              <div className="campaign-create-modal-form-fields">
                <label htmlFor="endDate">End date <span className="required">*</span></label>
                <input id="endDate" type="date" {...register('endDate', registerOptions.endDate())} disabled={!getValues('startDate')} />
                {errors.endDate && <span className="error">{errors.endDate.message}</span>}
              </div>
            </div>
            {getValues('endDate') ? <div className="campaign-create-modal-form-fields">
              <label htmlFor="budget">Available dates <span className="required">*</span></label>
              <Calendar
                className="campaign-create-modal-calendar"
                minDate={new Date(getValues('startDate'))}
                maxDate={new Date(getValues('endDate'))}
                tileClassName={tileClassName}
                onClickDay={handleDateSelection}
              />
            </div> : null}
            <div className="campaign-create-modal-form-fields">
              <label htmlFor="channels">Channels</label>
              {campaignChannelOptions.map((option, index) => (
                <div className="campaign-available-channel" key={index} onClick={() => onSelectChannel(option.value)}>
                  <input type="checkbox" checked={!!channels.includes(option.value)} readOnly />
                  <span>{option.label}</span>
                </div>
              ))}
            </div>
            {channels?.includes('Tiktok') && <div className="campaign-create-modal-form-fields">
              <label htmlFor="tiktokRequirements">Tiktok Requirements</label>
              <input id="tiktokRequirements" placeholder="Tiktok requirements" {...register('tiktokRequirementsDescription')} />
            </div>}
            {channels?.includes('Instagram') && <div className="campaign-create-modal-form-fields">
              <label htmlFor="instagramRequirements">Instagram Requirements</label>
              <input id="instagramRequirements" placeholder="Instagram requirements" {...register('instagramRequirementsDescription')} />
            </div>}
            <div className="campaign-create-modal-form-container">
              <div className="campaign-create-modal-form-fields">
                <label htmlFor="targetAudience">Target Audience</label>
                <input id="targetAudience" type="text" placeholder="Mid Age, Adult,..." {...register('targetAudience')} />
              </div>
              <div className="campaign-create-modal-form-fields">
                <label htmlFor="minAudienceRequirements">Minimum audience requirement</label>
                <input id="minAudienceRequirements" type="text" placeholder="Minimum number audience" {...register('minAudienceRequirements', registerOptions.minAudienceRequirements())} />
                {errors.minAudienceRequirements && <span className="error">{errors.minAudienceRequirements.message}</span>}
              </div>
            </div>
            <div className="campaign-create-modal-form-container">
              <div className="campaign-create-modal-form-fields">
                <label htmlFor="commissionOfferedRate">Commission offer rate</label>
                <input id="commissionOfferedRate" type="text" placeholder="Commission rate" {...register('commissionOfferedRate', registerOptions.commissionOfferedRate())} />
                {errors.commissionOfferedRate && <span className="error">{errors.commissionOfferedRate.message}</span>}
              </div>
              <div className="campaign-create-modal-form-fields">
                <label htmlFor="followerDiscountRate">Follower discount rate</label>
                <input id="followerDiscountRate" type="text" placeholder="Follower discount rate" {...register('followerDiscountRate', registerOptions.followerDiscountRate())} />
                {errors.followerDiscountRate && <span className="error">{errors.followerDiscountRate.message}</span>}
              </div>
            </div>

            <div className="campaign-create-modal-form-fields">
              <label htmlFor="keywords">Keywords</label>
              <TagsInput value={tags} onChange={setTags} placeHolder="Separate keywords with enter" />
            </div>

            <div className="campaign-create-modal-form-fields">
              <label htmlFor="images">Images</label>
              <div className="image-form-fields">
                {
                  currentImages.map((image, index) => (
                    image && <div className="image-form-field" key={index}>
                      <ImageUpload
                        setSelectedFile={onSelectNewImage(index)}
                        currentImage={image}
                        onImageRemove={() => setCurrentImages((currentImages) => currentImages.filter((_, i) => i !== index))}
                        className="campaign-create-modal-image-upload"
                      />
                    </div>
                  ))
                }
                <ImageUpload setSelectedFile={onSelectNewImage(-1)} className="campaign-create-modal-image-upload" />
              </div>
            </div>
          </div>

          <div className="campaign-create-modal-buttons">
            <Button variant="default" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={!isValid || loading}>
              {isEdit ? 'Update' : 'Create'}
            </Button>
          </div>
          {error && <p className="error system-error">{error}</p>}
        </form>
      </div>
    </Modal>
  )
}