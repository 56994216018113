import { createSlice } from '@reduxjs/toolkit'
import { registerUser, loginUser, updateUserPassword, getUserProfile, forgotPassword, resetPassword, verifyTwoFactorAuth, verifyOtpLogin } from './authAction';

const initialState: AuthState = {
  loading: false,
  userInfo: {},
  accessToken: null,
  error: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.loading = false
      state.userInfo = payload.user
      state.accessToken = payload.accessToken
    })
    builder.addCase(registerUser.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(loginUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.loading = false
      state.userInfo = payload.user
      state.accessToken = payload.accessToken
    })
    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(updateUserPassword.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateUserPassword.fulfilled, (state, { payload }) => {
      state.loading = false
      state.userInfo = payload.user
      state.accessToken = payload.accessToken
    })
    builder.addCase(updateUserPassword.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(getUserProfile.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
      state.loading = false
      state.userInfo = payload.data
    })
    builder.addCase(getUserProfile.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(forgotPassword.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    builder.addCase(forgotPassword.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(verifyTwoFactorAuth.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(verifyTwoFactorAuth.fulfilled, (state, { payload }) => {
      state.loading = false
      state.userInfo = payload.data
    })
    builder.addCase(verifyTwoFactorAuth.rejected, (state, { payload }) => {
      state.loading = false
    })

    builder.addCase(verifyOtpLogin.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(verifyOtpLogin.fulfilled, (state, { payload }) => {
      state.loading = false
      state.userInfo = payload.user
      state.accessToken = payload.accessToken
    })
    builder.addCase(verifyOtpLogin.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
  },
});

export interface AuthState {
  loading: boolean;
  userInfo: any;
  accessToken: string | null;
  error: any;
}
