import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { AiOutlinePlus } from "react-icons/ai";
import { AlertModal, Button, Table } from "../../../elements";
import { ColumnDef } from "@tanstack/react-table";
import { CampaignActionMenu, CampaignCard, CampaignCreateModal, CampaignViewModal, ManageOfferModal } from "../../../common";
import { CampaignInterfaceProps, OfferInterfaceProps, OfferStatus, UserInterfaceProps, UserRoles } from "../../../../interfaces";
import { acceptOffer, cancelCampaign, cancelOffer, deleteCampaign, fetchCampaigns, fetchHotels, getCampaignByHotelOwnerId, getInfluencers, getOfferByCampaignId, rejectOffer, sendOfferByHotel } from "../../../../redux";
import { formatDate } from "../../../../utils/timeHelpers";
import { getCampaignStatus, isCampaignLive, sortDescByCreatedAt } from "../../../../utils/dataHelpers";
import { sanitizeHTML } from "../../../../utils/stringHelpers";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useCurrentUser, useLoading } from "../../../../hooks";
import { useLongPress } from "use-long-press";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { useBodyClassName } from "../../../../hooks/useBodyClassName";

import "./ManageCampaignPage.scss";

export const ManageCampaignPage: React.FC = () => {
  useBodyClassName("bg-white");

  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [manageOfferVisible, setManageOfferVisible] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignInterfaceProps | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const { isMobile } = useWindowSize();
  const { loading, setLoading } = useLoading();
  const bind = useLongPress(() => {
    setDeleteModalVisible(true);
  }, {
    onStart: (event, meta) => {
      setSelectedCampaign(meta.context as CampaignInterfaceProps);
    }
  });

  const { campaigns } = useAppSelector(state => state.campaign);
  const { hotels } = useAppSelector(state => state.hotel);
  const { influencers } = useAppSelector(state => state.user);
  const { offers } = useAppSelector(state => state.offer);

  useEffect(() => {
    refreshCampaigns();

    if (!hotels || !hotels.length) {
      dispatch(fetchHotels({ page: 1, limit: 10 }));
    }

    dispatch(getInfluencers({}));
  }, []);

  const onEditCampaign = (campaign: CampaignInterfaceProps) => {
    setSelectedCampaign(campaign);
    setIsEdit(true);
    setCreateModalVisible(true);
  }

  const onDeleteCampaign = (campaign: CampaignInterfaceProps) => {
    setSelectedCampaign(campaign);
    setDeleteModalVisible(true);
  }

  const onCopyCampaign = (campaign: CampaignInterfaceProps) => {
    setSelectedCampaign(campaign);
    setIsCopy(true);
    setCreateModalVisible(true);
  }

  const onManageOffers = async (campaign: CampaignInterfaceProps) => {
    setSelectedCampaign(campaign);

    const offerResponse = await dispatch(getOfferByCampaignId({ campaignId: campaign.id }));

    if (offerResponse?.payload?.isSuccess) {
      setManageOfferVisible(true);
    }
  }

  const onCloseCreateModal = () => {
    setSelectedCampaign(null);
    setIsEdit(false);
    setIsCopy(false);
    setCreateModalVisible(false);
  }

  const onConfirmDelete = async () => {
    try {
      setLoading(true);
      const response = await dispatch(deleteCampaign(selectedCampaign?.id));

      if (response?.payload?.isSuccess) {
        setDeleteModalVisible(false);
        setSelectedCampaign(null);

        await refreshCampaigns();
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSelectedCampaign(null);
    }
  }

  const onViewCampaign = async (campaign: CampaignInterfaceProps) => {
    setSelectedCampaign(campaign);

    await dispatch(getOfferByCampaignId({ campaignId: campaign.id, queryParams: { status: OfferStatus.ACCEPTED } }));

    setViewModalVisible(true);
  }

  const onCloseViewModal = () => {
    setSelectedCampaign(null);
    setViewModalVisible(false);
  }

  const onCancelCampaign = (campaign: CampaignInterfaceProps) => {
    setSelectedCampaign(campaign);
    setCancelModalVisible(true);
  }

  const onConfirmCancel = async () => {
    try {
      setLoading(true);
      const response = await dispatch(cancelCampaign(selectedCampaign?.id));

      if (response?.payload?.isSuccess) {
        setCancelModalVisible(false);
        setSelectedCampaign(null);

        await refreshCampaigns();
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSelectedCampaign(null);
    }
  }

  const onSendOffer = async (campaign: CampaignInterfaceProps, user: UserInterfaceProps) => {
    setLoading(true);
    try {
      const offerParams: OfferInterfaceProps = {
        relatedCampaignId: campaign.id,
        relatedHotelId: campaign.relatedHotelId,
        relatedInfluencerId: user.id,
      }

      await dispatch(sendOfferByHotel(offerParams));
      await dispatch(getOfferByCampaignId({ campaignId: campaign.id }));

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const onActionOffer = async (offer: OfferInterfaceProps, callback: Function) => {
    setLoading(true);
    try {
      await dispatch(callback(offer.id));

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const refreshCampaigns = async () => {
    currentUser?.role === UserRoles.HOTEL_MANAGER ? await dispatch(getCampaignByHotelOwnerId(currentUser.id)) : await dispatch(fetchCampaigns());
  }

  const columns = useMemo<ColumnDef<CampaignInterfaceProps>[]>(() => [
    {
      header: 'CRN',
      accessorKey: 'crn',
      meta: {
        columnClassName: 'sticky left',
      }
    },
    {
      header: 'Title',
      accessorKey: 'name',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      cell: ({ row, getValue }) => (
        <span className="campaign-table-description" dangerouslySetInnerHTML={{ __html: sanitizeHTML(getValue<string>()) }}></span>
      )
    },
    {
      header: 'Hotel',
      accessorKey: 'hotel.organisationName',
    },
    {
      header: 'Start Date',
      accessorKey: 'startDate',
      cell: ({ row, getValue }) => (
        <span>{formatDate(getValue<string>())}</span>
      )
    },
    {
      header: 'End Date',
      accessorKey: 'endDate',
      cell: ({ row, getValue }) => (
        <span>{formatDate(getValue<string>())}</span>
      )
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: ({ row, getValue }) => (
        <span className={classNames("campaign-table-status", getCampaignStatus(row.original).toLowerCase())}>{getCampaignStatus(row.original)}</span>
      )
    },
    {
      header: 'Created At',
      accessorKey: 'createdAt',
      cell: ({ row, getValue }) => (
        <span>{formatDate(getValue<string>())}</span>
      )
    },
    {
      header: 'Actions',
      cell: ({ row }) => (
        <div className='campaign-table-actions'>
          <CampaignActionMenu
            campaign={row.original}
            onViewCampaign={onViewCampaign}
            onEditCampaign={onEditCampaign}
            onCopyCampaign={onCopyCampaign}
            onDeleteCampaign={onDeleteCampaign}
            onCancelCampaign={onCancelCampaign}
            onManageOffers={onManageOffers}
          />
        </div>
      ),
      size: 190,
      meta: {
        columnClassName: 'sticky right',
      }
    }
  ], []);

  return (
    <div className="manage-campaign-page-container">
      <div className="manage-campaign-page-header">
        <h1>Manage Campaign Page</h1>
        <Button variant={'primary'} onClick={() => setCreateModalVisible(true)}>
          {isMobile ? <AiOutlinePlus /> : 'Create'}
        </Button>
      </div>

      {campaigns && campaigns.length ? (
        <div className="manage-campaign-page-list">
          {isMobile ? (
            sortDescByCreatedAt(campaigns).map((campaign: CampaignInterfaceProps) => (
              <div className="manage-campaign-page-card" {...bind(campaign)} key={campaign.id}>
                <CampaignCard
                  onClick={() => isCampaignLive(campaign) ? onViewCampaign(campaign) : onEditCampaign(campaign)}
                  key={campaign.id}
                  campaign={campaign}
                  benefits={campaign.hotel.facilities}
                  showActionMenu
                  actionMenu={{
                    campaign,
                    onViewCampaign,
                    onEditCampaign,
                    onCopyCampaign,
                    onDeleteCampaign,
                    onCancelCampaign,
                    onManageOffers,
                  }}
                />
              </div>
            ))
          ) : (
            <Table columns={columns} data={sortDescByCreatedAt(campaigns)} />
          )}
        </div>
      ) : (
        <div className="manage-campaign-page-empty">
          <h2>No campaign found</h2>
        </div>
      )}

      {createModalVisible ? <CampaignCreateModal
        open={createModalVisible}
        onClose={() => onCloseCreateModal()}
        currentCampaign={selectedCampaign}
        isEdit={isEdit}
        isCopy={isCopy}
        hotels={hotels}
        refreshCampaigns={refreshCampaigns}
      /> : null}

      {viewModalVisible ? <CampaignViewModal
        open={viewModalVisible}
        offers={offers}
        onClose={() => onCloseViewModal()}
        campaign={selectedCampaign}
      /> : null}

      {manageOfferVisible ? <ManageOfferModal
        open={manageOfferVisible}
        onClose={() => setManageOfferVisible(false)}
        campaign={selectedCampaign}
        offers={offers}
        users={influencers}
        onSendOffer={onSendOffer}
        onAcceptOffer={(offer: OfferInterfaceProps) => onActionOffer(offer, acceptOffer)}
        onRejectOffer={(offer: OfferInterfaceProps) => onActionOffer(offer, rejectOffer)}
        onCanceledOffer={(offer: OfferInterfaceProps) => onActionOffer(offer, cancelOffer)}
      /> : null}

      <AlertModal
        open={deleteModalVisible || cancelModalVisible}
        onClose={() => deleteModalVisible ? setDeleteModalVisible(false) : setCancelModalVisible(false)}
        title={deleteModalVisible ? 'Delete Campaign' : 'Cancel Campaign'}
        description={`Are you sure you want to ${deleteModalVisible ? 'delete' : 'cancel'} this campaign, it will ${deleteModalVisible ? 'delete' : 'cancel'} all related offers?`}
        onConfirm={() => deleteModalVisible ? onConfirmDelete() : onConfirmCancel()}
        loading={loading}
      />
    </div>
  )
};