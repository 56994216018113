import React from "react"
import classNames from "classnames"
import CustomCalendar, { TileClassNameFunc } from "react-calendar"

import 'react-calendar/dist/Calendar.css'
import './Calendar.scss'

interface CalendarProps {
  className?: string,
  showDoubleView?: boolean,
  defaultView?: 'month' | 'year' | 'decade' | 'century',
  activeStartDate?: Date,
  minDate?: Date,
  maxDate?: Date,
  tileClassName?: string | TileClassNameFunc,
  showFixedNumberOfWeeks?: boolean,
  showNeighboringMonth?: boolean,
  onClickDay?: (value: Date) => void,
}

export const Calendar = ({
  className,
  showDoubleView = true,
  defaultView = 'month',
  activeStartDate,
  minDate,
  maxDate,
  tileClassName,
  showFixedNumberOfWeeks = false,
  showNeighboringMonth = false,
  onClickDay,
}: CalendarProps) => {
  return (
    <CustomCalendar
      className={classNames("custom-calendar", className)}
      showDoubleView={showDoubleView}
      defaultView={defaultView}
      activeStartDate={activeStartDate}
      minDate={minDate}
      maxDate={maxDate}
      tileClassName={tileClassName}
      prev2Label={null}
      next2Label={null}
      showFixedNumberOfWeeks={showFixedNumberOfWeeks}
      showNeighboringMonth={showNeighboringMonth}
      formatShortWeekday={(locale, date) => ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][date.getDay()]}
      onClickDay={onClickDay}
    />
  )
}