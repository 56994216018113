import classNames from 'classnames'
import React from 'react'

import './Button.scss'

interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  variant?: 'primary' | 'secondary' | 'default' | 'danger'
}

export const Button: React.FC<ButtonProps> = ({
  variant = 'default',
  className,
  ...restProps
}) => (
  <button className={classNames('button', variant, className)} {...restProps} />
)
