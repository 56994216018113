import React from "react"
import Toolbar from "./Toolbar/Toolbar";
import { ContentBlock, Editor, EditorState, RichUtils } from "draft-js"

import "./RichTextEditor.scss";
import 'draft-js/dist/Draft.css';

interface RichTextEditorProps {
  editorState: EditorState,
  setEditorState: (editorState: EditorState) => void,
  editor: any,
  placeholder?: string,
  onBlur?: () => void,
}

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  editorState,
  setEditorState,
  editor,
  placeholder = "Write anything here...",
  onBlur = () => { },
}) => {
  const focusEditor = () => {
    editor.current.focus();
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const myBlockStyleFn = (contentBlock: ContentBlock) => {
    const type = contentBlock.getType();
    switch (type) {
      case "leftAlign":
        return "leftAlign";
      case "rightAlign":
        return "rightAlign";
      case "centerAlign":
        return "centerAlign";
      default:
        break;
    }

    return "";
  };

  return (
    <div className="editor-wrapper" onClick={focusEditor}>
      <Toolbar editorState={editorState} setEditorState={setEditorState} />
      <div className="editor-container">
        <Editor
          ref={editor}
          placeholder={placeholder}
          handleKeyCommand={handleKeyCommand}
          editorState={editorState}
          blockStyleFn={myBlockStyleFn}
          onChange={(editorState) => setEditorState(editorState)}
          onBlur={onBlur}
        />
      </div>
    </div>
  )
}