import classNames from 'classnames'
import React from 'react'

import './Tab.scss'

export interface Pane {
  id: string
  title: string | React.ReactNode
  element: React.ReactNode
}

interface TabProps {
  panes: Pane[]
  position?: 'left' | 'center' | 'right'
  selectedId?: string
  className?: string
  infoElement?: React.ReactNode
}

export const Tab: React.FC<TabProps> = ({
  position = 'right',
  panes,
  selectedId,
  className,
  infoElement,
}) => {
  const [selectedPaneId, setSelectedPaneId] = React.useState(
    selectedId || panes[0].id,
  )
  const selectedPane = React.useMemo(
    () => panes.find((pane) => pane.id === selectedPaneId),
    [panes, selectedPaneId],
  )

  return (
    <div className={classNames('tab-container', className)}>
      <ul
        className={classNames('tab-navigation', {
          left: position === 'left',
          center: position === 'center',
          right: position === 'right',
        })}>
        {infoElement && (
          <li className="tab-navigation-item-element">{infoElement}</li>
        )}
        {panes.map(({ id, title }) => (
          <li
            key={id}
            className={classNames('tab-navigation-item', {
              active: id === selectedPaneId,
            })}
            onClick={() => setSelectedPaneId(id)}>
            {title}
          </li>
        ))}
      </ul>
      <div key={selectedPane?.id} className="tab-content">
        {selectedPane?.element}
      </div>
    </div>
  )
}
