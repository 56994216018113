import { CampaignInterfaceProps, CampaignStatus, UserRoles } from "../interfaces";
import { formatTimeToEndOfDay } from "./timeHelpers";
import { END_OF_TODAY, START_OF_TODAY } from "./types";

import HornIcon from '../assets/icons/Horn.svg'
import BedIcon from '../assets/icons/Bed.svg'
import UserIcon from '../assets/icons/User.svg'
import HotelIcon from '../assets/icons/Hotels.svg'
import MessageIcon from '../assets/icons/Messages.svg'
import SettingsIcon from '../assets/icons/Settings.svg'
import OffersIcon from '../assets/icons/Offers.svg'
import HelpIcon from '../assets/icons/Help.svg'

export const isCampaignLive = (campaign: CampaignInterfaceProps) => {
  return new Date(campaign.startDate).getTime() <= END_OF_TODAY || campaign.status === CampaignStatus.LIVE
}

export const isCampaignCompleted = (campaign: CampaignInterfaceProps) => {
  return formatTimeToEndOfDay(campaign.endDate) <= START_OF_TODAY || campaign.status === CampaignStatus.COMPLETED
}

export const isCampaignCancelled = (campaign: CampaignInterfaceProps) => {
  return campaign.status === CampaignStatus.CANCELLED
}

export const isCampaignScheduled = (campaign: CampaignInterfaceProps) => {
  return new Date(campaign.startDate).getTime() > START_OF_TODAY
}

export const getCampaignStatus = (campaign: CampaignInterfaceProps) => {
  if (isCampaignCancelled(campaign)) return CampaignStatus.CANCELLED
  if (isCampaignCompleted(campaign)) return CampaignStatus.COMPLETED
  if (isCampaignLive(campaign)) return CampaignStatus.LIVE
  if (isCampaignScheduled(campaign)) return CampaignStatus.SCHEDULED
  return CampaignStatus.SCHEDULED;
}

export const sortDescByCreatedAt = (dataList: Array<any>) => {
  return dataList.slice().sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  })
}

export const getHomePageMenuItems = (
  role: UserRoles,
  notification?: {
    newCampaigns?: number,
    newOffers?: number,
    newHotels?: number,
    newMessages?: number
    newMyCampaigns?: number
  }
) => {
  const basicMenuItems = [
    {
      id: 6,
      title: 'Messages',
      icon: MessageIcon,
      iconWidth: 60,
      newItems: notification?.newMessages || undefined,
      to: '/messages',
    },
    {
      id: 7,
      title: 'Help',
      icon: HelpIcon,
      iconWidth: 120,
      iconStyle: {
        transform: 'translateY(9px)',
      },
      to: '/help',
    },
    {
      id: 8,
      title: 'Settings',
      icon: SettingsIcon,
      iconWidth: 70,
      to: '/settings',
    },
  ];

  const influencerMenuItems = [
    {
      id: 1,
      title: 'Browse Campaigns',
      icon: BedIcon,
      iconWidth: 80,
      to: '/campaigns',
      newItems: notification?.newCampaigns || undefined,
    },
    {
      id: 2,
      title: 'Browse Hotels',
      icon: HotelIcon,
      iconWidth: 60,
      to: '/hotels',
      newItems: notification?.newHotels || undefined,
    },
    {
      id: 3,
      title: 'My Offers',
      icon: OffersIcon,
      iconWidth: 70,
      iconStyle: {
        transform: 'translateY(6px)',
      },
      newItems: notification?.newOffers || undefined,
      to: '/campaigns/my-offers',
    },
    {
      id: 4,
      title: 'My Campaigns',
      icon: HornIcon,
      iconWidth: 70,
      newItems: notification?.newMyCampaigns || undefined,
      to: '/campaigns/my-campaigns',
    },
    {
      id: 5,
      title: 'Profile',
      icon: UserIcon,
      iconWidth: 70,
      to: '/profile',
    },
  ];

  const adminMenuItems = [{
    id: 9,
    title: role === UserRoles.HOTEL_MANAGER ? 'Hotel Management' : 'Admin Dashboard',
    icon: role === UserRoles.HOTEL_MANAGER ? HotelIcon : SettingsIcon,
    iconWidth: role === UserRoles.HOTEL_MANAGER ? 60 : 70,
    to: '/admin/hotels',
  }];

  if (role === UserRoles.ADMIN) {
    return [...influencerMenuItems, ...basicMenuItems, ...adminMenuItems];
  }

  if (role === UserRoles.HOTEL_MANAGER) {
    return [...basicMenuItems, ...adminMenuItems];
  }

  return [...influencerMenuItems, ...basicMenuItems];
};