import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"

export const useAuth = () => {
  const [cookie] = useCookies();
  const [auth, setAuth] = useState(cookie['accessToken'] ? true : false);

  useEffect(() => {
    if (cookie['accessToken']) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [cookie]);

  return auth;
}

export const useCurrentUser = () => {
  const userInfo = localStorage.getItem('userInfo');
  const [user, setUser] = useState(userInfo ? JSON.parse(userInfo as string) : {});

  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      setUser(JSON.parse(localStorage.getItem('userInfo') as string));
    } else {
      setUser({});
    }
  }, [localStorage, userInfo]);

  return user;
}