import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'

import { AlertModal, Button, Input, Loading, Tags } from '../../../elements'
import { updateConfig } from '../../../../redux'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { useBodyClassName } from '../../../../hooks/useBodyClassName'

import './ManageConfigsPage.scss'

export const ManageConfigsPage: React.FC = () => {
  useBodyClassName('bg-white')
  const [interest, setInterest] = useState('')
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState<string | boolean>(
    false
  )
  const [error, setError] = useState('')

  const dispatch = useAppDispatch()
  const { width, isMobile } = useWindowSize()

  const [updatedTags, setUpdatedTags] = useState<string[]>([])

  const { configs, configsLoading } = useAppSelector((state) => state.config)
  const loading = useAppSelector((state) => state.config.updateConfigLoading)
  const interestConfig = configs?.find((config) => config.type === 'Interests')

  const tags = interestConfig?.content

  useEffect(() => {
    setUpdatedTags(tags || [])
  }, [tags])

  const handleDeleteConfirm = () => {
    setShowDeleteConfirmModal(false)
    setUpdatedTags(updatedTags.filter((tag) => tag !== showDeleteConfirmModal))
    dispatch(
      updateConfig({
        id: interestConfig.id,
        type: interestConfig.type,
        content: updatedTags.filter((tag) => tag !== showDeleteConfirmModal)
      })
    )
  }

  const handleAddTag = () => {
    if (interest) {
      const alreadyExist = updatedTags.includes(interest)
      if (alreadyExist) {
        return setError('Interest already exist')
      }

      setUpdatedTags([...updatedTags, interest])

      dispatch(
        updateConfig({
          id: interestConfig.id,
          type: interestConfig.type,
          content: [...updatedTags, interest]
        })
      )

      setInterest('')
    } else {
      setError('Interest is required')
    }
  }

  const handleTagClick = (tag: string) => {
    setShowDeleteConfirmModal(tag)
  }

  if (configsLoading) {
    return <Loading />
  }

  return (
    <div
      className="manage-configs-page-container"
      style={isMobile ? { width: width, maxWidth: width } : {}}>
      <div className="manage-configs-page-header">
        <h1>Manage Configs</h1>
      </div>

      <p>Available Interests</p>
      <Tags tags={updatedTags} onClick={handleTagClick} />

      <div className="manage-configs-page-input">
        <Input
          placeholder="New interest"
          value={interest}
          onChange={(e) => {
            setError('')
            setInterest(e.target.value)
          }}
        />
        <Button variant="default" onClick={handleAddTag}>
          <AiOutlinePlus />
        </Button>
      </div>
      {error ? <p className="manage-configs-page-error">{error}</p> : null}
      {loading && <Loading />}
      <AlertModal
        open={Boolean(showDeleteConfirmModal)}
        onClose={() => setShowDeleteConfirmModal(false)}
        title={'Delete interest'}
        description={`Are you sure you want to delete this tag?`}
        onConfirm={handleDeleteConfirm}
        loading={loading}
      />
    </div>
  )
}
