import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'

import { AppLayout } from '../../../layout'
import { CampaignCard } from '../../../common/campaign'
import { CampaignInterfaceProps } from '../../../../interfaces'

import { fetchCampaigns } from '../../../../redux'
import { isCampaignCancelled, sortDescByCreatedAt } from '../../../../utils/dataHelpers'
import { isRecentlyAdded } from '../../../../utils/timeHelpers'
import { useLoading } from '../../../../hooks'

import './CampaignsPage.scss'

export const CampaignsPage: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const { setLoading } = useLoading();

  const { campaigns, loading } = useAppSelector(state => state.campaign);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchCampaigns());
  }, []);

  useEffect(() => {
    if (campaigns && !loading) {
      setLoading(false);
    }
  }, [campaigns, loading]);

  return (
    <AppLayout backButtonVisible title="Available Campaigns">
      <div className="available-campaigns-container">
        {(sortDescByCreatedAt(campaigns || [])).filter(item => !isCampaignCancelled(item)).map((item: CampaignInterfaceProps) => (
          <CampaignCard
            key={item.crn}
            onClick={() => navigate(`/campaigns/${item.crn}`)}
            benefits={item.hotel.facilities}
            campaign={item}
            isRecentlyAdded={isRecentlyAdded(item.createdAt)}
            isShowDistance
          />
        ))}
      </div>
    </AppLayout>
  )
}
