import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { SlideProps } from "react-slideshow-image"

import { CampaignTags } from "../CampaignTags/CampaignTags"
import { CampaignDetailOptions } from "../CampaignDetailOptions/CampaignDetailOptions"
import { CampaignDetailActions } from "../CampaignDetailAction/CampaignDetailAction"
import { CampaignDetailEnquiryModal } from "../CampaignDetailEnquiryModal/CampaignDetailEnquiryModal"
import { ImageCarousel } from "../../../elements"
import { CampaignInterfaceProps, HotelInterfaceProps, OfferInterfaceProps } from "../../../../interfaces"

import './CampaignDetailPanel.scss'
import { MessageCreateModal } from "../../message"

interface CampaignDetailPanelProps {
  campaign: CampaignInterfaceProps,
  hotel: HotelInterfaceProps,
  offer?: OfferInterfaceProps | null,
  isMyCampaign?: boolean,
  isMyOffer?: boolean,
  isCampaignRegisteredOrApplied?: boolean,
}

const campaignOptions = [
  { id: 1, title: 'Campaign Description' },
  { id: 2, title: 'Deliverables' },
  { id: 3, title: 'Campaign Terms' },
  { id: 4, title: 'Terms & Conditions' },
]

export const CampaignDetailPanel = ({
  campaign,
  hotel,
  offer = null,
  isMyCampaign = false,
  isMyOffer = false,
  isCampaignRegisteredOrApplied = false,
}: CampaignDetailPanelProps) => {
  const navigate = useNavigate();

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [checkActive, setCheckActive] = useState(false);
  const [messageActive, setMessageActive] = useState(false);
  const [isMessageModalVisible, setIsMessageModalVisible] = useState(false);

  const slideOptions: SlideProps = {
    duration: 3000,
    arrows: false,
    children: null
  };

  return (
    <div className="campaign-detail-container">
      <div className="campaign-detail-image">
        <ImageCarousel images={[...(campaign?.images || []), ...(hotel?.images || [])]} options={slideOptions} />
      </div>

      <div className="campaign-detail-tags">
        <div className="tag-label">Benefits</div>
        <div className="tag-list">
          <CampaignTags
            type="benefit"
            tags={hotel.facilities}
          />
        </div>

        <div className="tag-label">Tags</div>
        <div className="tag-list">
          <CampaignTags type="tag" tags={campaign.keywords} />
        </div>
      </div>

      <div className="campaign-detail-options">
        <CampaignDetailOptions
          campaign={campaign}
          isMyCampaign={isMyCampaign}
          options={campaignOptions}
          onChange={(newSelected) => {
            const isMessageActive = [1, 2].every((value) =>
              newSelected.includes(value),
            )
            const isCheckActive = [1, 2, 3, 4].every((value) =>
              newSelected.includes(value),
            )
            setMessageActive(isMessageActive)
            setCheckActive(isCheckActive)
          }}
        />
      </div>

      <CampaignDetailActions
        campaign={campaign}
        checkActive={checkActive}
        messageActive={messageActive}
        isMyCampaign={isMyCampaign}
        isCampaignRegisteredOrApplied={isCampaignRegisteredOrApplied}
        onCheck={() => setConfirmModalVisible(true)}
        onMessage={() => setIsMessageModalVisible(true)}
        onCancel={() => navigate(-1)}
      />

      <CampaignDetailEnquiryModal
        campaign={campaign}
        isMyOffer={isMyOffer}
        currentOffer={offer}
        open={confirmModalVisible}
        onClose={() => setConfirmModalVisible(false)}
      />

      {isMessageModalVisible && (
        <MessageCreateModal
          open={isMessageModalVisible}
          onClose={() => setIsMessageModalVisible(false)}
          recipientId={campaign.hotel.relatedOwnerId}
          campaign={campaign}
        />
      )}
    </div>
  )
}


