const TWO_WEEKS_TIMESTAMP = 1209600000;

export const isRecentlyAdded = (createdAt: string | Date) => {
  const createdTimestamp = new Date(createdAt).getTime();
  const currentTimestamp = Date.now();
  return currentTimestamp - createdTimestamp < TWO_WEEKS_TIMESTAMP;
}

export const formatTimeToBeginingOfDay = (date: string | Date): number => {
  return new Date(date).setHours(0, 0, 0, 0);
}

export const formatTimeToEndOfDay = (date: string | Date): number => {
  return new Date(date).setHours(23, 59, 59, 999);
}

export const formatDate = (date: string | Date, options: any = {}) => {
  return new Date(date).toLocaleDateString('en-UK', options);
}