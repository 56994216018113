import React, { useEffect, useState } from "react"
import classNames from "classnames"
import QRCode from "qrcode"
import { useForm } from "react-hook-form"
import { AppLayout } from "../../../layout"
import { MdOutlineSecurity } from "react-icons/md"
import { Button } from "../../../elements"
import { useCurrentUser, useLoading } from "../../../../hooks"
import { useAppDispatch } from "../../../../redux/hooks"
import { enableTwoFactorAuth, removeTwoFactorAuth, verifyTwoFactorAuth } from "../../../../redux"

import "./MultiFactorAuthenticationPage.scss"

enum MultiFactor {
  NONE = 0,
  VERIFY_PASSWORD = 1,
  VERIFY_OTP = 2,
};

export const MultiFactorAuthenticationPage: React.FC = () => {
  const currentUser = useCurrentUser();
  const dispatch = useAppDispatch();
  const { loading, setLoading } = useLoading();
  const [step, setStep] = useState(MultiFactor.NONE);
  const [error, setError] = useState<string | null>(null);
  const [isMultiFactorEnabled, setIsMultiFactorEnabled] = useState(currentUser?.enabled2fa || false);
  const [verifyData, setVerifyData] = useState<any>({});
  const { register, handleSubmit, formState: { errors, isValid } } = useForm<{
    password: string;
    email: string;
  }>({
    mode: "onChange",
    delayError: 200,
    defaultValues: {
      email: currentUser?.email,
    }
  });

  const otpForm = useForm<{
    token: string;
  }>({
    mode: "onChange",
    delayError: 200,
  });

  useEffect(() => {
    setIsMultiFactorEnabled(currentUser?.enabled2fa || false);
  }, [JSON.stringify(currentUser)])

  const onEnable = () => {
    setStep(MultiFactor.VERIFY_PASSWORD);
  }

  const onDisable = async () => {
    try {
      setLoading(true);

      const response = await dispatch(removeTwoFactorAuth({}));

      if (response.payload?.isSuccess) {
        setIsMultiFactorEnabled(false);
        setStep(MultiFactor.NONE);
        setError(null);
        localStorage.setItem('userInfo', JSON.stringify(response.payload.data));
      }

      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  const onPasswordFormSubmit = async (data: any) => {
    try {
      setLoading(true);

      const response = await dispatch(enableTwoFactorAuth(data));

      if (response.payload?.isSuccess) {
        const qrCodeUrl = await QRCode.toDataURL(response.payload.data.uri);

        setVerifyData({ ...response.payload.data, qrCodeUrl });
        setStep(MultiFactor.VERIFY_OTP);
        setError(null);
      }

      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  const onOtpFormSubmit = async (data: any) => {
    try {
      setLoading(true);

      const response = await dispatch(verifyTwoFactorAuth({
        ...data,
        secret: verifyData?.secret,
      }));

      if (response.payload?.isSuccess) {
        setIsMultiFactorEnabled(true);
        setStep(MultiFactor.NONE);
        setError(null);
        localStorage.setItem('userInfo', JSON.stringify(response.payload.data));
      }

      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  return (
    <AppLayout title="Two-Step Verification" backButtonVisible>
      {step === MultiFactor.NONE && (
        <div className="factor-container">
          <div className="factor-title">
            <div className="factor-icon">
              <MdOutlineSecurity size={20} />
            </div>
            <div className="factor-title-text">
              Two-Step Verification
            </div>
            <div className="factor-title-description">
              Add an extra layer of security to your account
            </div>
            <div className={classNames("factor-title-status", { 'active': isMultiFactorEnabled })}>
              Status: {isMultiFactorEnabled ? <span>Active</span> : <span>Inactive</span>}
            </div>
          </div>
          <div className="factor-action">
            {isMultiFactorEnabled ? (
              <Button variant="danger" onClick={onDisable}>Disable</Button>
            ) : (
              <Button variant="primary" onClick={onEnable}>Enable</Button>
            )}
          </div>
        </div>
      )}
      {step === MultiFactor.VERIFY_PASSWORD && (
        <div className="factor-container">
          <form onSubmit={handleSubmit(onPasswordFormSubmit)}>
            <div className="factor-form-field">
              <label htmlFor="password">Please confirm your password <span className="required">*</span></label>
              <input id="password" type="password" placeholder="Your password here" {...register('password', { required: 'Password is required' })} />
              {errors.password && <span className="error">{errors.password.message}</span>}
            </div>


            <div className="factor-form-actions">
              <Button variant="primary" type="submit" disabled={!isValid || loading}>Verify</Button>
              {error && <p className="error system-error">{error}</p>}
            </div>
          </form>
        </div>
      )}
      {step === MultiFactor.VERIFY_OTP && (
        <div className="factor-container">
          <div className="factor-information">
            <div className="factor-information-title">
              Set up two-step verification
            </div>
            <div className="factor-information-description">
              To be able to use two-step verification, scan this QR code with your authenticator app to get started
            </div>
            <div className="factor-information-qr">
              <img src={verifyData?.qrCodeUrl} alt="QR Code" />
            </div>

            <div className="factor-information-secret">
              <span>Or copy this code and setup on your authenticator app</span>
              <div className="factor-information-secret-detail">
                {verifyData?.secret}
              </div>
            </div>

            <div className="factor-information-otp">
              <form onSubmit={otpForm.handleSubmit(onOtpFormSubmit)}>
                <div className="factor-information-otp-field">
                  <label htmlFor="token">Verification code</label>
                  <input id="token" type="text" placeholder="123456" {...otpForm.register('token', { required: 'Verification code is required' })} />
                  {otpForm.formState.errors.token && <span className="error">{otpForm.formState.errors.token.message}</span>}
                </div>
                <div className="factor-information-otp-action">
                  <Button variant="primary" type="submit" disabled={!otpForm.formState.isValid || loading}>Submit</Button>
                  {error && <p className="error system-error">{error}</p>}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </AppLayout>
  )
}