import React, { useEffect, useState } from "react"
import { AppLayout } from "../../layout"
import { Viewer } from "@react-pdf-viewer/core"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import "./HelpPage.scss"

const pdfjs = await import('pdfjs-dist/build/pdf');
const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');

export const HelpPage: React.FC = () => {
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [isWorkerLoaded, setIsWorkerLoaded] = useState(false);


  useEffect(() => {
    if (pdfjs && pdfjsWorker) {
      pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
      setIsWorkerLoaded(true);
    }
  }, [pdfjs, pdfjsWorker]);

  return (
    <AppLayout title="Help" backButtonVisible>
      {isWorkerLoaded && <Viewer
        fileUrl="https://voi-storage-prod.s3.eu-west-2.amazonaws.com/VOiApp_Help_Jan24+UPD.pdf"
        plugins={[
          defaultLayoutPluginInstance
        ]}
      />}
    </AppLayout>
  )
}
