import React, { useState } from 'react'
import classNames from 'classnames'
import { Button, Modal, ModalProps } from '../../../elements'
import {
  UserInterfaceProps,
  UserRoles,
  UserRolesArray,
} from '../../../../interfaces'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { set, useForm } from 'react-hook-form'
import { useLoading, useWindowSize } from '../../../../hooks'

import './UserCreateModal.scss'
import {
  createUserByAdmin,
  fetchUsers,
  updateUserByAdmin,
} from '../../../../redux'
import { UserEmailChangeConfirmationModal } from '../UserEmailChangeConfirmationModal/UserEmailChangeConfirmationModal'

interface UserCreateModalProps extends ModalProps {
  currentUser?: UserInterfaceProps
  isEdit?: boolean
}

export const UserCreateModal: React.FC<UserCreateModalProps> = ({
  open,
  onClose,
  currentUser,
  isEdit,
}) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const dispatch = useAppDispatch()
  const { setLoading } = useLoading()
  const { isMobile } = useWindowSize()
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm<UserInterfaceProps>({
    mode: 'onChange',
    delayError: 300,
    defaultValues: isEdit
      ? currentUser
      : {
          name: '',
          email: '',
          password: '',
          role: UserRoles.INFLUENCER,
        },
  })
  const { loading, error } = useAppSelector((state) => state.user)

  const handleUserAction = async (data: UserInterfaceProps) => {
    setLoading(true)
    try {
      const response = await dispatch(
        isEdit ? updateUserByAdmin(data) : createUserByAdmin(data),
      )

      if (response?.payload?.isSuccess) {
        await dispatch(fetchUsers({ page: 1, limit: 10 }))

        setLoading(false)
        onClose()
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onFormSubmit = async (data: UserInterfaceProps) => {
    if (isEdit && currentUser.enabled2fa && data.email !== currentUser?.email) {
      //  Show modal
      setOpenConfirmationModal(true)
    } else {
      handleUserAction(data)
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        className={classNames({ 'user-modal-container': !isMobile })}>
        <div className="user-create-modal-container">
          <h3 className="user-create-modal-heading">
            {isEdit ? 'Edit User' : 'Create User'}
          </h3>

          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="user-create-modal-form">
              <div className="user-create-modal-form-row">
                <div className="user-create-modal-form-row-item">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Write user's name"
                    {...register('name', { required: 'Name is required' })}
                    disabled={isEdit}
                  />
                  {errors.name && (
                    <span className="error">{errors.name.message}</span>
                  )}
                </div>
                <div className="user-create-modal-form-row-item">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Write user's email"
                    {...register('email', {
                      required: 'Email is required',
                    })}
                  />
                  {errors.email && (
                    <span className="error">{errors.email.message}</span>
                  )}
                </div>
              </div>
              <div className="user-create-modal-form-row">
                <div className="user-create-modal-form-row-item">
                  <label htmlFor="role">Role</label>
                  <select id="role" {...register('role')}>
                    {UserRolesArray.map((role) => (
                      <option key={role.value} value={role.value}>
                        {role.label}
                      </option>
                    ))}
                  </select>
                </div>
                {!isEdit ? (
                  <div className="user-create-modal-form-row-item">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      id="password"
                      placeholder="Write user's password"
                      {...register('password', {
                        required: 'Password is required',
                      })}
                    />
                    {errors.password && (
                      <span className="error">{errors.password.message}</span>
                    )}
                  </div>
                ) : (
                  <div className="user-create-modal-form-row-item"></div>
                )}
              </div>
            </div>

            <div className="user-create-modal-actions">
              <Button variant="default" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!isValid || loading}>
                {isEdit ? 'Update' : 'Create'}
              </Button>
            </div>
            {error && <p className="error system-error">{error}</p>}
          </form>
        </div>
      </Modal>
      <UserEmailChangeConfirmationModal
        className={classNames({ 'user-modal-container': !isMobile })}
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        onConfirm={() => {
          handleUserAction(getValues())
          setOpenConfirmationModal(false)
        }}
      />
    </>
  )
}
