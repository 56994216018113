import React from 'react';
import { MdOutlineCalendarMonth, MdOutlineLocationOn, MdOutlineRemoveRedEye } from 'react-icons/md'

import './CampaignNav.scss'
import classNames from 'classnames';

export type ViewMode = 'detail' | 'calendar' | 'map';

interface CampaignNavProps {
  currentMode?: ViewMode,
  onSelectViewMode?: (mode: ViewMode) => void
}

export const CampaignNav = ({
  currentMode,
  onSelectViewMode
}: CampaignNavProps) => {
  return (
    <div className="campaign-action-wrapper">
      <button
        className={classNames("campaign-action-btn")}
        onClick={() => onSelectViewMode(currentMode === "detail" ? "map" : "detail")}
        disabled={!onSelectViewMode}
      >
        {currentMode === "detail" ? "Map" : "Detail"}
      </button>
    </div>
  )
}