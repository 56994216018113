import React from 'react'
import classNames from 'classnames'
import { createPortal } from 'react-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { MdOutlineClose } from 'react-icons/md'
import { Button } from '../Button/Button'

import { usePreventScroll } from '../../../hooks/usePreventScroll'
import { useWindowSize } from '../../../hooks/useWindowSize'

import './Modal.scss'

export interface ModalProps {
  open: boolean,
  onClose: () => void,
  className?: string,
  modalContentStyle?: React.CSSProperties,
  isBackdropClose?: boolean
}

export const Modal: React.FC<ModalProps> = ({
  children,
  className,
  open,
  onClose,
  modalContentStyle = {},
  isBackdropClose = false
}) => {
  const { isMobile } = useWindowSize();

  usePreventScroll(!isMobile && open)
  return createPortal(
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0, translateY: 40, scale: 0.95 }}
          animate={{ opacity: 1, translateY: 0, scale: 1 }}
          exit={{ opacity: 0, translateY: 40, scale: 0.95 }}
          className={classNames('modal-container', className)} onClick={isBackdropClose ? onClose : null}>
          <div className="modal-content" style={modalContentStyle}>
            <button className="modal-close" onClick={onClose}>
              <MdOutlineClose size={30} />
            </button>
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body,
  )
}

export interface AlertModalProps extends ModalProps {
  title?: string
  description?: string
  onConfirm?: () => void
  confirmText?: string
  cancelText?: string
  loading?: boolean
}

export const AlertModal: React.FC<AlertModalProps> = ({
  open,
  onClose,
  title,
  description,
  onConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  className,
  loading
}) => {
  const { isMobile } = useWindowSize();

  usePreventScroll(!isMobile && open)
  return createPortal(
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0, translateY: 40, scale: 0.95 }}
          animate={{ opacity: 1, translateY: 0, scale: 1 }}
          exit={{ opacity: 0, translateY: 40, scale: 0.95 }}
          className={classNames('alert-modal-container', className)}>
          <div className="alert-modal-content">
            <h2 className="alert-modal-title">{title}</h2>
            <p className="alert-modal-description">{description}</p>
            <div className="alert-modal-actions">
              <Button variant={'default'} className="alert-modal-cancel" onClick={onClose}>
                {cancelText}
              </Button>
              <Button variant={'primary'} className="alert-modal-confirm" onClick={onConfirm} disabled={loading}>
                {confirmText}
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body,
  )
}