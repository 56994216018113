import React from 'react'
import { RootRoutes } from './routes/RootRoutes'
import { LoadingProvider } from './contexts'

const App: React.FC = () => {
  return (
    <LoadingProvider>
      <RootRoutes />
    </LoadingProvider>
  )
}

export default App
