import React, { useState } from "react";
import { Button } from "../../../elements";
import { RegisterOptions, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../../../redux";

import './ResetPasswordPage.scss';

export const ResetPasswordPage: React.FC = () => {
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: "onBlur"
  });
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const { loading } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const registerOptions = {
    password: (): RegisterOptions => {
      return {
        required: "Password is required",
      }
    },
  }

  const onFormSubmit: SubmitHandler<any> = async data => {
    try {
      const token = params?.token;

      const response = await dispatch(resetPassword({ password: data.password, token }));

      if (response?.payload?.isSuccess) {
        setError('');
        setSuccess('Password reset successfully. Redirecting to login page...');
        setTimeout(() => {
          navigate('/auth/login');
        }, 2000);
      }
    } catch (err) {
      setError(err.message);
    }
  }

  return (
    <div className="reset-password-container">
      <div className="reset-password-wrapper">
        <div className="reset-password-logo" onClick={() => navigate('/auth/login')}>
          <img src="/images/logo.png" alt="Voice of influence" />
        </div>

        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="reset-password-title">
            Reset Password
          </div>
          <div className="reset-password-fields">
            <input
              type="password"
              placeholder="Set new password here"
              name="password"
              {...register("password", registerOptions.password())}
            />
            {errors.password && <p className="error">{errors.password.message}</p>}
          </div>

          <div className="reset-password-button">
            <Button variant="secondary" type="submit" disabled={!isValid || loading}>Submit</Button>
          </div>

          {error && <p className="error system-error">{error}</p>}
          {success && <p className="success system-success">{success}</p>}
        </form>
      </div>
    </div>
  )
}