import React, { useEffect } from "react";
import { createContext } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { getMessageByUser } from "../../redux";

export const MessageContext = createContext({});

interface MessageProviderProps {
  children: React.ReactNode;
}

export const MessageProvider = ({ children }: MessageProviderProps) => {
  const value = {};
  const location = useLocation();
  const dispatch = useAppDispatch();

  const fetchMessages = () => {
    dispatch(getMessageByUser())
  }

  useEffect(() => {
    fetchMessages();

    const intervalDispatch = setInterval(() => {
      fetchMessages();
    }, 60000);

    if (location.pathname !== '/messages') {
      clearInterval(intervalDispatch);
    }
  }, [location]);

  return (
    <MessageContext.Provider value={value}>
      {children}
    </MessageContext.Provider>
  );
}