import { createAppAsyncThunk } from '../../createAppAsyncThunk'
import { getAxiosInstance } from '../../../utils/axiosHelpers'
import { ConfigInterfaceProps } from '../../../interfaces'

export const getConfigs = createAppAsyncThunk(
  'configs/get',
  async (_, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance()
      const response = await instance.get(`/configs`)

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateConfig = createAppAsyncThunk(
  'configs/update',
  async (config: ConfigInterfaceProps, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance()
      await instance.put(`/configs/${config.id}`, config)

      return config
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
