import React from 'react'

import { Tags } from '../../../elements/Tags/Tags'

import './CampaignTags.scss'

interface CampaignTagsProps {
  size?: 'sm' | 'lg'
  type?: 'benefit' | 'tag',
  tags?: Array<string>,
}

export const CampaignTags: React.FC<CampaignTagsProps> = ({
  size = 'sm',
  type = 'tag',
  tags,
}) => (
  <Tags
    size={size}
    variant={type === 'benefit' ? 'success' : 'default'}
    tags={tags || ['Foodie', 'Golf', 'Spa']}
  />
)
