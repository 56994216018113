import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import React from 'react'

import { store } from './redux/store'
import App from './components/App'
import { register as registerServiceWorker } from './serviceWorkerRegistration'

import './assets/styles/Reset.scss'
import './assets/styles/Base.scss'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
)

registerServiceWorker()