import { Routes, Route } from 'react-router-dom'
import React from 'react'

import { HotelsPage } from '../views/hotels/HotelsPage/HotelsPage'
import { HotelDetailPage } from '../views/hotels/HotelDetailPage/HotelDetailPage'

import Enquire from '../views/__old/Creator/Enquire'

export const HotelRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<HotelsPage />} />
      <Route path=":hotelId" element={<HotelDetailPage />} />
      <Route path=":hotelId/enquire" element={<Enquire />} />
    </Routes>
  )
}
