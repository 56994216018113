import { useAppSelector } from "../../hooks";
import { RootState } from "../../store";

export const useUnreadMessages = () =>
  useAppSelector((state: RootState) => state.message.unreadMessages);

export const useInboxMessages = () =>
  useAppSelector((state: RootState) => state.message.inboxMessages);

export const useSentMessages = () =>
  useAppSelector((state: RootState) => state.message.sentMessages);

export const useUnreadInboxMessages = () =>
  useAppSelector((state: RootState) => state.message.inboxMessages.filter(message => !message.isRead));