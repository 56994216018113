import {
  authSlice,
  campaignSlice,
  configSlice,
  feedbackSlice,
  hotelSlice,
  messageSlice,
  offerSlice,
  supportSlice,
  userSlice,
} from './slices'

export const reducer = {
  auth: authSlice.reducer,
  hotel: hotelSlice.reducer,
  user: userSlice.reducer,
  campaign: campaignSlice.reducer,
  offer: offerSlice.reducer,
  message: messageSlice.reducer,
  feedback: feedbackSlice.reducer,
  support: supportSlice.reducer,
  config: configSlice.reducer,
}
