import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { RegisterOptions, useForm } from "react-hook-form";
import { TagsInput } from "react-tag-input-component";
import { Button, ImageUpload, Modal, ModalProps } from "../../../elements";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useCurrentUser, useLoading } from "../../../../hooks";
import { createHotel, fetchHotels, updateHotel } from "../../../../redux";
import { uploadImageToS3 } from "../../../../utils/fileHelpers";
import { HotelInterfaceProps } from "../../../../interfaces";
import { useWindowSize } from "../../../../hooks/useWindowSize";

import './HotelCreateModal.scss';

interface HotelCreateModalProps extends ModalProps {
  currentHotel?: HotelInterfaceProps;
  isEdit?: boolean;
}

export const HotelCreateModal: React.FC<HotelCreateModalProps> = ({
  open,
  onClose,
  currentHotel,
  isEdit = false,
}) => {
  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();
  const { isMobile } = useWindowSize();
  const [currentImages, setCurrentImages] = useState<string[]>(currentHotel?.images || []);
  const [tags, setTags] = useState(currentHotel?.facilities || []);
  const { register, handleSubmit, formState: { errors, isValid } } = useForm<HotelInterfaceProps>({
    mode: 'onChange',
    delayError: 200,
    defaultValues: isEdit ? {
      ...currentHotel,
    } : {
      relatedOwnerId: currentUser?.id,
    }
  });
  const form = useRef(null);

  const { setLoading } = useLoading();
  const { loading, error } = useAppSelector(state => state.hotel);

  const onFormSubmit = async (data: any) => {
    try {
      setLoading(true);
      const images = [];
      for (const imageUrl of currentImages) {
        const imageResponse = imageUrl.includes('blob:') ? await uploadImageToS3(imageUrl) : null;

        images.push(imageResponse ? imageResponse?.data?.location : imageUrl);
      }

      const updatedData = {
        ...data,
        facilities: tags,
        images,
      }

      const response = await dispatch(isEdit ? updateHotel(updatedData) : createHotel(updatedData));

      if (response?.payload?.isSuccess) {
        await dispatch(fetchHotels({ page: 1, limit: 10 }));

        setLoading(false);
        onClose();
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const registerOptions = {
    hrn: (): RegisterOptions => {
      return {
        required: 'HRN is required',
      }
    },
    organisationName: (): RegisterOptions => {
      return {
        required: 'Organisation name is required',
      }
    },
    organisationAddress: (): RegisterOptions => {
      return {
        required: 'Organisation address is required',
      }
    },
    primaryVenueName: (): RegisterOptions => {
      return {
        required: 'Primary venue name is required',
      }
    },
    primaryVenueAddress: (): RegisterOptions => {
      return {
        required: 'Primary venue address is required',
      }
    },
  }

  const onSelectNewImage = (index: number) => (blobUrl: any) => {
    setCurrentImages((currentImages) => {
      const newImages = [...currentImages];
      if (index > -1) {
        newImages[index] = blobUrl;
      } else {
        newImages.push(blobUrl);
      }
      return newImages;
    });
  }

  useEffect(() => {
    const onEnterFormSubmit = (event: any) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        event.stopPropagation();
        form?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    }

    document.addEventListener('keypress', onEnterFormSubmit);

    return () => {
      document.removeEventListener('keypress', onEnterFormSubmit);
    }
  }, []);

  return (
    <Modal open={open} onClose={onClose} className={classNames({ ['hotel-modal-container']: !isMobile })}>
      <div className="hotel-create-modal-container">
        <h3 className="hotel-create-modal-heading">
          {isEdit ? 'Edit Hotel' : 'Create Hotel'}
        </h3>

        <form ref={form} onSubmit={handleSubmit(onFormSubmit)}>
          <div className="hotel-create-modal-fields">
            <div className="hotel-create-modal-form-fields">
              <label htmlFor="hrn">Hotel reference number <span className="required">*</span></label>
              <input id="hrn" type="text" placeholder="HRN00001" {...register('hrn', registerOptions.hrn())} />
              {errors.hrn && <span className="error">{errors.hrn.message}</span>}
            </div>
            <div className="hotel-create-modal-form-container">
              <div className="hotel-create-modal-form-fields">
                <label htmlFor="organisationName">Organisation name <span className="required">*</span></label>
                <input id="organisationName" type="text" placeholder="Feldon Valley" {...register('organisationName', registerOptions.organisationName())} />
                {errors.organisationName && <span className="error">{errors.organisationName.message}</span>}
              </div>
              <div className="hotel-create-modal-form-fields">
                <label htmlFor="organisationAddress">Organisation address <span className="required">*</span></label>
                <input id="organisationAddress" type="text" placeholder="Lower Bariles, Banbury" {...register('organisationAddress', registerOptions.organisationAddress())} />
                {errors.organisationAddress && <span className="error">{errors.organisationAddress.message}</span>}
              </div>
            </div>
            <div className="hotel-create-modal-form-container">
              <div className="hotel-create-modal-form-fields">
                <label htmlFor="primaryVenueName">Primary venue name <span className="required">*</span></label>
                <input id="primaryVenueName" type="text" placeholder="Feldon Valley" {...register('primaryVenueName', registerOptions.primaryVenueName())} />
                {errors.primaryVenueName && <span className="error">{errors.primaryVenueName.message}</span>}
              </div>
              <div className="hotel-create-modal-form-fields">
                <label htmlFor="primaryVenueAddress">Primary venue address <span className="required">*</span></label>
                <input id="primaryVenueAddress" type="text" placeholder="Sutton Lane, Lower Bariles, Banbury, OX15 5BB" {...register('primaryVenueAddress', registerOptions.primaryVenueAddress())} />
                {errors.primaryVenueAddress && <span className="error">{errors.primaryVenueAddress.message}</span>}
              </div>
            </div>
            <div className="hotel-create-modal-form-container">
              <div className="hotel-create-modal-form-fields">
                <label htmlFor="classification">Classification</label>
                <input id="classification" type="text" placeholder="Superior Lodge" {...register('classification')} />
              </div>
              <div className="hotel-create-modal-form-fields">
                <label htmlFor="website">Website</label>
                <input id="website" type="text" placeholder="Hotel's website url" {...register('primaryVenueWebsite')} />
              </div>
            </div>
            <div className="hotel-create-modal-form-container">
              <div className="hotel-create-modal-form-fields">
                <label htmlFor="mainContactName">Main contact name</label>
                <input id="mainContactName" type="text" placeholder="Main contact name" {...register('mainContactName')} />
              </div>
              <div className="hotel-create-modal-form-fields">
                <label htmlFor="mainContactTitle">Main contact title</label>
                <input id="mainContactTitle" type="text" placeholder="Main contact title" {...register('mainContactTitle')} />
              </div>
            </div>
            <div className="hotel-create-modal-form-container">
              <div className="hotel-create-modal-form-fields">
                <label htmlFor="mainContactEmail">Main contact email</label>
                <input id="mainContactEmail" type="text" placeholder="Main contact email" {...register('mainContactEmail')} />
              </div>
              <div className="hotel-create-modal-form-fields">
                <label htmlFor="mainContactPhoneNumber">Main contact phone number</label>
                <input id="mainContactPhoneNumber" type="text" placeholder="Main contact phone number" {...register('mainContactPhoneNumber')} />
              </div>
            </div>
            <div className="hotel-create-modal-form-fields">
              <label htmlFor="description">Description</label>
              <textarea id="description" placeholder="Description" {...register('description')} />
            </div>
            <div className="hotel-create-modal-form-fields">
              <label htmlFor="facilities">Facilities</label>
              <TagsInput value={tags} onChange={setTags} placeHolder="Separate facilities with enter" />
            </div>
            <div className="hotel-create-modal-form-container">
              <div className="hotel-create-modal-form-fields">
                <label htmlFor="facilitiesContactNumbers">Facilities contact number</label>
                <input id="facilitiesContactNumbers" type="text" placeholder="Facilities contact number" {...register('facilitiesContactNumbers')} />
              </div>
              <div className="hotel-create-modal-form-fields">
                <label htmlFor="mainReceptionPhoneNumber">Main reception phone number</label>
                <input id="mainReceptionPhoneNumber" type="text" placeholder="Main reception phone number" {...register('mainReceptionPhoneNumber')} />
              </div>
            </div>

            <div className="hotel-create-modal-form-fields">
              <label htmlFor="images">Images</label>
              <div className="image-form-fields">
                {
                  currentImages.map((image, index) => (
                    <div className="image-form-field" key={index}>
                      <ImageUpload
                        setSelectedFile={onSelectNewImage(index)}
                        currentImage={image}
                        onImageRemove={() => setCurrentImages((currentImages) => currentImages.filter((_, i) => i !== index))}
                        className="hotel-create-modal-image-upload"
                      />
                    </div>
                  ))
                }
                <ImageUpload setSelectedFile={onSelectNewImage(-1)} className="hotel-create-modal-image-upload" />
              </div>
            </div>
          </div>

          <div className="hotel-create-modal-buttons">
            <Button variant="default" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={!isValid || loading}>
              {isEdit ? 'Update' : 'Create'}
            </Button>
          </div>
          {error && <p className="error system-error">{error}</p>}
        </form>
      </div>
    </Modal>
  )
}