import React from "react";
import classNames from "classnames";
import { Slide, SlideProps } from "react-slideshow-image";

import 'react-slideshow-image/dist/styles.css';
import './ImageCarousel.scss';

interface ImageCarouselProps {
  images: Array<string>;
  options?: SlideProps;
  className?: string;
}

export const ImageCarousel: React.FC<ImageCarouselProps> = ({
  images,
  options = {},
  className
}) => {
  return (
    <div className={classNames("slide-container", className)}>
      <Slide {...options}>
        {images.map((slideImage, index) => (
          <img src={slideImage} key={index} alt={`slide ${index}`} />
        ))}
      </Slide>
    </div>
  )
}