import { Routes, Route } from 'react-router-dom'
import React from 'react'

import { CampaignsPage } from '../views/campaign/CampaignsPage/CampaignsPage'
import { CampaignDetailPage } from '../views/campaign/CampaignDetailPage/CampaignDetailPage'
import { MyOffersPage } from '../views/campaign/MyOffersPage/MyOffersPage'
import { MyCampaignsPage } from '../views/campaign/MyCampaignsPage/MyCampaignsPage'

export const CampaignRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<CampaignsPage />} />
      <Route path=":campaignId" element={<CampaignDetailPage />} />
      <Route path="my-offers" element={<MyOffersPage />} />
      <Route path="my-campaigns" element={<MyCampaignsPage />} />
      <Route path="my-campaigns/:campaignId/:offerId" element={<CampaignDetailPage />} />
      <Route path="my-offers/:campaignId" element={<CampaignDetailPage />} />
      <Route path="my-offers/:campaignId/:offerId" element={<CampaignDetailPage />} />
    </Routes>
  )
}
