import React from 'react'
import classNames from 'classnames'
import { UseFormRegisterReturn } from 'react-hook-form'

import './Input.scss'

interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
  label?: string
  formRegister?: UseFormRegisterReturn<any>
}

export const Input: React.FC<InputProps> = ({
  label,
  className,
  formRegister = () => {},
  ...restProps
}) => {
  return (
    <label className={classNames('input-container', className)}>
      {label && <span className="input-label">{label}</span>}
      <input className="input" {...restProps} {...formRegister}/>
    </label>
  )
}
