import React from 'react'
import classNames from 'classnames'

import { Header } from '../Header/Header'

import './AppLayout.scss'

interface AppLayoutProps {
  title?: string
  backButtonVisible?: boolean,
  backButtonClick?: () => void,
  headerChildren?: React.ReactNode
  className?: string
}

export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  title,
  backButtonVisible,
  backButtonClick,
  headerChildren,
  className
}) => (
  <div className={classNames("app-layout-container", className)}>
    <Header backButtonVisible={backButtonVisible} backButtonClick={backButtonClick} children={headerChildren} />
    {title && <div className="app-layout-title">{title}</div>}

    <div className={classNames('app-layout-content', { 'with-title': title })}>{children}</div>
  </div>
)
