import { createSlice } from '@reduxjs/toolkit'
import { getConfigs, updateConfig } from './configAction'
import { ConfigInterfaceProps } from '../../../interfaces'

const initialState: ConfigState = {
  configs: null,
  configsLoading: false,
  updateConfigLoading: false
}

export const configSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConfigs.pending, (state) => {
        state.configsLoading = true
      })
      .addCase(getConfigs.fulfilled, (state, { payload }) => {
        state.configsLoading = false
        state.configs = payload
      })
      .addCase(getConfigs.rejected, (state, action) => {
        state.configsLoading = false
      })

    builder
      .addCase(updateConfig.pending, (state) => {
        state.updateConfigLoading = true
      })
      .addCase(updateConfig.fulfilled, (state, action) => {
        state.updateConfigLoading = false
        state.configs = state.configs.map((config) =>
          config.id === action.payload.id ? { ...config, ...action.payload } : config
        )
      })
      .addCase(updateConfig.rejected, (state, action) => {
        state.updateConfigLoading = false
      })
  }
})

export interface ConfigState {
  configs: ConfigInterfaceProps[] | null
  configsLoading: boolean
  updateConfigLoading: boolean
}

export const {} = configSlice.actions
