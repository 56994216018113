export const adminNavItems = [
  { title: 'Hotel', path: '/admin/hotels' },
  { title: 'Campaigns', path: '/admin/campaigns' },
];

export const adminNavItemsAdvanced = [
  { title: 'Users', path: '/admin/users', isAdmin: true },
  { title: 'Configs', path: '/admin/configs', isAdmin: true },
];
export const campaignChannelOptions = [
  { value: 'Tiktok', label: 'Tiktok' },
  { value: 'Instagram', label: 'Instagram' },
];

export const LANDSCAPE_ASPECT_RATIO = 16 / 9;
export const PORTRAIT_ASPECT_RATIO = 9 / 16;
export const SQUARE_ASPECT_RATIO = 1 / 1;
export const FREE_ASPECT_RATIO = undefined;

export const END_OF_TODAY = new Date().setHours(23, 59, 59, 999);
export const START_OF_TODAY = new Date().setHours(0, 0, 0, 0);