import React, { useEffect, useMemo, useState } from 'react'
import { useLongPress } from 'use-long-press';
import { ColumnDef } from '@tanstack/react-table';
import { AiOutlinePlus } from 'react-icons/ai';
import { MdDelete, MdModeEditOutline } from 'react-icons/md';

import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { deleteHotel, fetchHotels } from '../../../../redux';
import { HotelCard, HotelCreateModal } from '../../../common';
import { HotelInterfaceProps } from '../../../../interfaces';
import { AlertModal, Button, Table, Tags } from '../../../elements';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { formatDate, isRecentlyAdded } from '../../../../utils/timeHelpers';
import { useBodyClassName } from '../../../../hooks/useBodyClassName';

import './ManageHotelPage.scss';

export const ManageHotelPage: React.FC = () => {
  useBodyClassName("bg-white");

  const dispatch = useAppDispatch();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState<HotelInterfaceProps | null>(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { isMobile } = useWindowSize();
  const bind = useLongPress(() => {
    setDeleteModalVisible(true);
  }, {
    onStart: (event, meta) => {
      setSelectedHotel(meta.context as HotelInterfaceProps);
    }
  });

  const { hotels } = useAppSelector(state => state.hotel);

  useEffect(() => {
    dispatch(fetchHotels({ page: 1, limit: 10 }));
  }, []);

  const onEditHotel = (hotel: HotelInterfaceProps) => {
    setSelectedHotel(hotel);
    setIsEdit(true);
    setCreateModalVisible(true);
  }

  const onDeleteHotel = (hotel: HotelInterfaceProps) => {
    setSelectedHotel(hotel);
    setDeleteModalVisible(true);
  }

  const closeModal = () => {
    setSelectedHotel(null);
    setIsEdit(false);
    setCreateModalVisible(false);
  }

  const onConfirmDelete = async () => {
    try {
      setLoading(true);
      const response = await dispatch(deleteHotel(selectedHotel?.id));

      if (response?.payload?.isSuccess) {
        setDeleteModalVisible(false);
        setSelectedHotel(null);

        await dispatch(fetchHotels({ page: 1, limit: 10 }));
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSelectedHotel(null);
    }
  }

  const columns = useMemo<ColumnDef<HotelInterfaceProps>[]>(() => [
    {
      header: 'HRN',
      accessorKey: 'hrn',
      meta: {
        columnClassName: 'sticky left'
      }
    },
    {
      header: 'Organisation Name',
      accessorKey: 'organisationName',
    },
    {
      header: 'Address',
      accessorKey: 'primaryVenueAddress',
    },
    {
      header: 'Description',
      accessorKey: 'description',
    },
    {
      header: 'Facilities',
      accessorKey: 'facilities',
      cell: ({ row, getValue }) => (
        <Tags className='hotel-table-tags' tags={getValue<Array<string>>() || []} size={'sm'} />
      )
    },
    {
      header: 'Images',
      accessorKey: 'images.length',
    },
    {
      header: 'Created At',
      accessorKey: 'createdAt',
      cell: ({ row, getValue }) => (
        <span>{formatDate(getValue<string>())}</span>
      )
    },
    {
      header: 'Actions',
      cell: ({ row }) => (
        <div className='hotel-table-actions'>
          <Button variant={'primary'} onClick={() => onEditHotel(row.original)}>
            <MdModeEditOutline />
          </Button>
          <Button variant={'danger'} disabled={!!row?.original?.liveCampaignIds?.length} onClick={() => onDeleteHotel(row.original)}>
            <MdDelete />
          </Button>
        </div>
      ),
      meta: {
        columnClassName: 'sticky right'
      }
    }
  ], []);

  return (
    <div className="manage-hotel-page-container">
      <div className="manage-hotel-page-header">
        <h1>Manage Hotel Page</h1>
        <Button variant={'primary'} onClick={() => setCreateModalVisible(true)}>
          {isMobile ? <AiOutlinePlus /> : 'Create'}
        </Button>
      </div>

      {hotels && hotels.length ? (
        <div className="manage-hotel-page-list">
          {isMobile ? (
            hotels.map((hotel: HotelInterfaceProps) => (
              <div className="manage-hotel-page-card" {...bind(hotel)} key={hotel.id}>
                <HotelCard
                  onClick={() => onEditHotel(hotel)}
                  numberOfImages={hotel.images.length}
                  image={hotel?.images[0]}
                  name={hotel.organisationName}
                  classification={hotel.classification}
                  description={hotel.description}
                  tags={hotel?.facilities.slice(0, 3) || []}
                  address={hotel.primaryVenueAddress}
                  isNew={isRecentlyAdded(hotel.createdAt)}
                />
              </div>
            ))
          ) : (
            <Table columns={columns} data={hotels} />
          )}
        </div>
      ) : (
        <div className="manage-hotel-page-empty">
          <h2>No hotels found</h2>
        </div>
      )}

      {createModalVisible ? <HotelCreateModal
        open={createModalVisible}
        onClose={() => closeModal()}
        currentHotel={selectedHotel}
        isEdit={isEdit}
      /> : null}

      <AlertModal
        open={deleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        title={'Delete Hotel'}
        description={'Are you sure you want to delete this hotel?'}
        onConfirm={() => onConfirmDelete()}
        loading={loading}
      />
    </div>
  )
}