import React from 'react'
import classNames from 'classnames';

import './FileUpload.scss'

interface FileUploadProps {
  className?: string;
  onFileSelect?: (file: File) => void;
}

export const FileUpload = ({
  className,
  onFileSelect
}: FileUploadProps) => {

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      return null;
    }

    onFileSelect(e.target.files[0]);
  }

  return (
    <div className={classNames('file-area', className)}>
      <input type='file' onChange={onSelectFile} />
      <div className="file-dummy">
        Attach file
      </div>
    </div>
  )
}