import React, { useMemo, useState, useCallback } from "react"
import { useLoadScript, GoogleMap } from "@react-google-maps/api"

import { HotelInterfaceProps } from "../../../interfaces"

import './MapPanel.scss'

interface MapPanelProps {
  hotel: HotelInterfaceProps,
  height?: string,
}

const DEFAULT_CENTER = { lat: 52.190802, lng: -1.704651 }

export const MapPanel = ({
  hotel,
  height = '100%',
}: MapPanelProps) => {
  const [map, setMap] = useState(null);
  const libraries = useMemo(() => ['places', 'geocoding'], []);
  const mapOptions = useMemo<google.maps.MapOptions>(() => ({
    disableDefaultUI: true,
    clickableIcons: true,
    scrollwheel: true,
  }), []);

  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
    libraries: libraries as any,
  });

  const onLoad = useCallback((map) => {
    const geocoder = new google.maps.Geocoder();
    const marker = new google.maps.Marker({
      label: {
        text: hotel.organisationName,
        color: 'red',
        className: 'google-map-marker-label'
      },
      icon: {
        url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi3_hdpi.png',
        labelOrigin: new google.maps.Point(75, 20),
        size: new google.maps.Size(28, 40),
        scaledSize: new google.maps.Size(28, 40),
      }
    });

    try {
      geocoder.geocode({ address: hotel?.organisationAddress || hotel?.primaryVenueAddress }, (results, status) => {
        if (status === 'OK') {
          if (results && results.length > 0) {
            map.setCenter(results[0]?.geometry?.location || DEFAULT_CENTER)
            marker.setPosition(results[0]?.geometry?.location || DEFAULT_CENTER)
            marker.setMap(map)
          }
        } else {
          map.setCenter(DEFAULT_CENTER)
        }
      });
    } catch (error) {
      map.setCenter(DEFAULT_CENTER)
    }

    setMap(map)
  }, []);

  const onUnmount = useCallback((map) => {
    setMap(null)
  }, []);

  return (
    <div className="google-map-container">
      {isLoaded && <GoogleMap
        options={mapOptions}
        zoom={15}
        mapTypeId={google.maps.MapTypeId.ROADMAP}
        mapContainerStyle={{ width: '100%', height }}
        onLoad={onLoad}
        onUnmount={onUnmount}
      />}
    </div>
  )
}