import { createSlice } from "@reduxjs/toolkit";
import { OfferInterfaceProps } from "../../../interfaces";
import { acceptOffer, cancelOffer, enquiryOffer, enquiryOfferFromHotel, fetchMyCampaigns, fetchMyOffers, getOfferById, getOfferByCampaignId, rejectOffer, sendOfferByHotel, withdrawEnquiry } from "./offerAction";

const initialState: OfferState = {
  loading: false,
  error: null,
  offers: null,
  myOffers: null,
  myCampaigns: [],
  offer: null,
  total: 0,
  page: 1,
  limit: 10,
}

export const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyCampaigns.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchMyCampaigns.fulfilled, (state, { payload }) => {
        state.loading = false
        state.myCampaigns = payload.data
      })
      .addCase(fetchMyCampaigns.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(enquiryOffer.pending, (state) => {
        state.loading = true
        state.error = null
        state.offer = null
      })
      .addCase(enquiryOffer.fulfilled, (state, { payload }) => {
        state.loading = false
        state.offer = payload.data
      })
      .addCase(enquiryOffer.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(withdrawEnquiry.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(withdrawEnquiry.fulfilled, (state, { payload }) => {
        state.loading = false
        state.offer = payload.data
      })
      .addCase(withdrawEnquiry.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(getOfferById.pending, (state) => {
        state.loading = true
        state.error = null
        state.offer = null
      })
      .addCase(getOfferById.fulfilled, (state, { payload }) => {
        state.loading = false
        state.offer = payload.data
      })
      .addCase(getOfferById.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(getOfferByCampaignId.pending, (state) => {
        state.loading = true
        state.error = null
        state.offers = null
      })
      .addCase(getOfferByCampaignId.fulfilled, (state, { payload }) => {
        state.loading = false
        state.offers = payload.data
      })
      .addCase(getOfferByCampaignId.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(sendOfferByHotel.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(sendOfferByHotel.fulfilled, (state, { payload }) => {
        state.loading = false
        state.offers = [...state.offers, payload.data]
      })
      .addCase(sendOfferByHotel.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(acceptOffer.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(acceptOffer.fulfilled, (state, { payload }) => {
        state.loading = false
        state.offers = state.offers?.map((offer) => {
          if (offer.id === payload.data.id) {
            return { ...offer, ...payload.data }
          }
          return offer
        })
      })
      .addCase(acceptOffer.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(rejectOffer.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(rejectOffer.fulfilled, (state, { payload }) => {
        state.loading = false
        state.offers = state.offers?.map((offer) => {
          if (offer.id === payload.data.id) {
            return { ...offer, ...payload.data }
          }
          return offer
        })
      })
      .addCase(rejectOffer.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(cancelOffer.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(cancelOffer.fulfilled, (state, { payload }) => {
        state.loading = false
        state.offers = state.offers?.map((offer) => {
          if (offer.id === payload.data.id) {
            return { ...offer, ...payload.data }
          }
          return offer
        })
      })
      .addCase(cancelOffer.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(fetchMyOffers.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchMyOffers.fulfilled, (state, { payload }) => {
        state.loading = false
        state.myOffers = payload.data
      })
      .addCase(fetchMyOffers.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(enquiryOfferFromHotel.pending, (state) => {
        state.loading = true
        state.error = null
        state.offer = null
      })
      .addCase(enquiryOfferFromHotel.fulfilled, (state, { payload }) => {
        state.loading = false
        state.offer = payload.data
      })
      .addCase(enquiryOfferFromHotel.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
});

export interface OfferState {
  loading: boolean;
  error: string | null;
  myOffers: OfferInterfaceProps[];
  myCampaigns: OfferInterfaceProps[];
  offers: OfferInterfaceProps[] | null;
  offer: OfferInterfaceProps;
  total: number;
  page: number;
  limit: number;
}