import { createSlice } from '@reduxjs/toolkit'
import { sendSupportQuery } from './supportAction'

const initialState: SupportState = {
  sendSupportQueryLoading: false,
  sendSupportQueryError: null,
  sendSupportQuerySuccess: false,
}

export const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendSupportQuery.pending, (state) => {
        state.sendSupportQueryLoading = true
        state.sendSupportQueryError = null
        state.sendSupportQuerySuccess = false
      })
      .addCase(sendSupportQuery.fulfilled, (state, { payload }) => {
        state.sendSupportQueryLoading = false
        state.sendSupportQueryError = null
        state.sendSupportQuerySuccess = true
      })
      .addCase(sendSupportQuery.rejected, (state, action) => {
        state.sendSupportQueryLoading = false
        state.sendSupportQueryError = action.payload
        state.sendSupportQuerySuccess = false
      })
  },
})

export interface SupportState {
  sendSupportQueryLoading: boolean
  sendSupportQueryError: string | null
  sendSupportQuerySuccess: boolean
}

export const {} = supportSlice.actions
