import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { UserInterfaceProps } from '../../../../interfaces'
import { Tags } from '../../../elements'

import './UserCard.scss'

interface UserCardProps {
  user: UserInterfaceProps
  className?: string
  action: ReactNode
}

export const UserCard: React.FC<UserCardProps> = ({
  user,
  className,
  action,
}) => {
  return (
    <div className={classNames('user-card-container', className)}>
      <div className="user-card-image">
        <img
          src={user?.avatar || '/images/default_profile_image.jpg'}
          alt="Avatar"
        />
      </div>
      <div className="user-card-info">
        <div className="user-card-info-name">{user?.name}</div>
        <div className="user-card-info-city">{user?.city}</div>
        <div className="user-card-info-bio">{user?.bio}</div>
        <div className="user-card-info-interests">
          <Tags variant="default" tags={user?.tags || []} size="sm" />
        </div>
        <div className="user-card-info-social">
          <div className="user-card-info-social-wrapper">
            {user?.instagram && (
              <div className="user-card-info-social-instagram">
                <a href={user?.instagram} target="_blank" rel="noreferrer">
                  <img src="/images/instagram-icon.png" alt="Instagram" />
                </a>
              </div>
            )}
            {user?.youtube && (
              <div className="user-card-info-social-youtube">
                <a href={user?.youtube} target="_blank" rel="noreferrer">
                  <img src="/images/youtube-icon.png" alt="Youtube" />
                </a>
              </div>
            )}
            {user?.tiktok && (
              <div className="user-card-info-social-tiktok">
                <a href={user?.tiktok} target="_blank" rel="noreferrer">
                  <img src="/images/tiktok-icon.png" alt="Tiktok" />
                </a>
              </div>
            )}
          </div>
        </div>
        {action}
      </div>
    </div>
  )
}
