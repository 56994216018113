import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form'
import { useCookies } from 'react-cookie'

import { loginUser, verifyOtpLogin } from '../../../../redux'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'

import { Button } from '../../../elements'

import './LoginPage.scss'

const ONE_DAY_TIMESTAMP = 86400000;

enum LoginSteps {
  LOGIN = 0,
  OTP = 1
}

export const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loginStep, setLoginStep] = useState<LoginSteps>(LoginSteps.LOGIN);
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: "onBlur"
  });
  const otpForm = useForm<{
    token: string;
  }>({
    mode: "onBlur"
  });
  const { loading, error, userInfo } = useAppSelector(state => state.auth);
  const [cookie, setCookie] = useCookies(['accessToken']);

  const registerOptions = {
    name: (): RegisterOptions => { return { required: "Name is required" } },
    email: (): RegisterOptions => {
      return {
        required: "Email is required",
        pattern: {
          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
          message: "Invalid email address",
        }
      }
    },
    password: (): RegisterOptions => {
      return {
        required: "Password is required",
      }
    },
  }

  const onFormSubmit: SubmitHandler<any> = async data => {
    try {
      const response = await dispatch(loginUser(data))

      if (response?.payload?.isSuccess && response?.payload?.isOtpRequired) {
        setLoginStep(LoginSteps.OTP);
      } else if (response?.payload?.accessToken && response?.payload?.isSuccess) {
        localStorage.setItem('userInfo', JSON.stringify(response.payload.user));
        setCookie('accessToken', response.payload.accessToken, { path: '/', expires: new Date(Date.now() + ONE_DAY_TIMESTAMP) });
      }
    } catch (err) {
      return err;
    }
  }

  const onOtpSubmit: SubmitHandler<any> = async data => {
    try {
      const response = await dispatch(verifyOtpLogin({ ...data, id: userInfo?.id }))

      if (response?.payload?.accessToken && response?.payload?.isSuccess) {
        localStorage.setItem('userInfo', JSON.stringify(response.payload.user));
        setCookie('accessToken', response.payload.accessToken, { path: '/', expires: new Date(Date.now() + ONE_DAY_TIMESTAMP) });
      }
    } catch (err) {
      return err;
    }
  }

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="login-logo">
          <img src="/images/logo.png" alt="Voice of influence" />
        </div>

        {loginStep === LoginSteps.LOGIN && (
          <>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="login-fields">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  {...register("email", registerOptions.email())}
                />
                {errors.email && <p className="error">{errors.email.message}</p>}
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  {...register("password", registerOptions.password())}
                />
                {errors.password && <p className="error">{errors.password.message}</p>}
              </div>

              <div className="login-button">
                <Button variant="secondary" type="submit" disabled={!isValid || loading}>Submit</Button>
              </div>

              {error && <p className="error system-error">{error}</p>}
            </form>

        <div className="forgot-password">
          <Link to="/auth/forgot-password" className="forgot-password-link">
            Forgot Password?
          </Link>
        </div>

        <div className="no-account">
          <p>Don't have an account?</p>
          <Link to="/auth/signup" className="signup-link">
            Sign Up for free
          </Link>
        </div>
          </>
        )}
        {loginStep === LoginSteps.OTP && (
          <>
            <div className="otp-message">
              <p>Two-Step Verification</p>
            </div>
            <form onSubmit={otpForm.handleSubmit(onOtpSubmit)}>
              <div className="login-fields">
                <input
                  type="text"
                  placeholder="Enter OTP"
                  name="token"
                  {...otpForm.register("token", { required: "OTP is required" })}
                />
                {otpForm.formState.errors.token && <p className="error">{otpForm.formState.errors.token.message}</p>}
              </div>
              
              <div className="login-button">
                <Button variant="secondary" type="submit" disabled={!otpForm.formState.isValid || loading}>Submit</Button>
              </div>
              
              {error && <p className="error system-error">{error}</p>}
            </form>
          </>
        )}
      </div>
    </div>
  )
}
