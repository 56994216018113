import React from "react";
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";

import './Table.scss';

interface TableProps {
  columns: Array<ColumnDef<any>>,
  data: Array<any>,
}

export const Table: React.FC<TableProps> = ({
  columns,
  data
}: TableProps) => {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className="table">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                {...{
                  key: header.id,
                  colSpan: header.colSpan,
                  style: {
                    width: 'auto',
                    maxWidth: header.getSize(),
                  },
                }}
                className={(header.column.columnDef.meta as any)?.columnClassName || ''}
              >
                {header.isPlaceholder ? null : (
                  flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <td
                    {...{
                      key: cell.id,
                      style: {
                        width: 'auto',
                        maxWidth: cell.column.getSize(),
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: cell.column.id === 'id' ? 'center' : 'left',
                      },
                    }}
                    className={(cell.column.columnDef.meta as any)?.columnClassName || ''}
                  >
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  )
}