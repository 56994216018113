import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdLocationPin } from "react-icons/md";
import { SlideProps } from "react-slideshow-image";

import { AppLayout } from "../../../layout";
import { CampaignCard, CampaignNav, MapPanel, ViewMode } from "../../../common";
import { ImageCarousel, Tags } from "../../../elements";
import { getCampaignByHotelId, getHotelByHrn, resetCurrentHotel } from "../../../../redux";
import { isRecentlyAdded } from "../../../../utils/timeHelpers";
import { sortDescByCreatedAt } from "../../../../utils/dataHelpers";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useLoading } from "../../../../hooks";
import { useBodyClassName } from "../../../../hooks/useBodyClassName";

import "./HotelDetailPage.scss";

export const HotelDetailPage: React.FC = () => {
  useBodyClassName("bg-white");

  const { setLoading } = useLoading();
  const { hotelId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { hotel, loading } = useAppSelector(state => state.hotel);
  const { availableCampaigns } = useAppSelector(state => state.campaign);
  const [viewMode, setViewMode] = useState<ViewMode>("detail");

  useEffect(() => {
    setLoading(true);
    dispatch(getHotelByHrn(hotelId));
  }, []);

  useEffect(() => {
    if (hotel && !loading) {
      setLoading(false);
      dispatch(getCampaignByHotelId(hotel.id));
    }
  }, [hotel, loading]);

  const onBackButtonClick = () => {
    dispatch(resetCurrentHotel());

    navigate(-1);
  }

  const slideOptions: SlideProps = {
    duration: 3000,
    arrows: false,
    children: null
  }

  return hotel ? (
    <AppLayout
      backButtonVisible
      backButtonClick={onBackButtonClick}
      headerChildren={
        <CampaignNav
          currentMode={viewMode}
          onSelectViewMode={setViewMode}
        />
      }
    >
      <div className="hotel-detail-header">
        <h2>{hotel.organisationName}</h2>
        <span>{hotel.organisationAddress} <MdLocationPin size={16} style={{ position: 'relative', top: 3 }} /></span>
      </div>
      {viewMode === "detail" && <div className="hotel-detail-container">
        <div className="hotel-detail-image">
          <ImageCarousel images={hotel.images} options={slideOptions} />
        </div>
        <div className="hotel-detail-tags">
          <div className="tag-label">Facilities</div>
          <div className="tag-list">
            <Tags
              variant="primary"
              tags={hotel.facilities}
            />
          </div>
        </div>
        <div className="hotel-detail-overview">
          <div className="hotel-detail-title">Overview</div>
          <div className="hotel-detail-description">{hotel.description}</div>
        </div>
        {availableCampaigns && availableCampaigns.length ? (
          <div className="hotel-detail-campaigns">
            <div className="hotel-detail-title">Campaigns</div>
            <div className="hotel-detail-campaigns-list">
              {sortDescByCreatedAt(availableCampaigns).map((campaign) => (
                <CampaignCard
                  key={campaign.id}
                  campaign={campaign}
                  address={hotel.organisationAddress}
                  onClick={() => navigate(`/campaigns/${campaign.crn}`)}
                  isRecentlyAdded={isRecentlyAdded(campaign.createdAt)}
                  benefits={hotel.facilities}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>}
      {viewMode === "map" && (
        <MapPanel
          hotel={hotel}
        />
      )}
    </AppLayout>
  ) : null;
}