import React, { useEffect, useState } from 'react'

import { AdminHeader } from '../Header/Header'
import { Navbar } from '../../elements'
import { adminNavItems, adminNavItemsAdvanced } from '../../../utils/types'
import { useCurrentUser } from '../../../hooks'
import { UserRoles } from '../../../interfaces'

import './AdminLayout.scss'

export const AdminLayout: React.FC = ({ children }) => {
  const currentUser = useCurrentUser();
  const [menu, setMenu] = useState(adminNavItems);

  useEffect(() => {
    if (currentUser) {
      setMenu(currentUser.role === UserRoles.ADMIN ? [...adminNavItems, ...adminNavItemsAdvanced] : adminNavItems);
    }
  }, [currentUser]);

  return (
    <div className="admin-layout-container">
      <AdminHeader menuItems={menu} />

      <div className="admin-layout">
        <div className="admin-layout-sidenav">
          <Navbar items={menu} />
        </div>

        <div className="admin-layout-content">{children}</div>
      </div>
    </div>
  )
}
