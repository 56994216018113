import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

import { AppLayout } from '../../../layout';
import { HotelCard } from '../../../common';
import { HotelInterfaceProps } from '../../../../interfaces';

import { fetchHotels } from '../../../../redux';
import { isRecentlyAdded } from '../../../../utils/timeHelpers';
import { sortDescByCreatedAt } from '../../../../utils/dataHelpers';
import { useLoading } from '../../../../hooks';

import './HotelsPage.scss';

export const HotelsPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setLoading } = useLoading();

  const { hotels, loading } = useAppSelector(state => state.hotel);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchHotels({ page: 1, limit: 10 }));
  }, []);

  useEffect(() => {
    if (hotels && !loading) {
      setLoading(false);
    }
  }, [hotels, loading]);

  return (
    <AppLayout backButtonVisible title="Hotels">
      <div className="hotels-container">
        {sortDescByCreatedAt((hotels || [])).map((hotel: HotelInterfaceProps) => (
          <HotelCard
            key={hotel.id}
            onClick={() => navigate(`/hotels/${hotel.hrn}`)}
            numberOfImages={hotel.images.length}
            image={hotel?.images[0]}
            name={hotel.organisationName}
            classification={hotel.classification}
            description={hotel.description}
            tags={hotel?.facilities || []}
            address={hotel.primaryVenueAddress}
            isNew={isRecentlyAdded(hotel.createdAt)}
          />
        ))}
      </div>
    </AppLayout>
  )
}
