import { MessageInterfaceProps } from "../../../interfaces";
import { getAxiosInstance } from "../../../utils/axiosHelpers";
import { createAppAsyncThunk } from "../../createAppAsyncThunk";

export const getInboxMessages = createAppAsyncThunk(
  'message/getInboxMessages',
  async (_, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/messages/recipient`);

      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getSentMessages = createAppAsyncThunk(
  'message/getSentMessages',
  async (_, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/messages/sender`);

      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getUnreadMessages = createAppAsyncThunk(
  'message/getUnreadMessages',
  async (_, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/messages/unread`);

      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const sendMessage = createAppAsyncThunk(
  'message/sendMessage',
  async (data: MessageInterfaceProps, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.post(`/message`, data);

      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const markAsRead = createAppAsyncThunk(
  'message/markAsRead',
  async (data: MessageInterfaceProps, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/message`, data);

      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteMessage = createAppAsyncThunk(
  'message/deleteMessage',
  async (data: MessageInterfaceProps, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.delete(`/message/${data.id}`);

      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getMessageByUser = createAppAsyncThunk(
  'message/getMessageByUser',
  async (_, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/messages/user`);

      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)