const isPossiblyDangerous = (name: string, value: any) => {
  let val = value.replace(/\s+/g, '').toLowerCase();
  if (['src', 'href', 'xlink:href'].includes(name)) {
    if (val.includes('javascript:') || val.includes('data:text/html')) return true;
  }
  if (name.startsWith('on')) return true;

  return false;
}

const removeScripts = (html: HTMLElement) => {
  const scripts = html.querySelectorAll('script');
  for (let script of scripts) {
    script.remove();
  }

  return html;
}

const removeAttributes = (elem) => {
  // Loop through each attribute
  // If it's dangerous, remove it
  let atts = elem.attributes;
  for (let { name, value } of atts) {
    if (!isPossiblyDangerous(name, value)) continue;
    elem.removeAttribute(name);
  }
}
const clean = (html: HTMLElement) => {
  const nodes = html.children;
  for (let node of nodes) {
    removeAttributes(node);
    clean(node as HTMLElement);
  }

  return html;
}

export const sanitizeHTML = (html: string) => {
  const parser = new DOMParser();
  let document = parser.parseFromString(html, 'text/html');

  document.body = removeScripts(document.body);
  document.body = clean(document.body);

  return document.body.innerHTML;
}