import axios from "axios"
import { Cookies } from "react-cookie";

export const getAxiosInstance = () => {
  const cookie = new Cookies();
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${cookie.get('accessToken')}`
    }
  })

  return instance;
}