import axios from 'axios'
import { UserInterfaceProps } from '../../../interfaces';
import { createAppAsyncThunk } from '../../createAppAsyncThunk';
import { getAxiosInstance } from '../../../utils/axiosHelpers';

export const registerUser = createAppAsyncThunk(
  'auth/register',
  async ({ email, password, name, ...rest }: UserInterfaceProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/signup`,
        { name, email, password, ...rest },
        { headers: { 'Content-Type': 'application/json' } }
      )

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const loginUser = createAppAsyncThunk(
  'auth/login',
  async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/signin`,
        { email, password },
        { headers: { 'Content-Type': 'application/json' } }
      )

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getUserProfile = createAppAsyncThunk(
  'auth/profile',
  async (id: number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/users/${id}`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateUserPassword = createAppAsyncThunk(
  'auth/update-password',
  async ({ password, newPassword, id }: { password: string; newPassword: string, id: number }, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/users/${id}/password`, { password, newPassword });

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateUserProfile = createAppAsyncThunk(
  'auth/update-user',
  async (data: any, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/users/${data.id}`, data);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const forgotPassword = createAppAsyncThunk(
  'auth/forgot-password',
  async (email: string, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.patch(`/auth/forgot-password`, { email });

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const resetPassword = createAppAsyncThunk(
  'auth/reset-password',
  async ({ password, token }: { password: string; token: string }, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.patch(`/auth/reset-password/${token}`, { newPassword: password });

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const enableTwoFactorAuth = createAppAsyncThunk(
  'auth/enable-two-factor-auth',
  async (data: any, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.post(`/users/mfa`, data);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const verifyTwoFactorAuth = createAppAsyncThunk(
  'auth/verify-two-factor-auth',
  async (data: any, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/users/mfa/verify`, data);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const removeTwoFactorAuth = createAppAsyncThunk(
  'auth/remove-two-factor-auth',
  async (data: any, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.delete(`/users/mfa`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const verifyOtpLogin = createAppAsyncThunk(
  'auth/verify-otp-login',
  async (data: any, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.post(`/auth/verify`, data);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
