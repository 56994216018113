import React, { useEffect } from 'react'
import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom'

import { HomePage } from '../views/HomePage/HomePage'
import { SettingsPage } from '../views/SettingsPage/SettingsPage'
import { AboutPage } from '../views/AboutPage/AboutPage'
import { MessagesPage } from '../views/profile/MessagesPage/MessagesPage'
import { HelpPage } from '../views/HelpPage/HelpPage'

// creator views
import { ManageHotelPage } from '../views/admin/ManageHotelPage/ManageHotelPage'
import { ManageUserPage } from '../views/admin/ManageUserPage/ManageUserPage'
import { ManageCampaignPage } from '../views/admin/ManageCampaignPage/ManageCampaignPage'
import { ContactPage } from '../views/ContactPage/ContactPage'

import { AuthRoutes } from './AuthRoutes'
import { HotelRoutes } from './HotelRoutes'
import { CampaignRoutes } from './CampaignRoutes'
import { ProfileRoutes } from './ProfileRoutes'

import { AdminLayout } from '../layout/AdminLayout/AdminLayout'
import { useAuth, useCurrentUser } from '../../hooks/useAuth'
import { useAppDispatch } from '../../redux/hooks'
import { UserRoles } from '../../interfaces'
import {
  fetchCampaigns,
  fetchHotels,
  fetchMyCampaigns,
  fetchMyOffers,
  getCampaignByHotelOwnerId,
  getConfigs,
  getUserProfile,
} from '../../redux'
import { MessageProvider } from '../contexts'
import { SupportPage } from '../views/SupportPage/SupportPage'
import { FeedbackPage } from '../views/FeedbackPage/FeedbackPage'
import { FAQPage } from '../views/FAQPage/FAQPage'
import { ManageConfigsPage } from '../views/admin/ManageConfigsPage/ManageConfigsPage'

export const RootRoutes: React.FC = () => {
  const auth = useAuth()
  const currentUser = useCurrentUser()
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    if (auth && currentUser?.id) {
      dispatch(fetchMyCampaigns(currentUser?.id))
      currentUser?.role === UserRoles.HOTEL_MANAGER
        ? dispatch(getCampaignByHotelOwnerId(currentUser.id))
        : dispatch(fetchCampaigns())
      dispatch(fetchHotels({ page: 1, limit: 10 }))
      dispatch(fetchMyOffers(currentUser?.id))
    }
  }, [auth, currentUser])

  useEffect(()=>{
    dispatch(getConfigs())
  },[])

  useEffect(() => {
    if (auth && currentUser?.id) {
      dispatch(getUserProfile(currentUser?.id))
    }
  }, [location])

  return auth ? (
    <MessageProvider>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="auth/*" element={<Navigate to="/" />} />
        <Route path="profile/*" element={<ProfileRoutes />} />
        <Route path="messages" element={<MessagesPage />} />
        <Route path="settings" element={<SettingsPage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="help" element={<HelpPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="support" element={<SupportPage />} />
        <Route path="feedback" element={<FeedbackPage />} />
        <Route path="faq" element={<FAQPage />} />

        {[UserRoles.ADMIN, UserRoles.INFLUENCER].includes(currentUser?.role) ? (
          <>
            <Route path="hotels/*" element={<HotelRoutes />} />
            <Route path="campaigns/*" element={<CampaignRoutes />} />
          </>
        ) : null}

        {[UserRoles.ADMIN, UserRoles.HOTEL_MANAGER].includes(
          currentUser?.role,
        ) ? (
          <Route
            path="admin"
            element={
              <AdminLayout>
                <Outlet />
              </AdminLayout>
            }>
            <Route path="hotels" element={<ManageHotelPage />} />
            <Route path="campaigns" element={<ManageCampaignPage />} />
            {currentUser?.role === UserRoles.ADMIN ? (
              <>
              <Route path="users" element={<ManageUserPage />} />
              <Route path="configs" element={<ManageConfigsPage />} />
              </>
            ) : null}
          </Route>
        ) : null}

        <Route path="*" element={<div>404 Not Found!</div>} />
      </Routes>
    </MessageProvider>
  ) : (
    <Routes>
      <Route path="auth/*" element={<AuthRoutes />} />
      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  )
}
