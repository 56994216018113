import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppLayout } from '../../../layout'
import { CampaignCard } from '../../../common'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { fetchMyOffers } from '../../../../redux'
import { isRecentlyAdded } from '../../../../utils/timeHelpers'
import { sortDescByCreatedAt } from '../../../../utils/dataHelpers'
import { useCurrentUser, useLoading } from '../../../../hooks'

import './MyOffersPage.scss'

export const MyOffersPage: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();
  const { setLoading } = useLoading();

  const { myOffers, loading } = useAppSelector(state => state.offer);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchMyOffers(currentUser?.id));
  }, [currentUser]);

  useEffect(() => {
    if (myOffers && !loading) {
      setLoading(false);
    }
  }, [myOffers, loading]);

  return (
    <AppLayout backButtonVisible title="My Offers: Campaigns">
      <div className="my-offers-container">
        {sortDescByCreatedAt(myOffers || []).map((item) => (
          <CampaignCard
            key={item.id}
            campaign={item.campaign}
            benefits={item.hotel.facilities}
            onClick={() => navigate(`/campaigns/my-offers/${item.campaign.crn}/${item.id}`)}
            isRecentlyAdded={isRecentlyAdded(item.createdAt)}
          />
        ))}
      </div>
    </AppLayout>
  )
}
