import React from 'react'
import { useForm } from 'react-hook-form'

import { AppLayout } from '../../layout'
import { Button, Loading } from '../../elements'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { sendSupportQuery } from '../../../redux'
import { useBodyClassName } from '../../../hooks/useBodyClassName'

import './SupportPage.scss'

interface IFeedback {
  name: string
  email: string
  message: string
}

export const SupportPage: React.FC = () => {
  useBodyClassName("bg-white")

  const { sendSupportQueryLoading: loading, sendSupportQuerySuccess: success } =
    useAppSelector((state) => state.support)
  const user = useAppSelector((state) => state.auth.userInfo)

  const dispatch = useAppDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IFeedback>({
    mode: 'onChange',
    delayError: 300,
    defaultValues: {
      name: user?.name || '',
      email: user?.email || '',
      message: '',
    },
  })

  const onSubmit = (values: IFeedback) => {
    dispatch(sendSupportQuery(values))
  }

  return (
    <AppLayout backButtonVisible title="Support">
      <div className="support-container">
        <p className="support-info">
          You can raise a support query by completing the form below, or
          alternatively emailing{' '}
          <a href="mailto:support@voice-of-influence.co.uk">
            support@voice-of-influence.co.uk
          </a>
        </p>
        <form className="support-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="support-form-item">
            <label htmlFor="name">Name:</label>
            <input
              id="name"
              type="text"
              placeholder="Name"
              name="name"
              disabled={success}
              {...register('name', { required: 'Name is required' })}
            />
            {errors.name?.message && (
              <span className="error-message">{errors.name.message}</span>
            )}
          </div>

          <div className="support-form-item">
            <label htmlFor="email">Email:</label>
            <input
              id="email"
              type="text"
              placeholder="Email"
              name="email"
              disabled={success}
              {...register('email', { required: 'Email is required' })}
            />
            {errors.email?.message && (
              <span className="error-message">{errors.email.message}</span>
            )}
          </div>

          <div className="support-form-item support-form-item-col">
            <label htmlFor="message">Description of support query:</label>
            <textarea
              id="message"
              placeholder="Description of support query:"
              name="name"
              disabled={success}
              {...register('message', {
                required: 'Message is required',
              })}></textarea>
            {errors.name?.message && (
              <span className="error-message">{errors.name.message}</span>
            )}
          </div>
          {loading && <Loading />}
          <Button variant="primary" disabled={!isValid || loading || success}>
            {success ? 'Submitted successfully' : 'Submit Query'}
          </Button>
        </form>
      </div>
    </AppLayout>
  )
}
