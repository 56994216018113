import React, { useMemo } from "react";
import classNames from "classnames";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { CampaignInterfaceProps, OfferInterfaceProps, offerStatusText } from "../../../../interfaces";
import { Calendar, ImageCarousel, Modal, ModalProps, Pane, Tab, Table } from "../../../elements";
import { ColumnDef } from "@tanstack/react-table";
import { SlideProps } from "react-slideshow-image";
import { MapPanel } from "../../MapPanel/MapPanel";

import { sanitizeHTML } from "../../../../utils/stringHelpers";
import { formatDate, formatTimeToBeginingOfDay } from "../../../../utils/timeHelpers";

import './CampaignViewModal.scss';

interface CampaignViewModalProps extends ModalProps {
  campaign: CampaignInterfaceProps;
  offers: OfferInterfaceProps[];
}

export const CampaignViewModal: React.FC<CampaignViewModalProps> = ({
  campaign,
  offers,
  open,
  onClose,
}: CampaignViewModalProps) => {
  const { isMobile } = useWindowSize();

  const slideOptions: SlideProps = {
    duration: 3000,
    arrows: false,
    children: null
  }

  const tileClassName = ({ date, view }) => {
    return (campaign?.availableDates || []).some((item) =>
      new Date(formatTimeToBeginingOfDay(item)).getTime() === new Date(formatTimeToBeginingOfDay(date)).getTime() &&
      new Date(item).getTime() > new Date().getTime()) ?
      'campaign-view-modal-tile campaign-view-modal-tile-available' :
      'campaign-view-modal-tile'
  }

  const offerTableColumns = useMemo<ColumnDef<OfferInterfaceProps>[]>(() => [
    {
      header: 'Name',
      accessorKey: 'user.name',
      meta: {
        columnClassName: isMobile ? 'sticky left' : '',
      }
    },
    {
      header: 'Selected Dates',
      accessorKey: 'selectedCampaignDates',
      cell: ({ getValue }) => (
        <ul className="offer-dates">
          {(getValue<Date[]>() || []).map((date, index) => (
            <li key={index}>{formatDate(date)}</li>
          ))}
        </ul>
      ),
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: ({ getValue }) => (
        <span className={classNames("offer-status", getValue<string>())}>{offerStatusText[getValue<string>()]}</span>
      ),
    },
  ], []);

  const offerTabMaybe: Pane = offers && offers.length ? {
    id: 'offers',
    title: 'Offers',
    element: (
      <div className="campaign-view-modal-offers">
        <Table columns={offerTableColumns} data={offers} />
      </div>
    )
  } : null;

  return (
    <Modal open={open} onClose={onClose} className={classNames({ 'campaign-view-modal-container': !isMobile, 'campaign-view-modal-container-mobile': isMobile })}>
      <div className="campaign-view-modal-wrapper">
        <div className="campaign-view-modal-images">
          <ImageCarousel images={[...(campaign?.images || []), ...(campaign.hotel?.images || [])]} options={slideOptions} />
        </div>
        <div className="campaign-view-modal-info-container">
          <div className="campaign-view-modal-basic">
            <div className="campaign-view-modal-title">{campaign?.name}</div>
            <div className="campaign-view-modal-schedule">Scheduled: {formatDate(campaign.startDate)} - {formatDate(campaign.endDate)}</div>
          </div>
          <div className="campaign-view-modal-advance">
            <Tab
              position="center"
              panes={[
                {
                  id: 'description',
                  title: 'Description',
                  element: (
                    <div className="campaign-view-modal-description">
                      <div className="campaign-view-modal-description-content" dangerouslySetInnerHTML={{ __html: sanitizeHTML(campaign.description) }} />
                    </div>
                  )
                },
                {
                  id: 'availability',
                  title: 'Available Dates',
                  element: (
                    <div className="campaign-view-modal-availability">
                      <Calendar
                        className="campaign-view-modal-calendar-container"
                        minDate={new Date(campaign.startDate)}
                        maxDate={new Date(campaign.endDate)}
                        activeStartDate={new Date(campaign.startDate)}
                        tileClassName={tileClassName}
                      />
                    </div>
                  )
                },
                {
                  id: 'terms',
                  title: 'Terms & Conditions',
                  element: (
                    <div className="campaign-view-modal-terms">
                      {campaign.terms && <div className="campaign-view-modal-terms-content" dangerouslySetInnerHTML={{ __html: sanitizeHTML(campaign.terms) }} />}
                    </div>
                  )
                },
                {
                  id: 'location',
                  title: 'Location',
                  element: (
                    <div className="campaign-view-modal-location">
                      <MapPanel hotel={campaign.hotel} height={'300px'} />
                    </div>
                  )
                },
                offerTabMaybe ? offerTabMaybe : {} as Pane
              ]}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};