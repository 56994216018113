import React from 'react'

import { AppLayout } from '../../layout'

import './AboutPage.scss'

export const AboutPage: React.FC = () => {
  return (
    <AppLayout backButtonVisible title="About VOI">
      <div className="about-container">
        <p className="about-description">
          VOi enables creators to obtain complimentary stays at desirable venues
          whilst enabling them to earn their fair share of commission by
          offering a discount to their following through the use of promotional
          codes.
          <br />
          <br />
          VOi is a platform targeted at increasing hotels’ direct bookings by
          utilising creators’ voice of influence. VOi values the individuality
          of creators and their direct relationship with their audience.
        </p>

        <ul className="about-board-members">
          <li className="about-board-members-item">
            Jai Hermolle Founder & CEO
          </li>
          <li className="about-board-members-item">
            Andrew White Commercial Director
          </li>
          <li className="about-board-members-item">
            Paul Hermolle Development Director
          </li>
        </ul>
      </div>
    </AppLayout>
  )
}
