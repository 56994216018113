import React, { useRef, useState } from "react";
import classNames from "classnames";
import { Button, FileUpload, Modal, ModalProps } from "../../../elements";
import { useCurrentUser, useLoading, useWindowSize } from "../../../../hooks";
import { CampaignInterfaceProps, MessageInterfaceProps } from "../../../../interfaces";
import { EditorState } from "draft-js";
import { RegisterOptions, useForm } from "react-hook-form";
import { stateToHTML } from "draft-js-export-html";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getSentMessages, sendMessage } from "../../../../redux";
import { RichTextEditor } from "../../../elements/RichTextEditor/RichTextEditor";

import "./MessageCreateModal.scss";
import { MdOutlineClose } from "react-icons/md";
import { uploadFileToS3 } from "../../../../utils/fileHelpers";

interface MessageCreateModalProps extends ModalProps {
  onSubmitted?: () => void;
  isOffer?: boolean;
  isReply?: boolean;
  campaign?: CampaignInterfaceProps;
  message?: MessageInterfaceProps;
  recipientId?: number;
}

export const MessageCreateModal: React.FC<MessageCreateModalProps> = ({
  open,
  onClose,
  onSubmitted,
  isOffer = false,
  isReply = false,
  campaign,
  message,
  recipientId,
}) => {
  const { isMobile } = useWindowSize();
  const { setLoading } = useLoading();
  const currentUser = useCurrentUser();
  const dispatch = useAppDispatch();
  const { id, ...rest } = message || {};
  const [messageEditorState, setMessageEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [attachments, setAttachments] = useState<File[]>([]);
  const { register, handleSubmit, formState: { errors }, setError, clearErrors } = useForm<MessageInterfaceProps>({
    mode: 'onChange',
    delayError: 200,
    defaultValues: isReply ? {
      ...rest,
      title: `Re: ${message?.title}`,
    } : {
      title: isOffer ? `Exciting Collaboration Opportunity: Join Our Influencer Campaign!` : '',
    }
  });
  const messageEditor = useRef(null);
  const { loading, error } = useAppSelector(state => state.message);

  const validateMessage = (name, message, state: EditorState) => {
    if (!state.getCurrentContent().hasText()) {
      setTimeout(() => {
        setError("message", { message });
      }, 500);
    } else {
      clearErrors("message");
    }
  };

  const onFormSubmit = async (data: any) => {
    try {
      setLoading(true);

      const files = [];

      if (attachments.length) {
        for (const attachment of attachments) {
          const fileResponse = await uploadFileToS3(attachment);

          files.push(fileResponse?.data?.location);
        }
      }

      const updatedData: MessageInterfaceProps = {
        ...data,
        senderId: currentUser?.id,
        recipientId: recipientId,
        message: stateToHTML(messageEditorState.getCurrentContent()),
        relatedCampaignId: campaign?.id,
        conversationId: isReply ? message?.conversationId : `conversation${currentUser?.id}${recipientId}${campaign?.id}${Date.now()}`,
        attachments: files.length ? files : null,
      };

      const response = await dispatch(sendMessage(updatedData));

      if (response?.payload?.isSuccess) {
        setLoading(false);
        dispatch(getSentMessages());
        onSubmitted && onSubmitted();
        onClose();
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const onFileSelect = (file: File) => {
    if (attachments.some(attachment => attachment.name === file.name)) {
      return;
    }

    setAttachments([...attachments, file]);
  }

  const onFileRemove = (file: File) => {
    setAttachments(attachments.filter(attachment => attachment.name !== file.name));
  }

  const registerOptions = {
    title: (): RegisterOptions => {
      return {
        required: 'Subject is required',
      }
    }
  }

  return (
    <Modal open={open} onClose={onClose} className={classNames({ 'message-modal-container': !isMobile })}>
      <div className="message-create-modal-container">
        <h3 className="message-create-modal-heading">
          {isOffer ? "Create Offer" : "Send Message"}
        </h3>

        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="message-create-modal-fields">
            <div className="message-create-modal-form-fields">
              <label htmlFor="title">Subject</label>
              <input
                type="text"
                id="title"
                {...register('title', registerOptions.title())}
              />
              {errors.title && <p className="error">{errors.title.message}</p>}
            </div>

            <div className="message-create-modal-form-fields">
              <label htmlFor="message">Message</label>
              <RichTextEditor
                editorState={messageEditorState}
                setEditorState={setMessageEditorState}
                editor={messageEditor}
                placeholder="Your message here..."
                onBlur={() => validateMessage('message', 'Message is required', messageEditorState)}
              />
              {errors.message && <p className="error">{errors.message.message}</p>}
            </div>

            <div className="message-create-modal-form-fields">
              <label htmlFor="attachments">Attachments</label>
              {attachments.length ? <div className="message-create-modal-attachments">
                {attachments.map((attachment, index) => (
                  <div className="message-create-modal-attachment" key={index}>
                    <span>{attachment.name}</span>
                    <Button variant="default" type="button" onClick={() => onFileRemove(attachment)}>
                      <MdOutlineClose />
                    </Button>
                  </div>
                ))}
              </div> : null}
              <FileUpload onFileSelect={onFileSelect} />
            </div>
          </div>

          <div className="message-create-modal-buttons">
            <Button variant="default" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={!!Object.keys(errors).length || !messageEditorState.getCurrentContent().hasText() || loading}>
              Send
            </Button>
          </div>
          {error && <p className="error system-error">{error}</p>}
        </form>
      </div>
    </Modal>
  )
};