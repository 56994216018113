import { createSlice } from "@reduxjs/toolkit";
import { banUser, createUserByAdmin, deleteUser, fetchUsers, getInfluencers, unbanUser, updateUserByAdmin } from "./userAction";
import { UserInterfaceProps } from "../../../interfaces";

const initialState: UserState = {
  loading: false,
  error: null,
  users: [],
  influencers: null,
  total: 0,
  page: 1,
  limit: 10,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.loading = false
      state.users = payload.data
      state.total = payload.total
      state.page = payload.page
      state.limit = payload.limit
    })
    builder.addCase(fetchUsers.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(banUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(banUser.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    builder.addCase(banUser.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(unbanUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(unbanUser.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    builder.addCase(unbanUser.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    builder.addCase(deleteUser.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })


    builder.addCase(getInfluencers.pending, (state) => {
      state.loading = true
      state.error = null
      state.influencers = null
    })
    builder.addCase(getInfluencers.fulfilled, (state, { payload }) => {
      state.loading = false
      state.influencers = payload.data
    })
    builder.addCase(getInfluencers.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(createUserByAdmin.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createUserByAdmin.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    builder.addCase(createUserByAdmin.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(updateUserByAdmin.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateUserByAdmin.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    builder.addCase(updateUserByAdmin.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
  }
});

export interface UserState {
  loading: boolean;
  error: string | null;
  users: Array<UserInterfaceProps>;
  influencers: Array<UserInterfaceProps>;
  total: number;
  page: number;
  limit: number;
}