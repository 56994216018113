import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { FiCheck, FiChevronRight } from "react-icons/fi";
import { Modal } from "../../../elements";
import { CampaignInterfaceProps } from "../../../../interfaces";
import { TermsAndConditions } from "../../TermsAndConditions/TermsAndConditions";
import { sanitizeHTML } from "../../../../utils/stringHelpers";

import './CampaignDetailOptions.scss'

interface CampaignOptionsProps {
  campaign: CampaignInterfaceProps,
  options: Array<{ id: number; title: string }>,
  onChange?: (newSelected: number[]) => void,
  isMyCampaign?: boolean,
}

export const CampaignDetailOptions: React.FC<CampaignOptionsProps> = ({
  campaign,
  options,
  onChange,
  isMyCampaign = false,
}) => {
  const [selected, setSelected] = useState<number[]>([])
  const [openDetailOptionModal, setOpenDetailOptionModal] = useState(false)
  const [modalTabId, setModalTabId] = useState(1);
  const [optionSelected, setOptionSelected] = useState(options[0]);
  const onChangeRef = useRef(onChange)

  const handleSelection = (id: number) => {
    setSelected((prev) => {
      if (prev.some((p) => p === id)) {
        return prev.filter((p) => p !== id)
      } else {
        return [...prev, id]
      }
    })
  }

  useEffect(() => {
    onChangeRef.current?.(selected)
  }, [selected])

  useEffect(() => {
    if (isMyCampaign) {
      setSelected([...options.map((option) => option.id)])
    }
  }, [isMyCampaign])

  const openDetailModal = (option: any) => {
    setOptionSelected(option)
    setModalTabId(option.id)
    setOpenDetailOptionModal(true)
  }

  return (
    <>
      <ul className="campaign-options">
        {options.map((option, index) => {
          const isDisabled = index > selected.length || isMyCampaign
          return (
            <li
              key={option.id}
              className={classNames('campaign-options-item', {
                disabled: isDisabled,
              })}>
              <div onClick={() => openDetailModal(option)}>{option.title}</div>
              <div onClick={isDisabled ? undefined : () => handleSelection(option.id)}>
                <input
                  type="checkbox"
                  checked={selected.includes(option.id)}
                  readOnly
                />
              </div>
            </li>
          )
        })}
      </ul>

      <Modal open={openDetailOptionModal} onClose={() => setOpenDetailOptionModal(false)}>
        <div className="detail-modal-container">
          <h3 className="detail-modal-heading">
            {optionSelected.title}
          </h3>

          {modalTabId === 1 && (
            <div className="detail-modal-description" dangerouslySetInnerHTML={{ __html: sanitizeHTML(campaign.description) }}>
            </div>
          )}

          {modalTabId === 2 && (
            <div className="detail-modal-description">
              {campaign.tiktokRequirements ? (
                <>
                  <h5>TikTok Requirements</h5>
                  <p>{campaign.tiktokRequirementsDescription}</p>
                </>
              ) : null}
              {campaign.instagramRequirements ? (
                <>
                  <h5>Instagram Requirements</h5>
                  <p>{campaign.instagramRequirementsDescription}</p>
                </>
              ) : null}

              {!campaign.instagramRequirements && !campaign.tiktokRequirements ? (
                <p>No requirements</p>
              ) : null}
            </div>
          )}

          {modalTabId === 3 && (
            <div className="detail-modal-description" dangerouslySetInnerHTML={{ __html: sanitizeHTML(campaign.terms) }}></div>
          )}

          {modalTabId === 4 && (
            <div className="detail-modal-description">
              <TermsAndConditions />
            </div>
          )}

          <div className="detail-modal-footer">
            <div className="detail-modal-footer-actions">
              <button
                className={classNames('detail-action-button check', {
                  active: selected.includes(optionSelected.id),
                })}
                disabled={modalTabId > selected.length + 1 || isMyCampaign}
                onClick={modalTabId > selected.length + 1 || isMyCampaign ? undefined : () => handleSelection(optionSelected.id)}
              >
                <FiCheck size={30} />
              </button>

              <button
                className="detail-action-button next"
                onClick={() => modalTabId === options.length ? setOpenDetailOptionModal(false) : openDetailModal(options[modalTabId])}
              >
                <FiChevronRight size={30} />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}