import classNames from 'classnames'
import React from 'react'

import './Tags.scss'

interface TagsProps {
  tags: string[]
  size?: 'sm' | 'lg'
  variant?: 'default' | 'success' | 'primary',
  onClick?: (tag: string) => any,
  children?: React.ReactNode,
  className?: string
}

export const Tags: React.FC<TagsProps> = ({
  tags,
  size = 'sm',
  variant = 'default',
  onClick,
  children,
  className
}) => (
  <div className={classNames('tags', size, variant, className)}>
    {tags.map((tag, i) => (
      <div key={i} className="tags-item" onClick={() => typeof onClick !== "undefined" && onClick(tag)}>
        {tag}
      </div>
    ))}
    {children}
  </div>
)
