import React from 'react'

import { AppLayout } from '../../layout'
import { useBodyClassName } from '../../../hooks/useBodyClassName';

import './ContactPage.scss'

export const ContactPage: React.FC = () => {
  useBodyClassName("bg-white");

  return (
    <AppLayout backButtonVisible title="Contact Us">
      <div className="contact-container">
        <p className="contact-description">
          <span>Mail:</span> <span>info@voice-of-influence.co.uk</span> <br />
        </p>
        <p className="contact-description">
          <span>Call:</span> <span>01789 205597</span> <br />
        </p>
        <p className="contact-description">
          <span>Address:</span>{' '}
          <span>Featherbed Barns, Stourton, CV36 5HG</span> <br />
        </p>
      </div>
    </AppLayout>
  )
}
