import React, { useState, useRef, useEffect } from 'react'
import ReactCrop, { Crop } from 'react-image-crop';
import classNames from 'classnames';
import { Modal } from '../Modal/Modal';
import { Button } from '../Button/Button';
import { MdCropFree, MdCropLandscape, MdCropPortrait, MdCropSquare, MdOutlineClose } from 'react-icons/md';
import { FREE_ASPECT_RATIO, LANDSCAPE_ASPECT_RATIO, PORTRAIT_ASPECT_RATIO, SQUARE_ASPECT_RATIO } from '../../../utils/types';

import './ImageUpload.scss'
import 'react-image-crop/src/ReactCrop.scss'

const DEFAULT_CROP: Crop = {
  height: 200,
  width: 200,
  x: 50,
  y: 50,
  unit: 'px'
}

interface ImageUploadProps {
  selectedFile?: any;
  setSelectedFile: any;
  currentImage?: string;
  aspect?: number | undefined;
  isProfileImage?: boolean;
  className?: string;
  imageClassName?: string;
  onImageRemove?: () => void;
}

export const ImageUpload = ({
  selectedFile,
  setSelectedFile,
  currentImage,
  aspect,
  isProfileImage = false,
  className,
  imageClassName,
  onImageRemove,
}: ImageUploadProps) => {
  const [cropModalVisible, setCropModalVisible] = useState(false);
  const [preview, setPreview] = useState('');
  const [viewImage, setViewImage] = useState<string | ArrayBuffer>();
  const [aspectRatio, setAspectRatio] = useState<number | undefined>(aspect || FREE_ASPECT_RATIO);
  const [blob, setBlob] = useState<Blob | null>(null);
  const [crop, setCrop] = useState<Crop>(DEFAULT_CROP);
  const imageCropRef = useRef(null);


  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      setPreview(undefined);

      return null;
    }

    setCrop(DEFAULT_CROP);

    const imageReader = new FileReader();

    imageReader.readAsDataURL(e.target.files[0]);
    imageReader.onloadend = () => {
      setViewImage(imageReader.result);
      setCropModalVisible(true);
    };
  }

  const setCompleteCrop = async () => {
    if (imageCropRef.current && crop.width && crop.height) {
      const imageCanvas = document.createElement("canvas");
      const scaleX = imageCropRef.current.naturalWidth / imageCropRef.current.width;
      const scaleY = imageCropRef.current.naturalHeight / imageCropRef.current.height;
      imageCanvas.width = crop.width;
      imageCanvas.height = crop.height;

      const imgCx = imageCanvas.getContext("2d");
      imgCx.drawImage(
        imageCropRef.current,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      await imageCanvas.toBlob((blob) => {
        setBlob(blob);
        return blob;
      }, "image/jpeg");
    }
  }

  useEffect(() => {
    if (blob) {
      const blobUrl = URL.createObjectURL(blob);
      setPreview(blobUrl);
      setSelectedFile(blobUrl);

      if (cropModalVisible) {
        setCropModalVisible(false);
      }
    }
  }, [blob]);

  useEffect(() => {
    const onEnterFormSubmit = (event: any) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        event.stopPropagation();
        setCompleteCrop();
      }
    }

    document.addEventListener('keydown', onEnterFormSubmit);

    return () => {
      document.removeEventListener('keydown', onEnterFormSubmit);
    }
  }, []);


  return (
    <div className={classNames('image-area', className)}>
      <input type='file' onChange={onSelectFile} accept="image/*;capture=camera" />
      {selectedFile || currentImage ? (
        <div className="preview-image-container">
          <img className={classNames('preview-image', imageClassName, { 'is-profile-image': isProfileImage })} src={preview || currentImage} alt="Preview" />
          {onImageRemove ? <Button variant="default" onClick={onImageRemove}><MdOutlineClose /></Button> : null}
        </div>
      ) : (
        <div className="file-dummy">
          Upload
        </div>
      )}
      <Modal className="crop-image-modal" open={cropModalVisible} onClose={() => setCropModalVisible(false)}>
        <div className="crop-image-modal-container">
          <h3 className="crop-image-modal-heading">Adjust your image</h3>

          <div className="crop-image-modal-description">
            Please adjust your image to fit the required dimensions.
          </div>

          {!isProfileImage ? <div className="crop-image-tools-container">
            <span>Aspect ratio options:</span>
            <Button variant={aspectRatio === LANDSCAPE_ASPECT_RATIO ? "primary" : "default"} onClick={() => setAspectRatio(LANDSCAPE_ASPECT_RATIO)} title='Landscape crop'>
              <MdCropLandscape />
            </Button>
            <Button variant={aspectRatio === PORTRAIT_ASPECT_RATIO ? "primary" : "default"} onClick={() => setAspectRatio(PORTRAIT_ASPECT_RATIO)} title='Portrait crop'>
              <MdCropPortrait />
            </Button>
            <Button variant={aspectRatio === SQUARE_ASPECT_RATIO ? "primary" : "default"} onClick={() => setAspectRatio(SQUARE_ASPECT_RATIO)} title='Square crop'>
              <MdCropSquare />
            </Button>
            <Button variant={aspectRatio === FREE_ASPECT_RATIO ? "primary" : "default"} onClick={() => setAspectRatio(FREE_ASPECT_RATIO)} title='Free crop'>
              <MdCropFree />
            </Button>
          </div> : null}

          <ReactCrop
            children={<img src={viewImage as string} alt="Crop preview" ref={imageCropRef} />}
            aspect={aspectRatio}
            crop={crop}
            onChange={setCrop}
          />

          <div className="crop-image-modal-buttons">
            <Button variant="default" onClick={() => setCropModalVisible(false)}>Cancel</Button>
            <Button variant="primary" onClick={() => setCompleteCrop()}>Confirm</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}