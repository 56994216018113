export enum UserRoles {
  ADMIN = "admin",
  INFLUENCER = "influencer",
  HOTEL_MANAGER = "hotel_manager",
}

export const UserRolesArray = [
  {
    value: UserRoles.ADMIN,
    label: "Admin",
  },
  {
    value: UserRoles.INFLUENCER,
    label: "Influencer",
  },
  {
    value: UserRoles.HOTEL_MANAGER,
    label: "Hotel Manager",
  },
];

export const userRoleText = {
  [UserRoles.ADMIN]: "Admin",
  [UserRoles.INFLUENCER]: "Influencer",
  [UserRoles.HOTEL_MANAGER]: "Hotel Manager",
};

export interface UserInterfaceProps {
  id?: number;
  email?: string;
  password?: string;
  confirmPassword?: string;
  name: string;
  city?: string;
  keywords?: string;
  bio?: string;
  irn?: string;
  tags?: Array<string>;
  instagram?: string;
  instagramFollowers?: string;
  instagramEngagement?: string;
  instagramRating?: string;
  tiktok?: string;
  tiktokFollowers?: string;
  tiktokEngagement?: string;
  tiktokRating?: string;
  youtube?: string;
  youtubeFollowers?: string;
  youtubeEngagement?: string;
  youtubeRating?: string;
  availableDates?: Array<Date>;
  avatar?: string;
  role?: UserRoles;
  deleted?: boolean;
  banned?: boolean;
  enabled2fa?: boolean;
  created_at?: Date;
}