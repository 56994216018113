import { createAppAsyncThunk } from "../../createAppAsyncThunk";
import { HotelInterfaceProps } from "../../../interfaces";
import { getAxiosInstance } from "../../../utils/axiosHelpers";

export const fetchHotels = createAppAsyncThunk(
  'hotel/fetch',
  async (params: { page: number; limit: number }, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/hotels`, { params });

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const createHotel = createAppAsyncThunk(
  'hotel/create',
  async (hotel: HotelInterfaceProps, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.post(`/hotels`, hotel);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateHotel = createAppAsyncThunk(
  'hotel/update',
  async (hotel: HotelInterfaceProps, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/hotels/${hotel.id}`, hotel);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteHotel = createAppAsyncThunk(
  'hotel/delete',
  async (id: number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.delete(`/hotels/${id}`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getHotelById = createAppAsyncThunk(
  'hotel/getById',
  async (id: number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/hotels/${id}`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getHotelByHrn = createAppAsyncThunk(
  'hotel/getByHrn',
  async (hrn: string, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/hotels/hrn/${hrn}`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)