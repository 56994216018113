import React from "react"
import classNames from "classnames"
import { NavLink, useNavigate } from "react-router-dom"

import "./Navbar.scss"

interface SideNavProps {
  items: { title: string, path: string }[],
  className?: string,
  onClick?: () => void
}

export const Navbar: React.FC<SideNavProps> = ({
  items,
  className,
  onClick = () => { }
}: SideNavProps) => {
  const navigate = useNavigate();

  return (
    <div className={classNames("navbar-container", className)}>
      {items.map(({ title, path }) => (
        <div key={path} className="navbar-list" onClick={() => { onClick(); navigate(path); }}>
          <NavLink
            to={path}
            className={({ isActive }) =>
              isActive
                ? 'navbar-list-item active'
                : 'navbar-list-item'
            }>
            {title}
          </NavLink>
        </div>
      ))}
    </div>
  )
}