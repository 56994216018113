import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Calendar, Modal, ModalProps } from "../../../elements"
import { CampaignInterfaceProps, OfferInterfaceProps } from "../../../../interfaces"
import { sanitizeHTML } from "../../../../utils/stringHelpers"
import { useCurrentUser, useLoading } from "../../../../hooks"
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import { enquiryOffer, enquiryOfferFromHotel, withdrawEnquiry } from "../../../../redux"
import { formatDate } from "../../../../utils/timeHelpers"

import "./CampaignDetailEnquiryModal.scss"

interface ConfirmEnquiryModalProps extends ModalProps {
  isMyOffer?: boolean,
  campaign: CampaignInterfaceProps
  currentOffer?: OfferInterfaceProps | null,
}

export const CampaignDetailEnquiryModal: React.FC<ConfirmEnquiryModalProps> = ({
  open,
  onClose,
  isMyOffer = false,
  campaign,
  currentOffer = null,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [calendarSection, setCalendarSection] = useState<boolean>(true);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [enquirySent, setEnquirySent] = useState(false);
  const { setLoading } = useLoading();
  const currentUser = useCurrentUser();

  const { offer, loading, error } = useAppSelector(state => state.offer);

  const availableDates = campaign.availableDates.map((date) => new Date(new Date(date).setHours(0, 0, 0, 0)));
  const takenDates = campaign?.registeredDates?.map((date) => new Date(new Date(date).setHours(0, 0, 0, 0))) || [];

  const tileClassName = ({ date, view }) => {
    return availableDates.find((item) => item.getTime() === date.getTime()) ?
      'campaign-calendar-tile campaign-calendar-tile-available' :
      takenDates.find((item) => item.getTime() === date.getTime()) ?
        'campaign-calendar-tile campaign-calendar-tile-unavailable' :
        'campaign-calendar-tile'
  }

  const handleSelection = (date: Date) => {
    setSelectedDates((prev) => {
      if (prev.some((pdate) => pdate.getTime() === date.getTime())) {
        return prev.filter((pdate) => pdate.getTime() !== date.getTime())
      } else {
        return [...prev, date]
      }
    })
  }

  const onSubmitOffer = async () => {
    try {
      setLoading(true);

      const bodyParams: OfferInterfaceProps = {
        relatedCampaignId: campaign.id,
        relatedHotelId: campaign.hotel.id,
        relatedInfluencerId: currentUser.id,
        selectedCampaignDates: selectedDates,
      };

      const response = await dispatch(isMyOffer ? enquiryOfferFromHotel({ ...currentOffer, ...bodyParams }) : enquiryOffer(bodyParams));

      if (response.payload?.isSuccess) {
        setLoading(false);
        setEnquirySent(true);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const onWithdrawOffer = async () => {
    try {
      setLoading(true);

      const response = await dispatch(withdrawEnquiry(offer?.id));

      if (response.payload?.isSuccess) {
        setLoading(false);
        setEnquirySent(false);
        onClose();
      }
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <Modal open={open} onClose={enquirySent ? () => navigate('/') : onClose}>
      {calendarSection ? (
        <div className="confirm-enquiry-modal-container">
          <h3 className="confirm-enquiry-modal-heading">
            Select your preferred dates
          </h3>
          <Calendar
            className="campaign-calendar"
            activeStartDate={new Date(campaign.startDate)}
            minDate={new Date(campaign.startDate)}
            maxDate={new Date(campaign.endDate)}
            tileClassName={tileClassName}
          />
          <div className="campaign-available-dates">
            <div className="campaign-available-dates-title">Available dates</div>
            <div className="campaign-available-dates-container">
              {availableDates.filter((cDate) => !takenDates.includes(cDate)).map((date, index) => (
                <div className="campaign-available-date" key={index} onClick={() => handleSelection(date)}>
                  <span>{formatDate(date, { year: "2-digit", month: "2-digit", day: "2-digit" })}</span>
                  <input type="checkbox" checked={!!selectedDates.find((sdate) => sdate.getTime() === date.getTime())} readOnly />
                </div>
              ))}
            </div>
          </div>
          <div className="confirm-enquiry-modal-buttons">
            <Button variant={'primary'} onClick={() => setCalendarSection(false)}>
              Continue
            </Button>
          </div>
        </div>
      ) : !enquirySent ? (
        <div className="confirm-enquiry-modal-container">
          <h3 className="confirm-enquiry-modal-heading">
            {isMyOffer ? "Confirm your acceptance of offer" : "Confirm your Campaign Enquiry"}
          </h3>
          <h5 className="confirm-enquiry-modal-subheading">Summary</h5>
          <div className="confirm-enquiry-modal-description" dangerouslySetInnerHTML={{ __html: sanitizeHTML(campaign.description) }}></div>
          <h5 className="confirm-enquiry-modal-subheading">Requirements</h5>
          <div className="confirm-enquiry-modal-requirements">
            {campaign?.tiktokRequirements ? (
              <>
                <h5>TikTok Requirements</h5>
                <p>{campaign.tiktokRequirementsDescription}</p>
              </>
            ) : null}
            {campaign?.instagramRequirements ? (
              <>
                <h5>Instagram Requirements</h5>
                <p>{campaign.instagramRequirementsDescription}</p>
              </>
            ) : null}

            {!campaign?.instagramRequirements && !campaign?.tiktokRequirements ? (
              <p>No requirements</p>
            ) : null}
          </div>
          <h5 className="confirm-enquiry-modal-subheading">Campaign dates</h5>
          <div className="confirm-enquiry-modal-description">
            <span>Please confirm your preferred dates:</span>
            <ul>
              {selectedDates.map((date, index) => (
                <li key={index}><strong>{formatDate(date, { year: "2-digit", month: "2-digit", day: "2-digit" })}</strong></li>
              ))}
            </ul>
          </div>
          <h5 className="confirm-enquiry-modal-subheading">Confirmation</h5>
          <div className="confirm-enquiry-modal-description">
            By sending this enquiry, you confirm your commitment to the listed
            deliverables and terms.
          </div>

          <div className="confirm-enquiry-modal-buttons">
            {error && <p className="error system-error">{error}</p>}
            <div className="confirm-enquiry-modal-buttons-wrapper">
              <Button variant="default" onClick={() => setCalendarSection(true)}>
                Back
              </Button>
              <Button className="confirm-enquiry-modal-buttons-submit" onClick={() => onSubmitOffer()} disabled={loading}>
                Confirm & Send
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="confirm-enquiry-modal-container">
          <h3 className="confirm-enquiry-modal-heading">Enquiry Confirmed!</h3>

          <div className="confirm-enquiry-modal-description">
            Thank you for applying to <strong>{campaign.name}</strong>
            <br />
            <br />
            You can now find this campaign in 'My Campaigns'
            <br />
            <br />
            Once ... reviews your enquiry, they will get in touch with you
            directly via email finalizing the collaboration.
            <br />
            <br />
            If you've missed something, let us know by sending a message using a
            chatbox.
            <br />
            <br />
            Please be aware that a representative from ... and VOI have access
            to the chat to assist you with any questions you may have regarding
            details of your stay or your campaign.
          </div>

          <div className="confirm-enquiry-modal-requirements">
            You can expect a typical response within 48 hours.
          </div>

          <div className="confirm-enquiry-modal-buttons">
            {error && <p className="error system-error">{error}</p>}
            <Button
              className="confirm-enquiry-modal-buttons-withdraw"
              type="button"
              onClick={() => onWithdrawOffer()}
              disabled={loading}
            >
              Withdraw Enquiry
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}