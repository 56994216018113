import { createSlice } from "@reduxjs/toolkit";
import { cancelCampaign, createCampaign, deleteCampaign, fetchCampaigns, getCampaignByHotelId, getCampaignByHotelOwnerId, getCampaignDetailByCrn, updateCampaign } from "./campaignAction";
import { CampaignInterfaceProps } from "../../../interfaces";

const initialState: CampaignState = {
  loading: false,
  error: null,
  campaigns: null,
  currentCampaign: null,
  availableCampaigns: null,
  total: 0,
  page: 1,
  limit: 10,
}

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    resetCurrentCampaign: (state) => {
      state.currentCampaign = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaigns.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchCampaigns.fulfilled, (state, { payload }) => {
        state.loading = false
        state.campaigns = payload.data || []
        state.total = payload.total
        state.page = payload.page
        state.limit = payload.limit
      })
      .addCase(fetchCampaigns.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(createCampaign.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(createCampaign.fulfilled, (state, { payload }) => {
        state.loading = false
      })
      .addCase(createCampaign.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(updateCampaign.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateCampaign.fulfilled, (state, { payload }) => {
        state.loading = false
      })
      .addCase(updateCampaign.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(deleteCampaign.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(deleteCampaign.fulfilled, (state, { payload }) => {
        state.loading = false
      })
      .addCase(deleteCampaign.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(getCampaignDetailByCrn.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getCampaignDetailByCrn.fulfilled, (state, { payload }) => {
        state.loading = false
        state.currentCampaign = payload.data
      })
      .addCase(getCampaignDetailByCrn.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(cancelCampaign.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(cancelCampaign.fulfilled, (state, { payload }) => {
        state.loading = false
      })
      .addCase(cancelCampaign.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(getCampaignByHotelId.pending, (state) => {
        state.loading = true
        state.error = null
        state.availableCampaigns = null
      })
      .addCase(getCampaignByHotelId.fulfilled, (state, { payload }) => {
        state.loading = false
        state.availableCampaigns = payload.data
      })
      .addCase(getCampaignByHotelId.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(getCampaignByHotelOwnerId.pending, (state) => {
        state.loading = true
        state.error = null
        state.campaigns = null
      })
      .addCase(getCampaignByHotelOwnerId.fulfilled, (state, { payload }) => {
        state.loading = false
        state.campaigns = payload.data || []
      })
      .addCase(getCampaignByHotelOwnerId.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
});

export interface CampaignState {
  loading: boolean;
  error: string | null;
  campaigns: CampaignInterfaceProps[] | null;
  currentCampaign: CampaignInterfaceProps | null;
  availableCampaigns: CampaignInterfaceProps[] | null;
  total: number;
  page: number;
  limit: number;
}

export const { resetCurrentCampaign } = campaignSlice.actions;