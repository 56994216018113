import React, { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { AiOutlinePlus } from 'react-icons/ai';
import { ColumnDef } from '@tanstack/react-table';
import { IoBan, IoShieldCheckmarkOutline } from 'react-icons/io5';
import { IoIosSend } from 'react-icons/io';
import { MdDelete, MdModeEditOutline } from 'react-icons/md';

import { AlertModal, Button, Table } from '../../../elements';
import { MessageCreateModal, UserCard, UserCreateModal } from '../../../common';
import { UserInterfaceProps, userRoleText } from '../../../../interfaces';
import { banUser, deleteUser, fetchUsers, unbanUser } from '../../../../redux';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useCurrentUser, useLoading } from '../../../../hooks';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { useBodyClassName } from '../../../../hooks/useBodyClassName';

import './ManageUserPage.scss';

export const ManageUserPage: React.FC = () => {
  useBodyClassName("bg-white");

  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();
  const { width, isMobile } = useWindowSize();
  const { loading, setLoading } = useLoading();
  const [isBanModalVisible, setIsBanModalVisible] = useState(false);
  const [isUnbanModalVisible, setIsUnbanModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isMessageModalVisible, setIsMessageModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserInterfaceProps | null>(
    null,
  );
  const [cookie, setCookie, removeCookie] = useCookies(['accessToken']);

  const { users } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchUsers({ page: 1, limit: 10 }));
  }, []);

  const onBanUser = (user: UserInterfaceProps) => {
    setSelectedUser(user);
    setIsBanModalVisible(true);
  };

  const onUnbanUser = (user: UserInterfaceProps) => {
    setSelectedUser(user);
    setIsUnbanModalVisible(true);
  };

  const onDeleteUser = (user: UserInterfaceProps) => {
    setSelectedUser(user);
    setIsDeleteModalVisible(true);
  };

  const onEditUser = (user: UserInterfaceProps) => {
    setSelectedUser(user);
    setIsEdit(true);
    setIsCreateModalVisible(true);
  };

  const onShowMessageModal = (user: UserInterfaceProps) => {
    setSelectedUser(user);
    setIsMessageModalVisible(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setIsEdit(false);
    setIsCreateModalVisible(false);
  };

  const onLogout = () => {
    localStorage.removeItem('userInfo');
    cookie.accessToken && removeCookie('accessToken', { path: '/' });
  };

  const onConfirmBanUser = async () => {
    try {
      setLoading(true);
      const response = await dispatch(banUser(selectedUser?.id));

      if (response?.payload?.isSuccess) {
        setIsBanModalVisible(false);
        setSelectedUser(null);

        if (selectedUser?.id === currentUser?.id) {
          onLogout();
        }

        await dispatch(fetchUsers({ page: 1, limit: 10 }));
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSelectedUser(null);
    }
  };

  const onConfirmUnbanUser = async () => {
    try {
      setLoading(true);
      const response = await dispatch(unbanUser(selectedUser?.id));

      if (response?.payload?.isSuccess) {
        setIsUnbanModalVisible(false);
        setSelectedUser(null);

        await dispatch(fetchUsers({ page: 1, limit: 10 }));
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSelectedUser(null);
    }
  };

  const onConfirmDeleteUser = async () => {
    try {
      setLoading(true);
      const response = await dispatch(deleteUser(selectedUser?.id));

      if (response?.payload?.isSuccess) {
        setIsDeleteModalVisible(false);
        setSelectedUser(null);

        if (selectedUser?.id === currentUser?.id) {
          onLogout();
        }

        await dispatch(fetchUsers({ page: 1, limit: 10 }));
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSelectedUser(null);
    }
  };

  const columns = useMemo<ColumnDef<UserInterfaceProps>[]>(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        cell: ({ row, getValue, column }) => <span>{getValue<number>()}</span>,
        meta: {
          columnClassName: 'sticky left',
        },
      },
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: 'Bio',
        accessorKey: 'bio',
      },
      {
        header: 'Role',
        accessorKey: 'role',
        cell: ({ row }) => <span>{userRoleText[row.original.role]}</span>,
      },
      {
        header: 'City',
        accessorKey: 'city',
      },
      {
        header: (props) => {
          return (
            <div className="user-table-social-header">
              <span>Followers</span>
              <span>(Instagram | Youtube | Tiktok)</span>
            </div>
          );
        },
        accessorKey: 'instagramFollowers',
        cell: ({ row }) => (
          <span>
            {row.original.instagramFollowers || '-'} |{' '}
            {row.original.youtubeFollowers || '-'} |{' '}
            {row.original.tiktokFollowers || '-'}
          </span>
        ),
      },
      {
        header: (props) => {
          return (
            <div className="user-table-social-header">
              <span>Engagement</span>
              <span>(Instagram | Youtube | Tiktok)</span>
            </div>
          );
        },
        accessorKey: 'instagramEngagement',
        cell: ({ row }) => (
          <span>
            {row.original.instagramEngagement || '-'} |{' '}
            {row.original.youtubeEngagement || '-'} |{' '}
            {row.original.tiktokEngagement || '-'}
          </span>
        ),
      },
      {
        header: (props) => {
          return (
            <div className="user-table-social-header">
              <span>Rating</span>
              <span>(Instagram | Youtube | Tiktok)</span>
            </div>
          );
        },
        accessorKey: 'instagramRating',
        cell: ({ row }) => (
          <span>
            {row.original.instagramRating || '-'} |{' '}
            {row.original.youtubeRating || '-'} |{' '}
            {row.original.tiktokRating || '-'}
          </span>
        ),
      },
      {
        header: 'Status',
        cell: ({ row }) => (
          <span>{row.original.banned ? 'Banned' : 'Active'}</span>
        ),
      },
      {
        header: 'Actions',
        cell: ({ row }) => (
          <div className="user-table-actions">
            <Button
              variant="default"
              onClick={() => onShowMessageModal(row.original)}>
              <IoIosSend />
            </Button>
            <Button
              variant={'primary'}
              onClick={() => onEditUser(row.original)}>
              <MdModeEditOutline />
            </Button>
            <Button
              variant={row.original.banned ? 'primary' : 'danger'}
              onClick={() =>
                row.original.banned
                  ? onUnbanUser(row.original)
                  : onBanUser(row.original)
              }
              title={row.original.banned ? 'Unban user' : 'Ban user'}>
              {row.original.banned ? <IoShieldCheckmarkOutline /> : <IoBan />}
            </Button>
            <Button
              variant={'danger'}
              onClick={() => onDeleteUser(row.original)}
              title="Delete user">
              <MdDelete />
            </Button>
          </div>
        ),
        meta: {
          columnClassName: 'sticky right',
        },
      },
    ],
    [],
  );

  return (
    <div
      className="manage-user-page-container"
      style={isMobile ? { width: width, maxWidth: width } : {}}>
      <div className="manage-user-page-header">
        <h1>Manage Users Page</h1>
        <Button
          variant={'primary'}
          onClick={() => setIsCreateModalVisible(true)}>
          {isMobile ? <AiOutlinePlus /> : 'Create'}
        </Button>
      </div>

      {users && users.length ? (
        <div className="manage-user-page-list">
          {isMobile ? (
            (users || []).map((user: UserInterfaceProps) => (
              <div className="manage-user-page-card">
                <UserCard
                  user={user}
                  action={
                    <div className="user-table-actions">
                      <Button
                        variant={'primary'}
                        onClick={() => onEditUser(user)}>
                        <MdModeEditOutline />
                      </Button>
                      <Button
                        variant={user.banned ? 'primary' : 'danger'}
                        onClick={() =>
                          user.banned ? onUnbanUser(user) : onBanUser(user)
                        }
                        title={user.banned ? 'Unban user' : 'Ban user'}>
                        {user.banned ? <IoShieldCheckmarkOutline /> : <IoBan />}
                      </Button>
                      <Button
                        variant={'danger'}
                        onClick={() => onDeleteUser(user)}
                        title="Delete user">
                        <MdDelete />
                      </Button>
                    </div>
                  }
                />
              </div>
            ))
          ) : (
            <Table columns={columns} data={users} />
          )}
        </div>
      ) : (
        <div className="manage-user-page-empty">
          <h2>No users found</h2>
        </div>
      )}

      {isCreateModalVisible ? (
        <UserCreateModal
          open={isCreateModalVisible}
          onClose={() => closeModal()}
          currentUser={selectedUser}
          isEdit={isEdit}
        />
      ) : null}

      {isMessageModalVisible ? (
        <MessageCreateModal
          open={isMessageModalVisible}
          onClose={() => setIsMessageModalVisible(false)}
          recipientId={selectedUser?.id}
        />
      ) : null}

      <AlertModal
        open={isBanModalVisible || isUnbanModalVisible}
        onClose={() =>
          isBanModalVisible
            ? setIsBanModalVisible(false)
            : setIsUnbanModalVisible(false)
        }
        title={isBanModalVisible ? 'Ban User' : 'Unban User'}
        description={`Are you sure you want to ${
          isBanModalVisible ? 'ban' : 'unban'
        } this user?${
          selectedUser?.id === currentUser?.id ? ' You will be logged out.' : ''
        }`}
        onConfirm={() =>
          isBanModalVisible ? onConfirmBanUser() : onConfirmUnbanUser()
        }
        loading={loading}
      />

      <AlertModal
        open={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        title={'Delete User'}
        description={`Are you sure you want to delete this user?${
          selectedUser?.id === currentUser?.id ? ' You will be logged out.' : ''
        }`}
        onConfirm={() => onConfirmDeleteUser()}
        loading={loading}
      />
    </div>
  );
};
