import { createAppAsyncThunk } from "../../createAppAsyncThunk";
import { OfferInterfaceProps } from "../../../interfaces";
import { getAxiosInstance } from "../../../utils/axiosHelpers";

export const fetchMyCampaigns = createAppAsyncThunk(
  'offer/fetchMyCampaigns',
  async (userId: string, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/offers/my-campaigns/${userId}`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const enquiryOffer = createAppAsyncThunk(
  'offer/enquiryOffer',
  async (data: OfferInterfaceProps, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.post(`/offers`, data);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const withdrawEnquiry = createAppAsyncThunk(
  'offer/withdrawEnquiry',
  async (offerId: string | number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/offers/withdraw/${offerId}`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getOfferById = createAppAsyncThunk(
  'offer/getOfferById',
  async (offerId: string | number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/offers/${offerId}`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getOfferByCampaignId = createAppAsyncThunk(
  'offer/getOfferByCampaignId',
  async ({ campaignId, queryParams }: { campaignId: string | number, queryParams?: object }, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const queryParamsString = queryParams && Object.keys(queryParams).map((key) => `${key}=${queryParams[key]}`).join('&');
      const response = await instance.get(`/offers/campaign/${campaignId}${queryParamsString ? `?${queryParamsString}` : ''}`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const sendOfferByHotel = createAppAsyncThunk(
  'offer/sendOfferByHotel',
  async (data: OfferInterfaceProps, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.post(`/offers/sentInvited`, data);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const acceptOffer = createAppAsyncThunk(
  'offer/acceptOffer',
  async (offerId: string | number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/offers/acceptByHotel/${offerId}`);

      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const rejectOffer = createAppAsyncThunk(
  'offer/rejectOffer',
  async (offerId: string | number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/offers/rejectByHotel/${offerId}`);

      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const cancelOffer = createAppAsyncThunk(
  'offer/cancelOffer',
  async (offerId: string | number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/offers/cancel/${offerId}`);

      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const fetchMyOffers = createAppAsyncThunk(
  'offer/fetchMyOffers',
  async (userId: string | number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/offers/my-offers/${userId}`);

      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const enquiryOfferFromHotel = createAppAsyncThunk(
  'offer/enquiryOfferFromHotel',
  async (data: OfferInterfaceProps, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/offers/acceptByUser/${data.id}`, data);

      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)