import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { ProfilePage } from '../views/profile/ProfilePage/ProfilePage'
import { EditProfilePage } from '../views/profile/EditProfilePage/EditProfilePage'
import { MultiFactorAuthenticationPage } from '../views/profile/MultiFactorAuthenticationPage/MultiFactorAuthenticationPage'

export const ProfileRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ProfilePage />} />
      <Route path=":id" element={<ProfilePage />} />
      <Route path="edit" element={<EditProfilePage />} />
      <Route path="multi-factor" element={<MultiFactorAuthenticationPage />} />
    </Routes>
  )
}
