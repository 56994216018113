import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppLayout } from '../../../layout'
import { CampaignCard } from '../../../common'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { fetchMyCampaigns } from '../../../../redux'
import { useCurrentUser } from '../../../../hooks'
import { OfferStatus } from '../../../../interfaces'
import { isRecentlyAdded } from '../../../../utils/timeHelpers'
import { sortDescByCreatedAt } from '../../../../utils/dataHelpers'

import './MyCampaignsPage.scss'

export const MyCampaignsPage: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();

  const { myCampaigns } = useAppSelector(state => state.offer);

  useEffect(() => {
    dispatch(fetchMyCampaigns(currentUser?.id));
  }, []);

  return (
    <AppLayout backButtonVisible title="My Campaigns">
      <div className="my-campaigns-container">
        {(sortDescByCreatedAt(myCampaigns || [])).filter(item => [OfferStatus.PENDING, OfferStatus.ACCEPTED].includes(item.status)).map((item) => (
          <CampaignCard
            key={item.campaign.crn}
            campaign={item.campaign}
            address={item.hotel.organisationAddress}
            benefits={item.hotel.facilities}
            onClick={() => navigate(`/campaigns/my-campaigns/${item.campaign.crn}/${item.id}`)}
            isMyCampaign
            status={item.status}
            isRecentlyAdded={isRecentlyAdded(item.createdAt)}
          />
        ))}
      </div>
    </AppLayout>
  )
}
