import { Routes, Route } from 'react-router-dom'
import React from 'react'

import { LoginPage } from '../views/auth/LoginPage/LoginPage'
import { SignupPage } from '../views/auth/SignupPage/SignupPage'
import { ForgotPasswordPage } from '../views/auth/ForgotPasswordPage/ForgotPassword'
import { ResetPasswordPage } from '../views/auth/ResetPasswordPage/ResetPasswordPage'

export const AuthRoutes: React.FC = () => (
  <Routes>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/signup" element={<SignupPage />} />
    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
    <Route path='/reset-password/:token' element={<ResetPasswordPage />} />
  </Routes>
)
