import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import { useLoadScript } from '@react-google-maps/api'
import { MdLocationPin, MdOutlineCameraAlt } from 'react-icons/md'

import { CampaignActionMenu, CampaignActionMenuProps } from '../CampaignActionMenu/CampaignActionMenu'
import { CampaignTags } from '../CampaignTags/CampaignTags'
import { CampaignInterfaceProps, OfferStatus, offerStatusText } from '../../../../interfaces'
import { sanitizeHTML } from '../../../../utils/stringHelpers'
import { useCurrentUser } from '../../../../hooks'

import './CampaignCard.scss'

interface CampaignCardProps {
  campaign: CampaignInterfaceProps,
  address?: string,
  status?: OfferStatus,
  benefits?: string[],
  isMyCampaign?: boolean,
  onClick?: () => void,
  className?: string,
  isRecentlyAdded?: boolean,
  showActionMenu?: boolean,
  actionMenu?: CampaignActionMenuProps,
  isShowDistance?: boolean
}

export const CampaignCard: React.FC<CampaignCardProps> = ({
  campaign,
  address,
  status,
  benefits,
  isMyCampaign = false,
  onClick,
  className,
  isRecentlyAdded,
  showActionMenu = false,
  actionMenu,
  isShowDistance = false
}) => {
  const currentUser = useCurrentUser();
  const [distance, setDistance] = useState<string>('');
  const libraries = useMemo(() => ['places', 'geocoding', 'routes'], []);

  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
    libraries: libraries as any,
  });

  const calculateDistance = async () => {
    const hotelAddress = campaign?.hotel?.organisationAddress || address;
    if (isShowDistance && currentUser?.city && hotelAddress) {
      const service = new google.maps.DistanceMatrixService();

      await service.getDistanceMatrix(
        {
          origins: [currentUser?.city],
          destinations: [hotelAddress],
          travelMode: google.maps.TravelMode.DRIVING,
        }, (response, status) => {
          if (status === 'OK') {
            const distance = response?.rows[0]?.elements[0]?.distance?.text;

            return setDistance(distance ? `(${distance})` : '');
          }

          return setDistance('');
        });
    }
  }

  useEffect(() => {
    if (isLoaded && currentUser?.city && isShowDistance) {
      calculateDistance();
    }
  }, [isLoaded, currentUser, isShowDistance]);

  return (
    <div className={classNames("campaign-card-container", className)} onClick={!showActionMenu ? onClick : () => { }}>
      {isRecentlyAdded && (
        <div className="campaign-card-newlabel">
          <span>New</span>
        </div>
      )}

      <div className="campaign-card-image">
        <img src={campaign?.images[0]} alt="Penny hill park" />

        {campaign?.images?.length && (
          <div className="campaign-card-image-number">
            <span>
              <MdOutlineCameraAlt size={16} />
            </span>
            <span>{campaign?.images?.length}</span>
          </div>
        )}

        {isMyCampaign && (
          <div className={classNames("campaign-card-status", status)}>
            {offerStatusText[status]}
          </div>
        )}
      </div>

      <div className="campaign-card-info">
        <h3 className={classNames("campaign-card-info-hotel-name",
          { 'hasRightData': showActionMenu || campaign?.instagramRequirements || campaign?.tiktokRequirements }
        )}>
          {campaign.name}
        </h3>
        <div className="campaign-card-info-campaign-name">{campaign?.targetAudience}</div>
        <HTMLEllipsis className="campaign-card-info-desc" unsafeHTML={sanitizeHTML(campaign.description)} maxLine='5' ellipsis='...' />
        {campaign?.instagramRequirements && (
          <p className="campaign-card-info-requirements">{campaign?.instagramRequirementsDescription}</p>
        )}
        {campaign?.tiktokRequirements && (
          <p className="campaign-card-info-requirements">{campaign?.tiktokRequirementsDescription}</p>
        )}
        <div className="campaign-card-info-tags-container">
          <CampaignTags tags={(campaign?.keywords || []).slice(0, 3)} />
          <CampaignTags type="benefit" tags={(benefits || []).slice(0, 3)} />
        </div>
        <p className="campaign-card-info-location">
          <MdLocationPin size={16} style={{ position: 'relative', top: 3 }} />
          <span>{campaign?.hotel?.organisationAddress || address} {distance}</span>
        </p>
      </div>

      {showActionMenu ? (
        <div className="campaign-card-actions">
          <CampaignActionMenu {...actionMenu} />
        </div>
      ) : (
        <div className="campaign-card-social-icons">
          {campaign?.instagramRequirements && <img
            className="small-icon"
            src="/images/instagram-icon.png"
            alt="Instagram"
          />}
          {campaign?.tiktokRequirements && <img
            className="small-icon"
            src="/images/tiktok-icon.png"
            alt="Tik Tok"
          />}
        </div>
      )}
    </div>
  )
}