import React from 'react'

import { Tags, Calendar } from '../../../elements'
import { MdLocationOn } from 'react-icons/md'
import { UserInterfaceProps } from '../../../../interfaces'

import './ProfileInfo.scss'

const TODAY_TIMESTAMP = new Date().getTime()

interface ProfileInfoProps {
  userInfo?: UserInterfaceProps;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({
  userInfo
}) => {

  const tileClassName = ({ date, view }) => {
    return (userInfo?.availableDates || []).some((item) => new Date(item).getTime() === date.getTime() && new Date(item).getTime() > TODAY_TIMESTAMP) ?
      'profile-calendar-tile profile-calendar-tile-available' :
      'profile-calendar-tile'
  }

  return (
    <div className="profile-info">
      <div className="profile-info-header">
        {userInfo?.avatar ? <img
          className="profile-info-header-image"
          src={userInfo?.avatar}
          alt="My Profile"
        /> : (
          <div className="profile-info-header-no-image">
            No Profile Image
          </div>
        )}

        <div className="profile-info-header-detail">
          <div className="profile-info-header-detail-name">{userInfo?.name}</div>
          <div className="profile-info-header-detail-address">
            <MdLocationOn size={16} className="location-icon" />
            Town/City: {userInfo?.city}
          </div>
        </div>
      </div>

      {userInfo?.bio && (<div className="profile-info-body">
        <h3 className="profile-info-body-label">About me</h3>
        <p className="profile-info-body-bio">
          {userInfo?.bio}
        </p>
      </div>)}

      {userInfo?.tags && (<div className="profile-info-interest">
        <h3 className="profile-info-interest-label">My interests</h3>
        <div className="profile-info-interest-tags">
          <Tags size="lg" tags={userInfo?.tags} />
        </div>
      </div>)}

      <div className="profile-info-channel">
        <h3 className="profile-info-channel-label">Channels</h3>
        <div className="profile-info-channel-container">
          <div className="profile-info-channel-item">
            <span></span>
            <span>Followers</span>
            <span>Engagement</span>
            <span>Rating</span>
          </div>
          <div className="profile-info-channel-item data">
            <img
              src="/images/instagram-icon.png"
              alt="Instagram"
            />
            <span>{userInfo?.instagramFollowers || '-'}</span>
            <span>{userInfo?.instagramEngagement && userInfo.instagramEngagement !== '-' ? `${userInfo.instagramEngagement}%` : '-'}</span>
            <span>{userInfo?.instagramRating || '-'}</span>
          </div>
          <div className="profile-info-channel-item data">
            <img
              src="/images/tiktok-icon.png"
              alt="Tiktok"
            />
            <span>{userInfo?.tiktokFollowers || '-'}</span>
            <span>{userInfo?.tiktokEngagement && userInfo?.tiktokEngagement !== '-' ? `${userInfo.tiktokEngagement}%` : '-'}</span>
            <span>{userInfo?.tiktokRating || '-'}</span>
          </div>
          <div className="profile-info-channel-item data">
            <img
              src="/images/youtube-icon.png"
              alt="Youtube"
            />
            <span>{userInfo?.youtubeFollowers || '-'}</span>
            <span>{userInfo?.youtubeEngagement && userInfo?.youtubeEngagement !== '-' ? `${userInfo.youtubeEngagement}%` : '-'}</span>
            <span>{userInfo?.youtubeRating || '-'}</span>
          </div>
        </div>
      </div>
      <div className="profile-info-calendar">
        <Calendar
          className="profile-info-calendar-container"
          minDate={new Date()}
          tileClassName={tileClassName}
        />
      </div>
    </div>
  )
}
