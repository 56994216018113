import { createSlice } from "@reduxjs/toolkit";
import { createHotel, deleteHotel, fetchHotels, getHotelByHrn, getHotelById, updateHotel } from "./hotelAction";
import { HotelInterfaceProps } from "../../../interfaces";

const initialState: HotelState = {
  loading: false,
  error: null,
  hotels: null,
  hotel: null,
  total: 0,
  page: 1,
  limit: 10,
}

export const hotelSlice = createSlice({
  name: 'hotel',
  initialState,
  reducers: {
    resetCurrentHotel: (state) => {
      state.hotel = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHotels.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchHotels.fulfilled, (state, { payload }) => {
      state.loading = false
      state.hotels = payload.data || []
      state.total = payload.total
      state.page = payload.page
      state.limit = payload.limit
    })
    builder.addCase(fetchHotels.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(createHotel.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createHotel.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    builder.addCase(createHotel.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(updateHotel.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateHotel.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    builder.addCase(updateHotel.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(deleteHotel.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(deleteHotel.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    builder.addCase(deleteHotel.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(getHotelById.pending, (state) => {
      state.loading = true
      state.error = null
      state.hotel = null
    })
    builder.addCase(getHotelById.fulfilled, (state, { payload }) => {
      state.loading = false
      state.hotel = payload.data
    })
    builder.addCase(getHotelById.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })

    builder.addCase(getHotelByHrn.pending, (state) => {
      state.loading = true
      state.error = null
      state.hotel = null
    })
    builder.addCase(getHotelByHrn.fulfilled, (state, { payload }) => {
      state.loading = false
      state.hotel = payload.data
    })
    builder.addCase(getHotelByHrn.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
  }
});

export interface HotelState {
  loading: boolean;
  error: string | null;
  hotels: HotelInterfaceProps[] | null;
  hotel: HotelInterfaceProps | null;
  total: number;
  page: number;
  limit: number;
}

export const { resetCurrentHotel } = hotelSlice.actions;