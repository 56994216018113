import React from 'react';
import { MdLocationPin, MdOutlineCameraAlt } from 'react-icons/md';
import { Tags } from '../../../elements/Tags/Tags';

import './HotelCard.scss';

interface HotelCardProps {
  name: string,
  image: string,
  description: string,
  classification: string,
  address: string,
  tags: Array<string>,
  numberOfImages?: number,
  onClick?: () => void,
  estimateDistance?: string,
  isNew?: boolean
}

export const HotelCard: React.FC<HotelCardProps> = ({
  name,
  image,
  description,
  classification,
  address,
  tags,
  numberOfImages,
  onClick,
  estimateDistance,
  isNew
}) => {
  return (
    <div className="hotel-card-container" onClick={onClick}>
      {isNew && (
        <div className="hotel-card-newlabel">
          <span>New</span>
        </div>
      )}

      <div className="hotel-card-image">
        <img src={image} alt={name} />

        {numberOfImages && (
          <div className="hotel-card-image-number">
            <span>
              <MdOutlineCameraAlt size={16} />
            </span>
            <span>{numberOfImages}</span>
          </div>
        )}
      </div>

      <div className="hotel-card-info">
        <h3 className="hotel-card-info-name">{name}</h3>
        <div className="hotel-card-info-classification">{classification}</div>
        <p className="hotel-card-info-desc">{description}</p>
        <div className="hotel-card-info-tags-container">
          <Tags size="sm" tags={(tags || []).slice(0, 4)} />
        </div>
        <p className="hotel-card-info-location">
          <MdLocationPin size={16} style={{ position: 'relative', top: 3 }} />
          <span>{address} {estimateDistance ? `(${estimateDistance})` : null}</span>
        </p>
      </div>
    </div>
  );
}