import { createAppAsyncThunk } from "../../createAppAsyncThunk";
import { CampaignInterfaceProps } from "../../../interfaces/campaignInterface";
import { getAxiosInstance } from "../../../utils/axiosHelpers";

export const fetchCampaigns = createAppAsyncThunk(
  'campaign/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/campaigns`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const createCampaign = createAppAsyncThunk(
  'campaign/create',
  async (campaign: CampaignInterfaceProps, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.post(`/campaigns`, campaign);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateCampaign = createAppAsyncThunk(
  'campaign/update',
  async (campaign: CampaignInterfaceProps, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/campaigns/${campaign.id}`, campaign);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteCampaign = createAppAsyncThunk(
  'campaign/delete',
  async (id: number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.delete(`/campaigns/${id}`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const cancelCampaign = createAppAsyncThunk(
  'campaign/cancel',
  async (id: number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/campaigns/${id}/cancel`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getCampaignDetailByCrn = createAppAsyncThunk(
  'campaign/getDetailByCrn',
  async (crn: string, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/campaign/crn/${crn}`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getCampaignByHotelId = createAppAsyncThunk(
  'campaign/getByHotelId',
  async (hotelId: number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/campaigns/hotel/${hotelId}`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getCampaignByHotelOwnerId = createAppAsyncThunk(
  'campaign/getByHotelOwnerId',
  async (hotelOwnerId: number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/campaigns/owner/${hotelOwnerId}`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)