import React from "react";
import classNames from "classnames";
import { UserInterfaceProps } from "../../../../interfaces";
import { Modal, ModalProps, Tab, Tags } from "../../../elements";
import { useWindowSize } from "../../../../hooks";

import './UserViewModal.scss';

interface UserViewModalProps extends ModalProps {
  user: UserInterfaceProps;
}

export const UserViewModal: React.FC<UserViewModalProps> = ({
  user,
  open,
  onClose,
}: UserViewModalProps) => {
  const { isMobile } = useWindowSize();

  return (
    <Modal open={open} onClose={onClose} className={classNames({ 'user-view-modal-container': !isMobile, 'user-view-modal-container-mobile': isMobile })}>
      <div className="user-view-modal-wrapper">
        <div className="user-view-modal-image">
          <img src={user?.avatar || '/images/default_profile_image.jpg'} alt="Avatar" />
        </div>
        <div className="user-view-modal-info-container">
          <div className="user-view-modal-basic">
            <div className="user-view-modal-name">{user?.name}</div>
            <div className="user-view-modal-city">{user?.city}</div>
            <div className="user-view-modal-bio" >{user?.bio}</div>
            <div className="user-view-modal-bio" >Interests  <Tags tags={user.tags ?? []} /></div>
          </div>
          <div className="user-view-modal-advance">
            <Tab
              position="center"
              panes={[
                {
                  id: 'instagram',
                  title: 'Instagram',
                  element: (
                    user?.instagram ? <div className="user-view-modal-social">
                      <div className="user-view-modal-social-link"><strong>Link:</strong> <a href={user?.instagram} target="_blank" rel="noreferrer">{user?.instagram}</a></div>
                      <div className="user-view-modal-social-follower"><strong>Followers:</strong> {user?.instagramFollowers}</div>
                      <div className="user-view-modal-social-engagement"><strong>Engagement:</strong> {user?.instagramEngagement}</div>
                      <div className="user-view-modal-social-rating"><strong>Rating:</strong> {user?.instagramRating}</div>
                    </div> : <div className="user-view-modal-social">No Instagram</div>
                  )
                },
                {
                  id: 'youtube',
                  title: 'Youtube',
                  element: (
                    user?.youtube ? <div className="user-view-modal-social">
                      <div className="user-view-modal-social-link"><strong>Link:</strong> <a href={user?.youtube} target="_blank" rel="noreferrer">{user?.youtube}</a></div>
                      <div className="user-view-modal-social-follower"><strong>Followers:</strong> {user?.youtubeFollowers}</div>
                      <div className="user-view-modal-social-engagement"><strong>Engagement:</strong> {user?.youtubeEngagement}</div>
                      <div className="user-view-modal-social-rating"><strong>Rating:</strong> {user?.youtubeRating}</div>
                    </div> : <div className="user-view-modal-social">No Youtube</div>
                  )
                },
                {
                  id: 'tiktok',
                  title: 'Tiktok',
                  element: (
                    user?.tiktok ? <div className="user-view-modal-social">
                      <div className="user-view-modal-social-link"><strong>Link:</strong> <a href={user?.tiktok} target="_blank" rel="noreferrer">{user?.tiktok}</a></div>
                      <div className="user-view-modal-social-follower"><strong>Followers:</strong> {user?.tiktokFollowers}</div>
                      <div className="user-view-modal-social-engagement"><strong>Engagement:</strong> {user?.tiktokEngagement}</div>
                      <div className="user-view-modal-social-rating"><strong>Rating:</strong> {user?.tiktokRating}</div>
                    </div> : <div className="user-view-modal-social">No Tiktok</div>
                  )
                },
              ]}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
