import { createSlice } from '@reduxjs/toolkit'
import { sendFeedback } from './feedbackAction'

const initialState: FeedbackState = {
  createFeedbackLoading: false,
  createFeedbackError: null,
  createFeedbackSuccess: false,
}

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendFeedback.pending, (state) => {
        state.createFeedbackLoading = true
        state.createFeedbackError = null
        state.createFeedbackSuccess = false
      })
      .addCase(sendFeedback.fulfilled, (state, { payload }) => {
        state.createFeedbackLoading = false
        state.createFeedbackError = null
        state.createFeedbackSuccess = true
      })
      .addCase(sendFeedback.rejected, (state, action) => {
        state.createFeedbackLoading = false
        state.createFeedbackError = action.payload
        state.createFeedbackSuccess = false
      })
  },
})

export interface FeedbackState {
  createFeedbackLoading: boolean
  createFeedbackError: string | null
  createFeedbackSuccess: boolean
}

export const {} = feedbackSlice.actions
