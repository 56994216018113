import React from "react"
import { MessageInterfaceProps, UserRoles } from "../../../../interfaces"
import { HiReply } from "react-icons/hi"
import { MdAttachFile, MdDeleteOutline } from "react-icons/md"
import { formatDate } from "../../../../utils/timeHelpers"
import { sanitizeHTML } from "../../../../utils/stringHelpers"

import './MessageCard.scss'

interface MessageCardProps {
  message: MessageInterfaceProps;
  isOwnMessage?: boolean;
  onReply?: (message: MessageInterfaceProps) => void
  onDelete?: (message: MessageInterfaceProps) => void
}

export const MessageCard: React.FC<MessageCardProps> = ({
  message,
  isOwnMessage = false,
  onReply,
  onDelete
}) => {
  const isHotelSender = message.sender.role === UserRoles.HOTEL_MANAGER;
  const isAdminSender = message.sender.role === UserRoles.ADMIN;
  const recipientName = isHotelSender || isAdminSender ? message.recipient.name : message?.campaign?.name;
  const senderName = isHotelSender ? message?.campaign?.name : message.sender.name;

  return (
    <div className="message-card-container" role="button">
      <div className="message-header">
        {isOwnMessage ?
          <h3 className="message-header-title">To: {recipientName} </h3> :
          <h3 className="message-header-title">From: {senderName}</h3>
        }
        <span className="message-header-subject">{message.title}</span>
      </div>

      <div className="message-body">
        <div className="message-body-date">
          <span>Received:</span> {formatDate(message.createdAt)}
        </div>

        <div className="message-body-description" dangerouslySetInnerHTML={{ __html: sanitizeHTML(message.message) }} ></div>
      </div>

      {message?.attachments?.length ? <div className="message-attachments">
        {message.attachments?.map((attachment, index) => (
          <div className="message-attachment" key={index}>
            <div className="message-attachment-icon">
              <MdAttachFile size={18} />
            </div>
            <a href={attachment} target="_blank" rel="noreferrer" className="message-attachment-name">{decodeURI(attachment.split('/').pop() as string)}</a>
          </div>
        ))}
      </div> : null}

      <div className="message-actions">
        {!isOwnMessage && <button className="message-actions-button" onClick={() => onReply(message)}>
          <HiReply size={18} />
        </button>}
        {message?.attachments?.length ? <button className="message-actions-button">
          <MdAttachFile size={18} />
        </button> : null}
        <button className="message-actions-button" onClick={() => onDelete(message)}>
          <MdDeleteOutline size={20} />
        </button>
      </div>
    </div>
  )
}