import axios from "axios";

export const uploadImageToS3 = async (file: any) => {
  try {
    const fetchImg = await fetch(file).then((res) => res.blob());

    const finalFile = await new File([fetchImg], 'capture.png', {
      type: 'image/png'
    });

    const formData = new FormData();
    formData.append('file', finalFile);

    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/file/upload`, {
      file: formData.get('file')
    },
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

    const data = await response.data;
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export const uploadFileToS3 = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/file/basic/upload`, {
      file: formData.get('file')
    },
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

    const data = await response.data;
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
}