import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RegisterOptions, useForm, SubmitHandler } from 'react-hook-form'

import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { useCurrentUser } from '../../../../hooks/useAuth'

import { AppLayout } from '../../../layout'
import { Modal, Tags, Input, ImageUpload, Calendar } from '../../../elements'
import { UserInterfaceProps } from '../../../../interfaces'

import { uploadImageToS3 } from '../../../../utils/fileHelpers'
import { updateUserProfile } from '../../../../redux'
import { useLoading } from '../../../../hooks'
import { SQUARE_ASPECT_RATIO } from '../../../../utils/types'

import './EditProfilePage.scss'

export const EditProfilePage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const currentUser = useCurrentUser()
  const { setLoading } = useLoading()
  const [selectedFile, setSelectedFile] = useState()
  const [tags, setTags] = useState<string[]>(currentUser?.tags || [])
  const [tagModalVisible, setTagModalVisible] = useState(false)
  const [selectedDates, setSelectedDates] = useState<Date[]>(
    currentUser?.availableDates || []
  )
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<UserInterfaceProps>({
    mode: 'onChange',
    delayError: 200,
    defaultValues: {
      ...currentUser
    }
  })

  const configs = useAppSelector((state) => state.config.configs)
  const interestConfig =
    configs?.find((config) => config.type === 'Interests')?.content || []

  const submitNewTag = (tag: string) => {
    setTagModalVisible(false)
    setTags((prev) => {
      return [...prev, tag]
    })
  }

  const removeTag = (tag: string) => {
    setTags((prev) => {
      return prev.filter((item) => item !== tag)
    })
  }

  const handleDateSelection = (date: Date) => {
    setSelectedDates((prev) => {
      if (prev.some((pdate) => new Date(pdate).getTime() === date.getTime())) {
        return prev.filter((pdate) => new Date(pdate).getTime() !== date.getTime())
      } else {
        return [...prev, date]
      }
    })
  }

  const tileClassName = ({ date, view }) => {
    return selectedDates.some((item) => new Date(item).getTime() === date.getTime())
      ? 'profile-calendar-tile profile-calendar-tile-available'
      : 'profile-calendar-tile'
  }

  const registerOptions = {
    name: (): RegisterOptions => {
      return { required: 'Name is required' }
    }
  }

  const onFormSubmit: SubmitHandler<UserInterfaceProps> = async (data) => {
    try {
      setLoading(true)
      const imageResponse = selectedFile ? await uploadImageToS3(selectedFile) : {}

      const updatedData = {
        ...data,
        tags,
        avatar: imageResponse?.data?.location || currentUser?.avatar || '',
        availableDates: selectedDates
      }

      await dispatch(updateUserProfile(updatedData))

      setLoading(false)
      navigate(`/profile/${currentUser?.id}`)
    } catch (error) {
      setLoading(false)
    }
  }

  const submitButton = (
    <button
      onClick={handleSubmit(onFormSubmit)}
      className="profile-submit-button"
      disabled={!isValid}>
      Done
    </button>
  )

  return (
    <AppLayout backButtonVisible title="Edit Profile" headerChildren={submitButton}>
      <div className="profile-container">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <ImageUpload
            className="profile-image-upload"
            aspect={SQUARE_ASPECT_RATIO}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            currentImage={currentUser?.avatar}
            isProfileImage
          />
          <div className="form-group">
            <label htmlFor="profileName">Name</label>
            <div className="form-group-content">
              <input
                type="text"
                name="name"
                id="profileName"
                {...register('name', registerOptions.name())}
              />
              {errors.name && (
                <span className="error-message">{errors.name.message}</span>
              )}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="profileAddress">Town/City</label>
            <input type="text" name="address" id="profileAddress" {...register('city')} />
          </div>
          <div className="form-group">
            <label htmlFor="profileBio">My bio</label>
            <textarea name="bio" id="profileBio" {...register('bio')} />
          </div>
          {currentUser.role === 'influencer' && (
            <div className="form-group">
              <div className="interest-section">
                <label htmlFor="profileInterests">My interests</label>
                <div className="interest-form-field">
                  <Tags tags={tags} onClick={removeTag}>
                    <button
                      className="tags-item"
                      type="button"
                      onClick={() => setTagModalVisible(true)}>
                      +
                    </button>
                  </Tags>
                </div>
              </div>
            </div>
          )}

          <div className="form-group">
            <div className="channel-section">
              <h3 className="section-heading">My channels</h3>

              <div className="channel-form-field">
                <img src="/images/instagram-icon.png" alt="Instagram" />
                <Input
                  type="text"
                  placeholder="https://www.instagram.com"
                  name="instagram"
                  formRegister={register('instagram')}
                />
                <button type="button">Upload</button>
              </div>

              <div className="channel-form-field">
                <img src="/images/tiktok-icon.png" alt="Tik Tok" />
                <Input
                  type="text"
                  placeholder="https://www.tiktok.com"
                  name="tiktok"
                  formRegister={register('tiktok')}
                />
                <button type="button">Upload</button>
              </div>

              <div className="channel-form-field">
                <img src="/images/youtube-icon.png" alt="Youtube" />
                <Input
                  type="text"
                  placeholder="https://www.youtube.com"
                  name="youtube"
                  formRegister={register('youtube')}
                />
                <button type="button">Upload</button>
              </div>
            </div>
          </div>

          <div className="form-group">
            <Calendar
              className="profile-calendar-section"
              minDate={new Date()}
              tileClassName={tileClassName}
              onClickDay={handleDateSelection}
            />
          </div>
        </form>
      </div>

      <Modal open={tagModalVisible} onClose={() => setTagModalVisible(false)}>
        <div className="tag-modal-container">
          <h3 className="tag-modal-heading">Available Tags</h3>
          <div className="tag-modal-list">
            <Tags
              tags={interestConfig.filter((tag) => !tags.includes(tag))}
              onClick={submitNewTag}
            />
          </div>
        </div>
      </Modal>
    </AppLayout>
  )
}
