import {
  MdOutlineAccountBox,
  MdOutlinePassword,
  MdOutlineSupportAgent,
  MdOutlineFeedback,
  MdOutlineContactPhone,
  MdOutlineQuestionMark,
  MdOutlineLogout,
  MdOutlineStarRate,
  MdOutlineShare,
  MdOutlineSecurity,
} from 'react-icons/md'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { AppLayout } from '../../layout'
import { PasswordChangeModal } from '../../common'
import { useCurrentUser } from '../../../hooks'

import './SettingsPage.scss'

export const SettingsPage: React.FC = () => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  const [cookie, setCookie, removeCookie] = useCookies(['accessToken'])
  const [passwordChangeModalVisible, setPasswordChangeModalVisible] =
    useState(false)

  const onLogout = () => {
    localStorage.removeItem('userInfo')
    cookie.accessToken && removeCookie('accessToken', { path: '/' })
  }

  const onPasswordChange = () => {
    setPasswordChangeModalVisible(true)
  }

  return (
    <AppLayout title="Settings" backButtonVisible>
      <div className="settings-container">
        <div className="settings-list-container">
          <div className="settings-list">
            <SettingsListItem
              icon={<MdOutlineAccountBox size={20} />}
              title="Account"
              onClick={() => navigate(`/profile/${currentUser?.id}`)}
            />
            <SettingsListItem
              icon={<MdOutlinePassword size={20} />}
              title="Change Password"
              onClick={() => onPasswordChange()}
            />
            <SettingsListItem
              icon={<MdOutlineSecurity size={20} />}
              title="Two-Step Verification"
              onClick={() => navigate('/profile/multi-factor')}
            />
          </div>

          <div className="settings-list">
            <SettingsListItem
              icon={<MdOutlineSupportAgent size={20} />}
              title="Support"
              onClick={() => navigate('/support')}
            />
            <SettingsListItem
              icon={<MdOutlineFeedback size={20} />}
              title="Feedback"
              onClick={() => navigate('/feedback')}
            />
            <SettingsListItem
              icon={<MdOutlineContactPhone size={20} />}
              title="Contact Us"
              onClick={() => navigate('/contact')}
            />
            <SettingsListItem
              icon={<MdOutlineQuestionMark size={20} />}
              title="FAQ"
              onClick={() => navigate('/faq')}
            />
          </div>

          {/* <div className="settings-list">
            <SettingsListItem
              icon={<MdOutlineStarRate size={20} />}
              title="Rate Us"
            />
            <SettingsListItem
              icon={<MdOutlineShare size={20} />}
              title="Share"
            />
          </div> */}

          <div className="settings-list">
            <SettingsListItem
              icon={<MdOutlineLogout size={20} />}
              title="Logout"
              onClick={() => onLogout()}
            />
          </div>
        </div>

        <PasswordChangeModal
          open={passwordChangeModalVisible}
          onClose={() => setPasswordChangeModalVisible(false)}
          onSuccess={() => onLogout()}
        />
      </div>
    </AppLayout>
  )
}

// MARK: - SettingsListItem

interface SettingsListItemProps {
  icon: React.ReactNode
  title: string
  onClick?: () => void
}

const SettingsListItem: React.FC<SettingsListItemProps> = ({
  icon,
  title,
  onClick,
}) => (
  <div className="settings-list-item" onClick={onClick} role="button">
    <div className="settings-list-item-icon">{icon}</div>

    <div className="settings-list-item-title">{title}</div>
  </div>
)
