import { createAppAsyncThunk } from "../../createAppAsyncThunk";
import { getAxiosInstance } from "../../../utils/axiosHelpers";

export const fetchUsers = createAppAsyncThunk(
  'users/fetch',
  async (params: { page: number; limit: number }, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/users`, { params });

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const banUser = createAppAsyncThunk(
  'users/ban',
  async (id: number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/users/${id}/ban`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const unbanUser = createAppAsyncThunk(
  'users/unban',
  async (id: number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/users/${id}/unban`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteUser = createAppAsyncThunk(
  'users/delete',
  async (id: number, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/users/${id}/delete`);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const getInfluencers = createAppAsyncThunk(
  'users/influencers',
  async (params: any, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.get(`/influencers`, { params });

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const createUserByAdmin = createAppAsyncThunk(
  'users/createByAdmin',
  async (data: any, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.post(`/users`, data);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateUserByAdmin = createAppAsyncThunk(
  'users/updateByAdmin',
  async (data: any, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance();
      const response = await instance.put(`/users/${data.id}/admin-update`, data);

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)