import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { CampaignInterfaceProps, OfferInterfaceProps, OfferStatus, UserInterfaceProps, offerStatusText } from "../../../../interfaces";
import { ColumnDef } from "@tanstack/react-table";
import { Button, Modal, ModalProps, Tab, Table } from "../../../elements";
import { IoSend } from "react-icons/io5";
import { MdOutlineCancel, MdOutlineCheck, MdOutlineClose, MdOutlineRemoveRedEye } from "react-icons/md";
import { formatDate } from "../../../../utils/timeHelpers";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { UserViewModal } from "../../user";

import "./ManageOfferModal.scss";
import { MessageCreateModal } from "../../message";

interface ManageOfferModalProps extends ModalProps {
  campaign: CampaignInterfaceProps;
  offers: OfferInterfaceProps[];
  users: UserInterfaceProps[];
  className?: string;
  onSendOffer?: (campaign: CampaignInterfaceProps, user: UserInterfaceProps) => Promise<void>;
  onAcceptOffer?: (offer: OfferInterfaceProps) => Promise<void>;
  onRejectOffer?: (offer: OfferInterfaceProps) => Promise<void>;
  onCanceledOffer?: (offer: OfferInterfaceProps) => Promise<void>;
}

export const ManageOfferModal: React.FC<ManageOfferModalProps> = ({
  campaign,
  offers,
  users,
  className,
  open,
  onClose,
  onSendOffer,
  onAcceptOffer,
  onRejectOffer,
  onCanceledOffer,
}) => {
  const { isMobile } = useWindowSize();
  const [availableUsers, setAvailableUsers] = useState<UserInterfaceProps[]>(users || []);
  const [selectedUser, setSelectedUser] = useState<UserInterfaceProps | null>(null);
  const [isUserViewModalVisible, setIsUserViewModalVisible] = useState(false);
  const [isSendMessageModalVisible, setIsSendMessageModalVisible] = useState(false);

  useEffect(() => {
    if (!offers || !users) return;

    const filteredUsers = users.filter(user => {
      const haveOffer = offers.some(offer => offer.relatedInfluencerId === user.id);
      const banned = user.banned;
      const deleted = user.deleted;

      return !haveOffer && !banned && !deleted;
    });

    setAvailableUsers(filteredUsers);
  }, [offers, users]);

  const onShowUserDetails = (user: UserInterfaceProps) => {
    setSelectedUser(user);
    setIsUserViewModalVisible(true);
  }

  const onCloseUserDetails = () => {
    setSelectedUser(null);
    setIsUserViewModalVisible(false);
  }

  const onSendingOffer = (user: UserInterfaceProps) => {
    setSelectedUser(user);
    setIsSendMessageModalVisible(true);
  }

  const influencerTableColumns = useMemo<ColumnDef<UserInterfaceProps>[]>(() => [
    ...(!isMobile ? [
      {
        header: 'Id',
        accessorKey: 'id',
      }
    ] : []),
    {
      header: 'Name',
      accessorKey: 'name',
    },
    ...(!isMobile ? [
      {
        header: 'Bio',
        accessorKey: 'bio',
      }
    ] : []),
    {
      header: 'City',
      accessorKey: 'city',
    },
    {
      header: 'Actions',
      cell: ({ row }) => (
        <div className='influencer-actions'>
          <Button variant={'default'} title="User details" onClick={() => onShowUserDetails(row.original)}>
            <MdOutlineRemoveRedEye />
          </Button>
          <Button variant={'primary'} onClick={() => onSendingOffer(row.original)} title="Send offer">
            <IoSend />
          </Button>
        </div>
      ),
    }
  ], [isMobile]);

  const offerTableColumns = useMemo<ColumnDef<OfferInterfaceProps>[]>(() => [
    {
      header: 'Name',
      accessorKey: 'user.name',
      meta: {
        columnClassName: isMobile ? 'sticky left' : '',
      }
    },
    {
      header: 'Selected Dates',
      accessorKey: 'selectedCampaignDates',
      cell: ({ getValue }) => (
        <ul className="offer-dates">
          {(getValue<Date[]>() || []).map((date, index) => (
            <li key={index}>{formatDate(date)}</li>
          ))}
        </ul>
      ),
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: ({ getValue }) => (
        <span className={classNames("offer-status", getValue<string>())}>{offerStatusText[getValue<string>()]}</span>
      ),
    },
    {
      header: 'Invited By Hotel',
      accessorKey: 'isOfferSentByHotel',
      cell: ({ getValue }) => (
        <span>{getValue<boolean>() ? 'Yes' : 'No'}</span>
      ),
    },
    {
      header: 'TikTok Rating',
      accessorKey: 'user.tiktokRating',
    },
    {
      header: 'Instagram Rating',
      accessorKey: 'user.instagramRating',
    },
    {
      header: 'Youtube Rating',
      accessorKey: 'user.youtubeRating',
    },
    {
      header: 'Actions',
      cell: ({ row }) => (
        <div className="offer-actions">
          <Button variant={'default'} title="User details" onClick={() => onShowUserDetails(row.original.user)}>
            <MdOutlineRemoveRedEye />
          </Button>
          {row.original.status === OfferStatus.PENDING && !row.original.isOfferSentByHotel ? (
            <>
              <Button variant={'primary'} onClick={() => onAcceptOffer(row.original)} title="Accept offer">
                <MdOutlineCheck />
              </Button>
              <Button variant={'danger'} onClick={() => onRejectOffer(row.original)} title="Reject offer">
                <MdOutlineClose />
              </Button>
            </>
          ) : (
            <Button variant={'danger'} className="cancel-button" disabled={row.original.status === OfferStatus.CANCELLED} onClick={() => onCanceledOffer(row.original)} title="Cancel offer">
              <MdOutlineCancel />
            </Button>
          )}
        </div>
      ),
      meta: {
        columnClassName: isMobile ? 'sticky right' : '',
      }
    }
  ], [isMobile]);

  return (
    <Modal open={open} onClose={onClose} className={classNames({ 'manage-offer-modal': !isMobile }, className)}>
      <div className="manage-offer-modal-container">
        <h3 className="manage-offer-modal-heading">
          Manage Offers
        </h3>

        <Tab
          position="left"
          panes={[
            {
              id: 'influencers',
              title: 'Influencers',
              element: (
                <div className="manage-offer-modal-influencers">
                  <Table columns={influencerTableColumns} data={availableUsers} />
                </div>
              ),
            },
            {
              id: 'offers',
              title: 'Offers',
              element: (
                <div className="manage-offer-modal-offers">
                  {offers && offers.length ? <Table columns={offerTableColumns} data={offers} /> : (
                    <div className="manage-offer-modal-offers-empty">
                      No offers found
                    </div>
                  )}
                </div>
              ),
            }
          ]}
        />
      </div>

      {isUserViewModalVisible && selectedUser && (
        <UserViewModal
          open={isUserViewModalVisible}
          onClose={() => onCloseUserDetails()}
          user={selectedUser}
        />
      )}

      {isSendMessageModalVisible && selectedUser && (
        <MessageCreateModal
          open={isSendMessageModalVisible}
          onClose={() => setIsSendMessageModalVisible(false)}
          onSubmitted={() => onSendOffer(campaign, selectedUser)}
          recipientId={selectedUser.id}
          campaign={campaign}
          isOffer
        />
      )}
    </Modal>
  );
}