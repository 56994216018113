import React from 'react'

import { AppLayout } from '../../layout'
import { useBodyClassName } from '../../../hooks/useBodyClassName'

import './FAQPage.scss'

const faqs = [
  {
    question: 'How do I get paid?',
    answer:
      'The referrals that you influence will drive the commission amount which will be based on the percentage specified in the campaign. Once the campaign has ended you will need to provide your bank account details. Payments will then be sent directly to your bank account.',
  },
  {
    question: 'When will I get paid?',
    answer:
      'You will start to be paid commission at the end of the month in which your campaign expires. You will then get paid at the end of each month until the last guest you have referred has checked out. ',
  },
  {
    question: 'How much will I get paid?',
    answer:
      'The campaign terms & conditions will specify the commission rate.  So, for each booking made using your promotion code you will receive the specified commission rate on the revenue it generates.',
  },
  {
    question: 'Will my followers get a discount?',
    answer:
      'The discount value of the promotion code varies by campaign. Be sure to check the campaign terms and conditions to understand the level of discount available to your audience for each campaign you apply for.',
  },
  {
    question: 'How many followers do I need and do I need a business account?',
    answer:
      'In order to carry out any campaign on the VOi platform you will need a business account on the relevant platform chosen for that campaign (eg. Instagram).  As a rule of thumb you need 10k or above.',
  },
  {
    question: 'Do I need to be resident in the UK?',
    answer:
      'You do not need to be resident in the UK, however, to receive commissions to an overseas bank account may attract international charges which would be deducted from the balance.',
  },
  {
    question: 'Will my transport be covered?',
    answer:
      'Transport is not usually included unless explicitly referred to in the campaign description.  You will be expected to cover all transport costs to and from the hotel/property.',
  },
  {
    question: 'Can I take my dog?',
    answer:
      'Unless mentioned in the campaign description, dogs are not permitted.',
  },
  {
    question: 'Can I negotiate the offer?',
    answer:
      'The platform presents the campaign that is being offered by the hotel, these terms are not negotiable. If there are no campaigns that appeal to you, please let us know in our feedback page.',
  },
  {
    question: 'What if can’t make the agreed date?',
    answer: (
      <>
        If for an unforeseen reason you are not able to make the agreed date you
        will need to contact the hotel via the message section as soon as
        possible. You should also contact VOi at{' '}
        <a href="mailto:info@voice-of-influence.co.uk">
          info@voice-of-influence.co.uk
        </a>
      </>
    ),
  },
  {
    question: 'How is my booking confirmed?',
    answer:
      'You will receive a message on the platform, and the campaign that has been offered to you will appear in “My Offers” tab on the app.',
  },
  {
    question: 'Is food included in my stay?',
    answer:
      'Some campaigns will include food which will be mentioned in the campaign description. ',
  },
  {
    question: 'How many stays can I have using this app?',
    answer:
      'You are currently able to apply for as many campaigns as you wish. The hotels will decide if you are a suitable fit and confirm or reject applications accordingly.',
  },
  {
    question: 'Can I bring a friend/partner?',
    answer:
      'Usually stays are offered to the influencer +1 but occupying 1 room.  The campaign description and terms will specify the room occupancy for the influencer stay.',
  },
]

export const FAQPage: React.FC = () => {
  useBodyClassName("bg-white");

  return (
    <AppLayout backButtonVisible title="FAQs">
      <div className="faq-container">
        {faqs.map((faq, i) => (
          <div key={`FAQ - ${i}`}>
            <p className="faq-question">{faq.question}</p>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
    </AppLayout>
  )
}
