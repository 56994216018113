import { useEffect, useState } from "react";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    isMobile: false,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        const isMobile = window.screen.width < 768;

        setWindowSize({
          width: window.screen.width,
          height: window.screen.height,
          isMobile,
        });
      };

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }

    return () => { };
  }, []);

  return windowSize;
}