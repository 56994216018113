import { createAppAsyncThunk } from '../../createAppAsyncThunk'
import { getAxiosInstance } from '../../../utils/axiosHelpers'
import { FeedbackInterfaceProps } from '../../../interfaces'

export const sendSupportQuery = createAppAsyncThunk(
  'support/create',
  async (feedback: FeedbackInterfaceProps, { rejectWithValue }) => {
    try {
      const instance = getAxiosInstance()
      const response = await instance.post(`/support`, feedback)

      return response.data
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
