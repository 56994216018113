import React from 'react'
import { useParams, Link } from 'react-router-dom'
import Footer from './Footer'
import { AppLayout } from '../../../layout/AppLayout/AppLayout'

export default function Campaigns() {
  let { id } = useParams()
  return (
    <AppLayout backButtonVisible title="Enquire">
      <div className="container">
        <p className="text-center mt-5">
          Thank you for enquiring to promote Feldon Valley on 9th November 2022
        </p>
        <p className="text-center">
          Please wait for correspondence regarding the status of your Campaign.
        </p>
      </div>
      <Footer />
    </AppLayout>
  )
}
