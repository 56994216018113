import React, { createContext, useEffect, useState } from "react";
import { Loading } from "../elements";
import { useLocation } from "react-router-dom";

export const LoadingContext = createContext({
  loading: false,
  setLoading: null,
});

interface LoadingProviderProps {
  children: React.ReactNode;
}

const PATHNAME_WHITELIST = [
  '/',
  '/campaigns/my-offers',
  '/campaigns/my-campaigns',
  '/messages',
];

export const LoadingProvider = ({ children }: LoadingProviderProps) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const value = { loading, setLoading };

  useEffect(() => {
    if (PATHNAME_WHITELIST.includes(location.pathname)) {
      setLoading(false);
    }
  }, [location]);

  return (
    <LoadingContext.Provider value={value}>
      {loading && <Loading />}
      {children}
    </LoadingContext.Provider>
  );
}