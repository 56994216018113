import { CampaignInterfaceProps } from "./campaignInterface";
import { HotelInterfaceProps } from "./hotelInterface";
import { UserInterfaceProps } from "./userInterface";

export enum OfferStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
};

export const offerStatusText = {
  [OfferStatus.PENDING]: 'Enquired',
  [OfferStatus.ACCEPTED]: 'Accepted',
  [OfferStatus.REJECTED]: 'Rejected',
  [OfferStatus.CANCELLED]: 'Cancelled',
};

export interface OfferInterfaceProps {
  id?: number;
  relatedCampaignId: number;
  relatedHotelId: number;
  relatedInfluencerId?: number;
  selectedCampaignDates?: Array<Date>;
  isOfferSentByHotel?: boolean;
  status?: OfferStatus;
  createdAt?: Date;
  campaign?: CampaignInterfaceProps;
  hotel?: HotelInterfaceProps;
  user?: UserInterfaceProps;
}