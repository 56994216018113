import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'

import { ProfileInfo } from '../../../common'
import { AppLayout } from '../../../layout'
import { getUserProfile } from '../../../../redux'
import { UserInterfaceProps } from '../../../../interfaces'
import { useBodyClassName } from '../../../../hooks/useBodyClassName'

import './ProfilePage.scss'

export const ProfilePage: React.FC = () => {
  useBodyClassName("bg-white");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { userInfo } = useAppSelector(state => state.auth);
  const [cookie, setCookie, removeCookie] = useCookies(['accessToken']);


  useEffect(() => {
    if (id) {
      dispatch(getUserProfile(parseInt(id)));
    }
  }, []);

  useEffect(() => {
    if (!userInfo || !Object.keys(userInfo).length) return;

    if (userInfo?.banned || userInfo?.deleted) {
      onLogout();
    }

    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }, [userInfo]);

  const onLogout = () => {
    localStorage.removeItem('userInfo')
    cookie.accessToken && removeCookie('accessToken', { path: '/' })
  }

  const editProfileButton = (
    <button onClick={() => navigate('/profile/edit')} className="profile-edit-button">
      Edit
    </button>
  )

  return (
    <AppLayout
      backButtonVisible
      title="My Profile"
      headerChildren={editProfileButton}
    >
      <div className="profile-container">
        <ProfileInfo userInfo={userInfo as UserInterfaceProps} />
      </div>
    </AppLayout>
  )
}
