import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { AiOutlineMenu } from 'react-icons/ai'
import { Button, Navbar } from '../../elements'
import { UserRoles, UserRolesArray } from '../../../interfaces'
import { adminNavItems, adminNavItemsAdvanced } from '../../../utils/types'
import { useNavigate } from 'react-router-dom'
import { useCurrentUser } from '../../../hooks'

import './Header.scss'

interface HeaderProps {
  backButtonVisible?: boolean,
  backButtonClick?: () => void,
}

export const Header: React.FC<HeaderProps> = ({
  backButtonVisible,
  backButtonClick,
  children
}) => {
  const navigate = useNavigate()

  return (
    <div className="header-container">
      <div className="header-action">
        {backButtonVisible && (
          <button onClick={() => backButtonClick ? backButtonClick() : navigate(-1)} className="header-back-button">
            Back
          </button>
        )}
      </div>
      <div className="header-logo" onClick={() => navigate('/')}>
        <img src="/images/logo.png" alt="Voice of influence" />
      </div>

      <div className="header-action">
        {children}
      </div>
    </div>
  )
}

interface AdminHeaderProps {
  menuItems?: Array<any>
}

export const AdminHeader: React.FC<AdminHeaderProps> = ({ menuItems }) => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navbarMobileRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarMobileRef.current && !navbarMobileRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div className="admin-header-container">
        <div className="admin-header-navbar">
          <Button variant='primary' onClick={() => setIsMenuOpen(true)} className="admin-header-navbar-button">
            <AiOutlineMenu />
          </Button>
        </div>
        <div className="admin-header-logo" onClick={() => navigate('/')}>
          <img src="/images/logo.png" alt="Voice of influence" />
        </div>
        <div className="admin-header-action">
        </div>
      </div>

      <div ref={navbarMobileRef} className={classNames("admin-navbar-mobile", { 'active': isMenuOpen })}>
        <div className="admin-navbar-profile-section" onClick={() => navigate(`/profile/${currentUser?.id}`)}>
          <div className="admin-navbar-profile-image">
            <img src={currentUser?.avatar || '/images/default_profile_image.jpg'} alt="Profile" />
          </div>
          <div className="admin-navbar-profile-name">
            <span>Hi, {currentUser?.name}</span>
            <span className="admin-navbar-profile-name__role" >{UserRolesArray.find(role => role.value === currentUser?.role)?.label || ''}</span>
          </div>
        </div>
        <Navbar items={menuItems} className="admin-navbar-container" onClick={() => setIsMenuOpen(false)} />
      </div>
    </>
  )
}
