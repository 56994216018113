import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { AppLayout } from '../../layout'

import { isRecentlyAdded } from '../../../utils/timeHelpers'
import { useCurrentUser } from '../../../hooks/useAuth'
import { useAppSelector } from '../../../redux/hooks'
import { UserRoles } from '../../../interfaces'
import { getHomePageMenuItems } from '../../../utils/dataHelpers'

import './HomePage.scss'
import { useUnreadMessages } from '../../../redux'

export const HomePage: React.FC = () => {
  const [menu, setMenu] = useState([]);
  const currentUser = useCurrentUser();
  const location = useLocation();
  const [cookie, setCookie, removeCookie] = useCookies(['accessToken']);
  const { campaigns } = useAppSelector(state => state.campaign);
  const { hotels } = useAppSelector(state => state.hotel);
  const { userInfo } = useAppSelector(state => state.auth);
  const { myOffers, myCampaigns } = useAppSelector(state => state.offer);
  const unreadMessages = useUnreadMessages();

  useEffect(() => {
    if (campaigns && hotels && currentUser && myOffers) {
      const newCampaigns = campaigns.filter(campaign => isRecentlyAdded(campaign.createdAt));
      const newHotels = hotels.filter(hotel => isRecentlyAdded(hotel.createdAt));
      const newOffers = myOffers.filter(offer => isRecentlyAdded(offer.createdAt));
      const newMyCampaigns = myCampaigns.filter(offer => isRecentlyAdded(offer.createdAt));

      setMenu(getHomePageMenuItems(currentUser.role as UserRoles, { newCampaigns: newCampaigns.length, newHotels: newHotels.length, newOffers: newOffers.length, newMessages: unreadMessages, newMyCampaigns: newMyCampaigns.length }));
    }
  }, [campaigns, hotels, myOffers, currentUser, unreadMessages, myCampaigns]);

  useEffect(() => {
    if (userInfo && (userInfo.banned || userInfo.deleted)) {
      onLogout();
    }
  }, [location, userInfo]);

  const onLogout = () => {
    localStorage.removeItem('userInfo')
    cookie.accessToken && removeCookie('accessToken', { path: '/' })
  }

  return (
    <AppLayout>
      <div className="home-container">
        {menu.map(({ id, ...rest }) => (
          <HomeCard key={id} {...rest} />
        ))}
      </div>
    </AppLayout>
  )
}

// MARK: - HomeCard

interface HomeCardProps {
  title: string
  icon: string,
  to: string
  newItems?: number,
  iconWidth?: number,
  iconStyle?: React.CSSProperties
}

const HomeCard: React.FC<HomeCardProps> = ({ title, icon, to, newItems, iconWidth, iconStyle }) => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser();

  return (
    <div className="homecard" onClick={() => navigate(title === 'Profile' ? `${to}/${currentUser.id}` : to)}>
      {newItems && <div className="homecard-newlabel">{newItems}</div>}
      <div className="homecard-icon">
        <img src={icon} alt={title} width={iconWidth} style={iconStyle} />
      </div>
      <h3 className="homecard-title">{title}</h3>
    </div>
  )
}
