import React from "react"
import classNames from "classnames"
import { MdClose } from "react-icons/md"
import { RiMessage2Fill } from "react-icons/ri"
import { FiCheck } from "react-icons/fi"
import { CampaignInterfaceProps } from "../../../../interfaces"
import { isCampaignCancelled, isCampaignCompleted, isCampaignLive } from "../../../../utils/dataHelpers"

import "./CampaignDetailAction.scss"

interface CampaignDetailActionsProps {
  campaign: CampaignInterfaceProps,
  messageActive: boolean,
  checkActive: boolean,
  isMyCampaign?: boolean,
  isCampaignRegisteredOrApplied?: boolean,
  onCheck?: () => void,
  onMessage?: () => void,
  onCancel?: () => void,
}

export const CampaignDetailActions: React.FC<CampaignDetailActionsProps> = ({
  campaign,
  messageActive,
  checkActive,
  isMyCampaign = false,
  isCampaignRegisteredOrApplied = false,
  onCheck,
  onMessage,
  onCancel,
}) => {
  return isCampaignRegisteredOrApplied || isCampaignLive(campaign) || isCampaignCompleted(campaign) || isCampaignCancelled(campaign) ? (
    <div className="campaign-actions-container">
      {!isMyCampaign ? (
        <button className="campaign-action-button danger" onClick={onCancel}>
          <MdClose size={30} />
        </button>
      ) : (
        <button
          className={classNames('campaign-action-button message', {
            active: messageActive,
          })}
          disabled={!messageActive}
          onClick={onMessage}>
          <RiMessage2Fill size={30} />
        </button>
      )}
    </div>
  ) : (
    <div className="campaign-actions-container">
      {!isMyCampaign && (
        <button
          className={classNames('campaign-action-button check', {
            active: checkActive,
          })}
          disabled={!checkActive}
          onClick={onCheck}>
          <FiCheck size={30} />
        </button>
      )}
      <button
        className={classNames('campaign-action-button message', {
          active: messageActive,
        })}
        disabled={!messageActive}
        onClick={onMessage}>
        <RiMessage2Fill size={30} />
      </button>
      {!isMyCampaign && (
        <button className="campaign-action-button danger" onClick={onCancel}>
          <MdClose size={30} />
        </button>
      )}
    </div>
  )
}