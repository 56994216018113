import { createSlice } from "@reduxjs/toolkit";
import { deleteMessage, getInboxMessages, getMessageByUser, getSentMessages, getUnreadMessages, markAsRead, sendMessage } from "./messageAction";

const initialState: MessageState = {
  inboxMessages: [],
  sentMessages: [],
  unreadMessages: 0,
  loading: false,
  error: null
}

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInboxMessages.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getInboxMessages.fulfilled, (state, { payload }) => {
        state.loading = false
        state.inboxMessages = payload.data
      })
      .addCase(getInboxMessages.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(getSentMessages.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getSentMessages.fulfilled, (state, { payload }) => {
        state.loading = false
        state.sentMessages = payload.data
      })
      .addCase(getSentMessages.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(getUnreadMessages.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getUnreadMessages.fulfilled, (state, { payload }) => {
        state.loading = false
        state.unreadMessages = payload.data
      })
      .addCase(getUnreadMessages.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(sendMessage.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(sendMessage.fulfilled, (state, { payload }) => {
        state.loading = false
        state.sentMessages = [payload.data, ...state.sentMessages]
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(markAsRead.pending, (state) => {
        state.error = null
      })
      .addCase(markAsRead.fulfilled, (state, { payload }) => {
        state.unreadMessages = 0
      })
      .addCase(markAsRead.rejected, (state, action) => {
        state.error = action.payload
      })

    builder
      .addCase(deleteMessage.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(deleteMessage.fulfilled, (state, { payload }) => {
        state.loading = false
        state.inboxMessages = state.inboxMessages.filter(message => message.id !== payload.data.id)
        state.sentMessages = state.sentMessages.filter(message => message.id !== payload.data.id)
      })
      .addCase(deleteMessage.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder.addCase(getMessageByUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
      .addCase(getMessageByUser.fulfilled, (state, { payload }) => {
        state.loading = false
        state.inboxMessages = payload.data.inbox
        state.sentMessages = payload.data.sent
        state.unreadMessages = payload.data.unread
      })
      .addCase(getMessageByUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

export interface MessageState {
  inboxMessages: any[],
  sentMessages: any[],
  unreadMessages: number,
  loading: boolean,
  error: string | null;
}