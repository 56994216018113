import { HotelInterfaceProps } from "./hotelInterface";

export enum CampaignStatus {
  SCHEDULED = 'scheduled',
  LIVE = 'live',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export interface CampaignInterfaceProps {
  id?: number;
  crn: string;
  name: string;
  description: string;
  relatedHotelId: number;
  startDate: Date | string;
  endDate: Date | string;
  availableDates: Array<Date>;
  registeredDates: Array<Date>;
  instagramRequirements?: boolean;
  instagramRequirementsDescription?: string;
  tiktokRequirements?: boolean;
  tiktokRequirementsDescription?: string;
  keywords?: Array<string>;
  targetAudience?: string;
  minAudienceRequirements?: number;
  commissionOfferedRate?: number;
  followerDiscountRate?: number;
  voiFeeRate?: number;
  promotionCode?: string;
  channels?: Array<string>;
  images?: Array<string>;
  engagement?: number;
  engagementDate?: Date;
  conversion?: number;
  conversionDate?: Date;
  status?: CampaignStatus;
  terms: string;
  createdAt?: Date;
  hotel?: HotelInterfaceProps;
}