import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form'
import { useCookies } from 'react-cookie'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { registerUser } from '../../../../redux'

import { AppLayout } from '../../../layout'
import { Button, Loading, Modal, Tags } from '../../../elements'
import { UserInterfaceProps, UserRoles } from '../../../../interfaces'

import './SignupPage.scss'

const ONE_DAY_TIMESTAMP = 86400000

export const SignupPage: React.FC = () => {
  const [tagModalVisible, setTagModalVisible] = useState(false)
  const [tags, setTags] = useState([])

  const { configs, configsLoading } = useAppSelector((state) => state.config)
  const interestConfig =
    configs?.find((config) => config.type === 'Interests')?.content || []

  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid }
  } = useForm<UserInterfaceProps>({
    mode: 'onBlur'
  })
  const { loading } = useAppSelector((state) => state.auth)
  const [, setCookie] = useCookies(['accessToken'])
  const [userType, setUserType] = useState<UserRoles>(UserRoles.INFLUENCER)

  useEffect(() => {
    setTags([])
  }, [userType])

  const submitNewTag = (tag: string) => {
    setTagModalVisible(false)
    setTags((prev) => {
      return [...prev, tag]
    })
  }

  const removeTag = (tag: string) => {
    setTags((prev) => {
      return prev.filter((item) => item !== tag)
    })
  }

  let password = watch('password')

  const registerOptions = {
    name: (): RegisterOptions => {
      return { required: 'Name is required' }
    },
    email: (): RegisterOptions => {
      return {
        required: 'Email is required',
        pattern: {
          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
          message: 'Invalid email address'
        }
      }
    },
    password: (): RegisterOptions => {
      return {
        required: 'Password is required',
        minLength: {
          value: 8,
          message: 'Password must have at least 8 characters'
        }
      }
    },
    confirmPassword: (): RegisterOptions => {
      return {
        required: 'Confirm password is required',
        minLength: {
          value: 8,
          message: 'Confirm password must have at least 8 characters'
        },
        validate: {
          matchesPreviousPassword: (value) => {
            return password === value || 'Passwords should match!'
          }
        }
      }
    }
  }

  const onFormSubmit: SubmitHandler<UserInterfaceProps> = async (data) => {
    try {
      const response = await dispatch(registerUser({ ...data, tags, role: userType }))

      if (response?.payload?.accessToken && response?.payload?.isSuccess) {
        localStorage.setItem('userInfo', JSON.stringify(response.payload.user))
        setCookie('accessToken', response.payload.accessToken, {
          path: '/',
          expires: new Date(Date.now() + ONE_DAY_TIMESTAMP)
        })
      }
    } catch (err) {
      return err
    }
  }

  return (
    <>
      {configsLoading && <Loading />}
      <AppLayout title="Sign Up" className="signup-layout">
        <div className="signup-container">
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="signup-fields">
              <div className="signup-fields-user">
                <div
                  className="signup-user-type"
                  onClick={() => setUserType(UserRoles.INFLUENCER)}>
                  <span>Influencer</span>
                  <input
                    type="radio"
                    checked={userType === UserRoles.INFLUENCER}
                    readOnly
                  />
                </div>
                <div
                  className="signup-user-type"
                  onClick={() => setUserType(UserRoles.HOTEL_MANAGER)}>
                  <span>Hotel</span>
                  <input
                    type="radio"
                    checked={userType === UserRoles.HOTEL_MANAGER}
                    readOnly
                  />
                </div>
              </div>
              <input
                type="email"
                placeholder="Email"
                name="email"
                {...register('email', registerOptions.email())}
              />
              {errors.email && <p className="error">{errors.email.message}</p>}
              <input
                type="password"
                placeholder="Password"
                name="password"
                {...register('password', registerOptions.password())}
              />
              {errors.password && <p className="error">{errors.password.message}</p>}
              <input
                type="password"
                placeholder="Confirm Password"
                {...register('confirmPassword', registerOptions.confirmPassword())}
              />
              {errors.confirmPassword && (
                <p className="error">{errors.confirmPassword.message}</p>
              )}
              <input
                type="text"
                placeholder="Name"
                {...register('name', registerOptions.name())}
              />
              {errors.name && <p className="error">{errors.name.message}</p>}
              <input type="text" placeholder="Town/City" {...register('city')} />
              {userType === UserRoles.INFLUENCER && (
                <div className="form-group">
                  <div className="interest-section">
                    <label htmlFor="profileInterests">My interests</label>
                    <div className="interest-form-field">
                      <Tags tags={tags} onClick={removeTag}>
                        <button
                          className="tags-item"
                          type="button"
                          onClick={() => setTagModalVisible(true)}>
                          +
                        </button>
                      </Tags>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="signup-button">
              <Button variant="primary" type="submit" disabled={!isValid || loading}>
                Submit
              </Button>
            </div>
          </form>

          <div className="already-registered">
            Already registered? <Link to="/auth/login">Login</Link>
          </div>
        </div>
      </AppLayout>

      <Modal open={tagModalVisible} onClose={() => setTagModalVisible(false)}>
        <div className="tag-modal-container">
          <h3 className="tag-modal-heading">My Interest</h3>
          <div className="tag-modal-list">
            <Tags tags={interestConfig} onClick={submitNewTag} />
          </div>
        </div>
      </Modal>
    </>
  )
}
