import React from 'react'
import { Button, Modal, ModalProps } from '../../../elements'

import './UserEmailChangeConfirmationModal.scss'

interface UserEmailChangeConfirmationModalProps extends ModalProps {
  onConfirm: () => void
}

export const UserEmailChangeConfirmationModal: React.FC<
  UserEmailChangeConfirmationModalProps
> = ({ open, onClose, onConfirm }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="email-change-confirmation-modal">
      <div className="email-change-confirmation-modal-container">
        <h3 className="email-change-confirmation-modal-heading">
          Are you sure?
        </h3>

        <div className="email-change-confirmation-modal-description">
          The user's multi factor authentication will be removed and user will
          be required to set up a new one.
        </div>

        <div className="email-change-confirmation-modal-actions">
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            Proceed
          </Button>
        </div>
      </div>
    </Modal>
  )
}
