import React from "react";
import { RegisterOptions, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Button, Modal, ModalProps } from "../../../elements";
import { updateUserPassword } from "../../../../redux";
import { useCurrentUser } from "../../../../hooks/useAuth";

import "./SettingPasswordChangeModal.scss";

interface PasswordChangeModalProps extends ModalProps {
  onSuccess: () => void
}

export const PasswordChangeModal: React.FC<PasswordChangeModalProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: "onBlur"
  });
  const { loading, error } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const user = useCurrentUser();

  const registerOptions = {
    password: (): RegisterOptions => {
      return {
        required: "Password is required",
      }
    },
    newPassword: (): RegisterOptions => {
      return {
        required: "New password is required",
      }
    }
  }

  const onFormSubmit = async (data: any) => {
    try {
      const response = await dispatch(updateUserPassword({ ...data, id: user?.id }))

      if (response?.payload?.isSuccess) {
        onSuccess();
      }
    } catch (err) {
      return err;
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className="password-change-modal-container">
        <h3 className="password-change-modal-heading">Change Password</h3>

        <div className="password-change-modal-description">
          Please enter your current password and then your new password.
        </div>

        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="password-change-modal-fields">
            <input
              type="password"
              placeholder="Current password"
              name="password"
              {...register("password", registerOptions.password())}
            />
            {errors.password && <p className="error">{errors.password.message}</p>}
            <input
              type="password"
              placeholder="New password"
              name="newPassword"
              {...register("newPassword", registerOptions.newPassword())}
            />
            {errors.newPassword && <p className="error">{errors.newPassword.message}</p>}
          </div>
          <div className="password-change-modal-buttons">
            <Button variant="primary" type="submit" disabled={!isValid || loading}>
              Change Password
            </Button>
          </div>
          {error && <p className="error system-error">{error}</p>}
        </form>
      </div>
    </Modal>
  )
}