import React, { useRef, useState } from "react";
import { Button, Modal } from "../../../elements";
import { CampaignInterfaceProps } from "../../../../interfaces";
import { RxDotsVertical } from "react-icons/rx";
import { isCampaignCancelled, isCampaignCompleted, isCampaignLive, isCampaignScheduled } from "../../../../utils/dataHelpers";

import "./CampaignActionMenu.scss";

export interface CampaignActionMenuProps {
  campaign: CampaignInterfaceProps;
  onViewCampaign: (campaign: CampaignInterfaceProps) => void;
  onEditCampaign: (campaign: CampaignInterfaceProps) => void;
  onCopyCampaign: (campaign: CampaignInterfaceProps) => void;
  onDeleteCampaign: (campaign: CampaignInterfaceProps) => void;
  onCancelCampaign: (campaign: CampaignInterfaceProps) => void;
  onManageOffers: (campaign: CampaignInterfaceProps) => void;
}

export const CampaignActionMenu: React.FC<CampaignActionMenuProps> = ({
  campaign,
  onViewCampaign,
  onEditCampaign,
  onCopyCampaign,
  onDeleteCampaign,
  onCancelCampaign,
  onManageOffers,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [modalContentStyle, setModalContentStyle] = useState({} as React.CSSProperties);
  const menu = useRef(null);

  const onAction = (action: any) => {
    action(campaign);
    setIsMenuVisible(false);
  }

  const onOpenCampaignMenu = () => {
    const { top, left } = menu.current.getBoundingClientRect();

    setModalContentStyle({
      top: `${top}px`,
      left: `${left - 50}px`,
    });

    setIsMenuVisible(true);
  }

  return (
    <div className="campaign-action-menu">
      <div ref={menu} className="campaign-action-menu__toggle">
        <Button className="campaign-action-menu__toggle__icon" onClick={() => onOpenCampaignMenu()}>
          <RxDotsVertical />
        </Button>
      </div>

      <Modal open={isMenuVisible} onClose={() => setIsMenuVisible(false)} className="campaign-action-menu-list" modalContentStyle={modalContentStyle} isBackdropClose>
        <div className="campaign-action-menu-list-container">
          <Button onClick={() => onAction(onViewCampaign)}>
            View
          </Button>
          <Button onClick={() => onAction(onCopyCampaign)}>
            Copy
          </Button>
          <Button disabled={!isCampaignScheduled(campaign)} onClick={() => onAction(onManageOffers)}>
            Offers
          </Button>
          <Button disabled={isCampaignLive(campaign) || isCampaignCompleted(campaign)} onClick={() => onAction(onEditCampaign)}>
            Edit
          </Button>
          <Button disabled={isCampaignCompleted(campaign) || isCampaignCancelled(campaign)} onClick={() => onAction(onCancelCampaign)}>
            Cancel
          </Button>
          <Button disabled={isCampaignLive(campaign)} onClick={() => onAction(onDeleteCampaign)}>
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};
